import React from 'react';
import {Select } from 'antd';


const SelectField = (props) => {

    var onChangeTitle = (value) => {
        props.onChange(value);
    }

    const {Option} = Select;

    let value = props.defaultValue
    if(props.value)
        value = props.value

    return (
        <div style={props.style}>
            <Select onChange={onChangeTitle.bind(this)}
                    value={value}
                    mode={props.mode}
                    className={props.className}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
            >
                {props.list && props.list.length > 0 && props.list.map((item, index) => {
                    return (<Option key={index} value={item.value}>{item.label}</Option>)
                })}
            </Select>
        </div>
    );
}


export default SelectField
