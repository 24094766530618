import React, { useState ,useEffect} from 'react';
import {
    Row,
    message,
    Col,
    Divider,
    Modal, Tooltip, Table, Tag, Input,
    Badge
} from "antd";
import 'react-sortable-tree/style.css';
import Loading from "../../library/components/Loading";
import {TIPS_LIST, DELETE_TIPS} from "../queries/tips/tips"
import 'react-sortable-tree/style.css';
import _ from "lodash";
import {MaterialCommunityIcons} from "react-web-vector-icons";
import UserInfo from "../../library/components/UserInfo";
import moment from "jalali-moment";
import {CHANGE_STATUS} from "../queries/bazaar/ChangeStatus";

const columns = [
    {
        title: 'id',
        dataIndex: 'key',
        width:'5%'
    },
    {
        title: 'name',
        dataIndex: 'name',
    },
    {
        title: 'type',
        dataIndex: 'type',
    },
    {
        title: 'report title',
        dataIndex: 'report_title',
        width:'35%'
    },
    {
        title: 'like',
        dataIndex: 'like',
    },
    {
        title: 'dislike',
        dataIndex: 'dislike',
    },
    {
        title: 'user info',
        dataIndex: 'user_info',
    },
    {
        title: 'created date',
        dataIndex: 'created_At',
    },
    {
        title: 'action',
        dataIndex: 'action',
        width:'5%'
    },
];

function TipsList(props){

    const [listLoading, setListLoading] = useState(false);
    const [list, setList] = useState([]);
    const [userModalVisible,setUserModalVisible] = useState(false)
    const [userId, setUserId] = useState('');
    const [visible,setVisible] = useState(false)
    const [textValue,setTextValue] = useState(false)
    const [modelId,setModelId] = useState()
    const [loading, setLoading] = useState(false);
    const [modelType,setModelType] = useState()
    const { TextArea } = Input;

    useEffect(() => {
        getTipsList()
    }, []);

    /* ------------------------------------------------ */
    /* ---------------- Report Query ----------------- */
    /* ------------------------------------------------ */

    const getTipsList = () => {
        setListLoading(true)
        let param = {}
        TIPS_LIST(param).then(list => {
            if(list.status === 200) {
                if (list.data.data.length > 0) {
                    setListLoading(false)
                    setList(list.data.data)
                } else
                    return list;
            }

        }).catch(error => {
            console.log(error);
        });

    }

    const deleteReport = (id) => {
        DELETE_TIPS({tipId:id}).then(res => {
            if (res.status === 200) {
                let report = res.data.data
                res ? message.success("the item was deleted.") : message.error("Error in delete country")
                var index = _.findIndex(list, {id: id});
                list.splice(index, 1);
                getTipsList()
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (id) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                deleteReport(id);
            },
            onCancel() {},
        });
    }

    /*--- fix user list for show in table ---*/
    const fixListForShowInTable = () => {
        var dataSource = [];

        if(list) {

            for (let i = 0; i < list.length; i++) {
                let __id
                if (list[i].bazaarId && list[i].bazaarId.id)
                    __id = list[i].bazaarId.id
                else if (list[i].marketId && list[i].marketId.id)
                    __id = list[i].marketId.id
                else if (list[i].eventId && list[i].eventId.id)
                    __id = list[i].eventId.id

                dataSource.push({
                    key: i+1,
                    name: <span>
                            {__id ?
                                <a href={'#/'+list[i].modelType+'/'+__id} >
                                    {list[i].bazaarId && list[i].bazaarId.title ? list[i].bazaarId.title.en : ''}
                                </a>
                                : <Tag color={'red'}>Removed this {list[i].modelType}</Tag>}
                            </span>,
                    type: list[i].modelType ? list[i].modelType : '',
                    report_title: <Tooltip placement="top" title={list[i].message}>
                                    {list[i].message ? list[i].message.substring(0,100)+"..." : ''}
                                </Tooltip>,
                    user_info: <a onClick={showModal.bind(this,list[i].userId.id)} >
                        {list[i].userId ? list[i].userId.name+' '+list[i].userId.family : ''}
                    </a>,
                    like: <Badge count={list[i].like.length} style={{ backgroundColor: '#52c41a' }} />,
                    dislike: <Badge count={list[i].dislike.length} />,
                    created_At: list[i].createdAt ? moment(list[i].createdAt).format('lll') : '',
                    action: <span>
                                <Tooltip placement="top" title="delete report">
                                    <a onClick={showConfirm.bind(this,list[i].id)} >
                                        <MaterialCommunityIcons name={'close-circle'} size={30} color={'red'} />
                                    </a>
                                </Tooltip>
                            </span>
                });
            }
        }
        return dataSource;
    }

    /* ------------------------------------------------ */
    /* ----------------- Reject  ---------------------- */
    /* ------------------------------------------------ */
    const reject = (item) => {
        setVisible(true);
        setModelType(item.modelType)
        let id;
        if(item.bazaarId && item.bazaarId.id)
            id = item.bazaarId.id
        else if(item.marketId && item.marketId.id)
            id = item.marketId.id
        else if(item.eventId && item.eventId.id)
            id = item.eventId.id
        setModelId(id);
    }

    const handleOk = e => {
        setLoading(true)
        setVisible(false);
        let param = {
            id:modelId,
            status:-1,
            message:textValue
        }
        if(modelType === 'bazaar')
            CHANGE_STATUS(param).then(res => {
                if(res.status === 200) {
                    getTipsList()
                    setLoading(false)
                }
            })
    };

    const showModal = (id) => {
        setUserModalVisible(true);
        setUserId(id)
    }

    const handleUserModalCancel = e => {
        setUserModalVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    return(
        <div>
            <Row>
                <Col xs={24} md={24}>
                    <Divider orientation="left">tips list</Divider>
                </Col>
            </Row>
            <Row>
                {listLoading ?
                    <Loading />
                    :
                    <Table rowClassName={(record, index) => (index + 1) % 2 == 0 ? 'even' : 'odd'} pagination={true} columns={columns} dataSource={fixListForShowInTable()} size="small" />
                }
                <Modal
                    visible={userModalVisible}
                    onCancel={handleUserModalCancel}
                    footer={null}
                >
                    <UserInfo id={userId}/>
                </Modal>
                <Modal
                    title="reason of rejection"
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <TextArea onChange={(val) => setTextValue(val.target.value)} type={'textarea'} rows={4} />
                </Modal>
            </Row>
        </div>
    )

}

export default TipsList;
