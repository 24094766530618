import {API_FETCH} from "../../../library/utils/FetchService";

export const REPORT_LIST = (param) => {
    return API_FETCH.post('super/report/getAllReport',param)
}

export const DELETE_REPORT = (id) => {
    return API_FETCH.delete('super/report/deleteReport/'+id)
}

export const OPTION_LIST = (param) => {
    return API_FETCH.post('super/reportOpt/getAllOpt',param)
}

export const OPTION_INFO = (id) => {
    return API_FETCH.get('super/reportOpt/getOne/'+id)
}

export const ADD_OPTION = (param) => {
    return API_FETCH.post('super/reportOpt/addOptReport',param)
}

export const EDIT_OPTION = (param) => {
    return API_FETCH.put('super/reportOpt/updateReportOpt',param)
}

export const DELETE_OPTION = (id) => {
    return API_FETCH.delete('super/reportOpt/deleteReportOpt/'+id)
}
