import React,{useState} from "react";
import ItemsCarousel from 'react-items-carousel';
import {MaterialIcons} from 'react-web-vector-icons'
export const ImgGallery = (props) => {

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;

    return(
        <div style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={1}
                gutter={20}
                leftChevron={<MaterialIcons name={'keyboard-arrow-left'} />}
                rightChevron={<MaterialIcons name={'keyboard-arrow-right'} />}
                outsideChevron
                chevronWidth={chevronWidth}
            >
                {
                    props.item && props.item.map((item,index) => {
                        return (
                            <div key={index}>
                                <img style={{width:'100%'}} src={item.medium} />
                            </div>
                        )
                    })
                }
            </ItemsCarousel>
        </div>
    )
}
