import React, {useState} from 'react';
import {Col, Divider, Icon, Tag, Tooltip, Row, Modal, Input} from 'antd'
import moment from "jalali-moment";
import {MaterialCommunityIcons, EvilIcons, Entypo, FontAwesome, MaterialIcons} from 'react-web-vector-icons'
import _ from 'lodash'
import {Link} from "react-router-dom";
import {CHANGE_STATUS as BazaarStatus} from "../../components/queries/bazaar/ChangeStatus";
import {CHANGE_STATUS as MarketStatus} from "../../components/queries/marketplace/ChangeStatus";
import {CHANGE_STATUS as EventStatus} from "../../components/queries/event/ChangeStatus";
import UserInfo from "./UserInfo";
import {SlideShow} from "./Slideshow";
import {MiniLoading} from "./Loading";

const CardLists = (props) => {

    const [userInfo,setUserInfo] = useState()
    const [visible,setVisible] = useState(false)
    const [userModalVisible,setUserModalVisible] = useState(false)
    const [textValue,setTextValue] = useState(false)
    const [id,setId] = useState()
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);

    const { TextArea } = Input;

    let fullDate = moment(props.item.createdAt).format('lll');
    let status;
    switch (props.item.status) {
        case 0 :
            status = {
                color : 'orange',
                title : 'pending'
            }
            break;
        case 1 :
            status = {
                color : 'green',
                title : 'approved'
            }
            break;
        case -1 :
            status = {
                color : 'red',
                title : 'rejected'
            }
            break;
        default :
            status = {
                color : 'green',
                title : 'approved'
            }
    }
    let treePrimaryCat = []

    if(props.item.primryCatTree)
        props.item.primryCatTree.map((item,index)=>{
            treePrimaryCat.push(item.title.en)
            if(index < props.item.primryCatTree.length - 1)
                treePrimaryCat.push(' -> ')
        })
// console.log(props)
    const accept = (id,type) => {
        setLoading(true)
        let param = {
            id:id,
            message:'',
            status:1
        }
        if(type === 'bazaar')
            BazaarStatus(param).then(res => {
                if(res.status === 200) {
                    var index = _.findIndex(props.list, {id: props.item.id});
                    props.list.splice(index, 1, res.data.data);
                    props.onChange(props.list)
                    setLoading(false)
                }
            })
        else if(type === 'marketplace')
            MarketStatus(param).then(res => {
                if(res.status === 200) {
                    var index = _.findIndex(props.list, {id: props.item.id});
                    props.list.splice(index, 1, res.data.data);
                    props.onChange(props.list)
                    setLoading(false)
                }
            })
        else if(type === 'event')
            EventStatus(param).then(res => {
                if(res.status === 200) {
                    var index = _.findIndex(props.list, {id: props.item.id});
                    props.list.splice(index, 1, res.data.data);
                    props.onChange(props.list)
                    setLoading(false)
                }
            })
    }

    const reject = (id) => {
        setVisible(true);
        setId(id);
    }

    /*------- Modal -------*/
    const handleOk = e => {
        setLoading(true)
        setVisible(false);
        let param = {
            id:id,
            status:-1,
            message:textValue
        }
        if(props.type === 'bazaar')
            BazaarStatus(param).then(res => {
                if(res.status === 200) {
                    var index = _.findIndex(props.list, {id: props.item.id});
                    props.list.splice(index, 1, res.data.data);
                    props.onChange(props.list)
                    setLoading(false)
                }
            })
        else if(props.type === 'marketplace')
            MarketStatus(param).then(res => {
                if(res.status === 200) {
                    var index = _.findIndex(props.list, {id: props.item.id});
                    props.list.splice(index, 1, res.data.data);
                    props.onChange(props.list)
                    setLoading(false)
                }
            })
        else if(props.type === 'event')
            EventStatus(param).then(res => {
                if(res.status === 200) {
                    var index = _.findIndex(props.list, {id: props.item.id});
                    props.list.splice(index, 1, res.data.data);
                    props.onChange(props.list)
                    setLoading(false)
                }
            })
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const showModal = (id) => {
        setUserModalVisible(true);
        setUserId(id)
    }

    const handleUserModalCancel = e => {
        setUserModalVisible(false);
    };

    let imgGallery = []
    if(props.item.gallery && props.item.gallery.length > 0){
        props.item.gallery.map((item)=>{
            item.children.map((child)=>{
                imgGallery.push(child.small)
            })
        })
    }
    if(props.item.mainPic)
        imgGallery.push(props.item.mainPic.small)
    if(props.item.logo)
        imgGallery.push(props.item.logo.small)

    return (
        <Col style={cardStyle} md={7} xs={24}>
            <div style={mainPic}>
                {props.item && props.item.mainPic && !_.isEmpty(props.item.mainPic.medium) ?
                    <SlideShow item={imgGallery}/>
                    : <div style={noImage}>No Image</div>
                }
            </div>
            <Link to={props.type+'/'+(props.item.id ? props.item.id : props.item._id)}>
                <div style={title}>
                    {props.item.title ? props.item.title.en : ''}
                </div>
                <div style={desc}>
                    {props.item.description && props.item.description.en ?
                        props.item.description.en.substring(0,150)+"..." :
                        ''}
                </div>
            </Link>
            <Row style={footer}>
                <Col md={6} xs={12}>
                    {loading ?
                        <MiniLoading style={{marginTop:10}}/> :
                        <Tag style={{paddingTop: 2, paddingBottom: 2, marginRight: 0, marginTop: 10}}
                             color={status.color}>
                            {status.title}
                        </Tag>
                     }
                </Col>
                <Col style={statusStyle} md={12} xs={12}>
                    <EvilIcons name="calendar" size={18} />
                    {fullDate}
                </Col>
                <Col style={{padding:'4px 0'}} md={6} xs={12}>
                    <Tooltip placement="top" title={'reject'}>
                        <a onClick={reject.bind(this,props.item.id,props.type)}>
                            <MaterialCommunityIcons name="close-circle-outline" size={25} color={'#ff4d4f'} />
                        </a>
                    </Tooltip>
                    <Divider type={"vertical"} />
                    <Tooltip placement="top"  title={'accept'}>
                        <a onClick={accept.bind(this,props.item.id,props.type)}>
                            <MaterialCommunityIcons name="check-circle-outline" size={25} color={'#34c38f'} />
                        </a>
                    </Tooltip>
                </Col>
            </Row>
            <Row style={footer}>
                <Col style={statusStyle} md={12} xs={12}>
                    <FontAwesome name="user-o" style={icon} size={18} />
                    <a onClick={showModal.bind(this,props.userId)} >
                        {props.userInfo}
                    </a>
                    <Modal
                        title={props.fullName}
                        visible={userModalVisible}
                        onCancel={handleUserModalCancel}
                        footer={null}
                    >
                        <UserInfo id={userId}/>
                    </Modal>
                </Col>
                <Col style={{paddingTop:10}} md={12} xs={12}>
                    <MaterialIcons name={'play-arrow'} style={icon} size={'25'} />
                    {props.item.primryCat ? props.item.primryCat.title.en : null}
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <Entypo name="flow-tree" style={icon} size={18} />
                    {treePrimaryCat}
                </Col>
            </Row>
            <Modal
                title="reason of rejection"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <TextArea onChange={(val) => setTextValue(val.target.value)} type={'textarea'} rows={4} />
            </Modal>
        </Col>
    );

}
const statusStyle = {
    borderRight:'1px solid #e8e8e8',
    borderLeft:'1px solid #e8e8e8',
    padding:'10px 0'
}
const cardStyle = {
    border:'1px solid #e8e8e8',
    borderRadius:5,
    margin:'2%',
    marginBottom:15,
}
const mainPic = {
    height:300,
    overflow:'hidden'
}
const img = {
    width:'100%',
}
const title = {
    textAlign:'left',
    padding:5,
    fontWeight:600
}
const desc = {
    textAlign:'left',
    padding:5,
    color:'#74788d',
    borderBottom:'1px solid #e8e8e8',
    height: 90,
	overflowWrap: 'break-word'
}
const footer = {
    borderBottom: '1px solid #e8e8e8'
}
const noImage = {
    background:'#eee',
    color:'#999',
    fontSize:20,
    align:'center',
    padding:'135px 0'
}
const icon = {
    marginRight:10,
    marginLeft:10,
    color:'#556EE6'
}

export default CardLists;


