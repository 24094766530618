import * as ACT from './globalAction';

let State = {
    username: '',
    password:  '',
    isButtonDisabled: false,
    isError: false
};

const initialState = {
    username: '',
    password: '',
    isButtonDisabled: true,
    helperText: '',
    isError: false
};

let Action = { type: 'setUsername', payload: '' }
    | { type: 'setPassword', payload: '' }
    | { type: 'setIsButtonDisabled', payload: false }
    | { type: 'loginSuccess', payload: '' }
    | { type: 'loginFailed', payload: '' }
    | { type: 'setIsError', payload: false };


export const global = (state=State, action=Action) => {
    switch (action.type) {

        case 'setUsername':
            return {
                ...state,
                username: action.payload
            };

        case 'setPassword':
            return {
                ...state,
                password: action.payload
            };

        case 'setIsButtonDisabled':
            return {
                ...state,
                isButtonDisabled: action.payload
            };

        case 'loginSuccess':
            return {
                ...state,
                helperText: action.payload,
                isError: false
            };

        case 'loginFailed':
            return {
                ...state,
                helperText: action.payload,
                isError: true
            };

        case 'setIsError':
            return {
                ...state,
                isError: action.payload
            };

        case ACT.CATEGORY_GET_ITEM:
            return {
                ...state,
                getCategoryItem: action.data,
            };
        case ACT.CATEGORY_SET_ITEM:
            return {
                ...state,
                targetCategoryItem: action.data,
            };

        case ACT.CATEGORY_FIELD_ERROR:
            if (state.fieldsError)
                var fieldsErrorPushed = state.fieldsError;
            else
                fieldsErrorPushed = {};
            fieldsErrorPushed[action.fieldName] = (action.fieldData != {}) ? action.fieldData : {err: true};


            return {
                ...state,
                fieldsError: fieldsErrorPushed,
                formError: true,
            };
        case ACT.CATEGORY_FIELD_RESET_ERROR:
            return {
                ...state,
                fieldsError: {},
                formError: false,
            };


        default:
            return state
    }
};
