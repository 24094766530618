//--- React Hook
import React, {useState, useEffect} from 'react';

//--- ant design
import {
    Button, Icon, Input, message, Checkbox, Typography,
    Layout, Card, Modal, Skeleton, Col, Tooltip
} from 'antd';

/* ------ custom -------- */
import EditableText from "../../library/components/EditableText";
import _ from 'lodash'
import {ADD_FEATURE} from "../queries/feature/AddFeature";
import {UPDATE_FEATURE} from "../queries/feature/EditFeature";
import {DELETE_FEATURE_PARENT} from "../queries/feature/DeleteFeatureParent";
import {ADD_FEATURE_PARENT} from "../queries/feature/AddFeatureParent";
import {UPDATE_FEATURE_PARENT} from "../queries/feature/EditFeatureParent";
import {DELETE_FEATURE} from "../queries/feature/DeleteFeature";
import {FEATURE_LIST} from "../queries/feature/FeatureList";

import classNames from 'classnames/bind';
// and design component
const { Content } = Layout;
const { Title } = Typography;


const FeatureList = (props) => {

    /* ------------ Render HTML  : convert special character ------------- */
    const renderHTML = (rawHTML) => React.createElement("div", {dangerouslySetInnerHTML: {__html: rawHTML}});

    /* ------------------------------------------------ */
    /* ---------------- Define State ------------------ */
    /* ------------------------------------------------ */
    const [form, setFormField] = useState({
        feature_list: [],
        feature_exclude: [],
    });
    const [featureList, setFeatureList] = useState([]);
    const [featureParentList, setFeatureParentList] = useState([]);
    const [state, setState] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [modalParentVisibility, setModalParentVisibility] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [parentConfirmLoading, setParentConfirmLoading] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [FeatureModal, setFeatureModal] = useState(false);
    const [FeatureParentModal, setFeatureParentModal] = useState(false);
    const nullFeatureData = {
        title: {
            fa: null,
            en: null,
            tr: null,
        },
        description: {
            fa: null,
            en: null,
            tr: null,
        }
    }
    const [FormAddFeature, setFormAddFeature] = useState(nullFeatureData);
    const nullFeatureParentData = {
        title: {
            fa: null,
            en: null,
            tr: null,
        },
        description: {
            fa: null,
            en: null,
            tr: null,
        },
        childres:[]
    }
    const [FormAddFeatureParent, setFormAddFeatureParent] = useState(nullFeatureParentData);


    useEffect(() => {
        callFeatureList();
    }, []);


    /* ------------------------------------------------ */
    /* -------------------- [ Query ] ----------------- */
    /* ------------------------------------------------ */


    //--- get all feature
    const callFeatureList = () => {
        setListLoading(true)
        FEATURE_LIST.then(list => {
            if(list.status === 200) {
                if(list.data && list.data.data.length > 0)
                {
                    setFeatureList(list.data.data);
                }
                setListLoading(false)
            }
        }).catch(error => {
            console.log(error);
        });

    }

    /* -------------- add Feature  ----------------- */
    const addFeature = (param) => {
        ADD_FEATURE(param).then(res => {
            if(res.status === 200) {
                setConfirmLoading(false);
                setModalVisibility(false);
                let feature = res.data.data
                let parentFeature = featureList.find(parent => parent.id === res.data.data.parent.$id)
                if(parentFeature && parentFeature.childres) {
                    parentFeature.childres.push(res.data.data)
                }else {
                    parentFeature['childres'] = [res.data.data]
                }
                res && feature ? message.success("the ` " + feature.title.en+ " ` was added.") : message.error("Error in add feature")
                setFormAddFeature({
                    title: {
                        fa: null,
                        en: null,
                        tr: null,
                    },
                    description: {
                        fa: null,
                        en: null,
                        tr: null,
                    }
                })
                callFeatureList();
            }
        }).catch(error => {
            console.log(error);
        });

    }

    /* -------------- Edit Feature  ----------------- */
    const updateFeature = (param) => {
        UPDATE_FEATURE(param).then(res => {
            if(res.status === 200) {
                if(res.data.data)
                {
                    let feature = res.data.data
                    res ? message.success("the ` " + feature.title.en+ " ` was updated.") : message.error("Error in update feature")

                }
            }
        }).catch(error => {
            console.log(error);
        });

    }

    /* -------------- Delete Feature  ----------------- */
    const deleteFeature = (id,index) => {
        setLoading(true)
        DELETE_FEATURE(id).then(res => {
            if(res.status === 200) {
                let feature = res.data.data
                let parentFeature = featureList.find(parent => parent.id === res.data.data.parent.$id)
                parentFeature.childres.splice(index,1)
                res && feature ? message.success("the ` " + feature.title.en+ " ` was deleted.") : message.error("Error in delete feature")
                callFeatureList()
                setLoading(false)
            }
        }).catch(error => {
            console.log(error);
        });

    }

    /* -------------- Edit Feature Parent ----------------- */
    const updateFeatureParent = (param) => {
        UPDATE_FEATURE_PARENT(param).then(res => {
            if(res.status === 200) {
                if(res.data.data)
                {
                    let feature = res.data.data
                    res ? message.success("the ` " + feature.title.en+ " ` was updated.") : message.error("Error in update feature")
                }
            }
        }).catch(error => {
            console.log(error);
        });

    }

    /* -------------- Add Feature Parent ----------------- */
    const addFeatureParent = (param) => {
        ADD_FEATURE_PARENT(param).then(res => {
            if(res.status === 200) {
                setModalParentVisibility(false);
                setParentConfirmLoading(false);
                featureList.push(res.data.data)
                let feature = res.data.data
                res && feature ? message.success("the ` " + feature.title.en+ " ` was added.") : message.error("Error in add feature")
                setFormAddFeatureParent({
                    title: {
                        fa: null,
                        en: null,
                        tr: null,
                    },
                    description: {
                        fa: null,
                        en: null,
                        tr: null,
                    }
                })
                callFeatureList();
            }
        }).catch(error => {
            console.log(error);
        });

    }

    /* -------------- Delete Feature Parent ----------------- */
    const deleteFeatureParent = (param,index) => {
        setLoading(true)

        DELETE_FEATURE_PARENT(param).then(res => {
            if(res.data.errCode === -6)
                message.error("you can not delete a parent witch has child")
            if(res.status === 200) {
                let feature = res.data.data
                res && feature ? message.success("the ` " + feature.title.en+ " ` was deleted.") : message.error("Error in delete feature")
                featureList.splice(index,1)
                callFeatureList();
                setLoading(false)
            }
        }).catch(error => {
            console.log(error);
        });

    }

    /* -------------- Feature Modal ----------------- */
    var onSaveFeature = (feature,lang,text, disableEditMode) => {

        feature.title[lang] = text;
        if (!feature.type)
            feature.type = "bazaar"

        updateFeature({
            id    : feature.id,
            title :feature.title
        });
        callFeatureList()
        disableEditMode();
    }

    var onDeleteFeature = (feature,index, disableEditMode) => {
        deleteFeature(feature.id,index);
        disableEditMode();
    }

    var onAddFeature =(featureParent)=>{
        setFeatureModal(featureParent.id)
        setModalVisibility(true)
    }

    var onAddModalFeature =(featureParent)=>{
        // alert(FeatureParentModal);
        if (!FeatureModal)
            return false;

        setConfirmLoading(true);
        addFeature({
            title: FormAddFeature.title,
            description: FormAddFeature.description,
            priority: 0,
            parent: FeatureModal,
            type: 'bazaar',
        });
    }


    /* -------------- Feature Parent Modal ----------------- */
    var onSaveFeatureParent = (feature,lang,text, disableEditMode) => {

        feature.title[lang] = text;
        if (!feature.type)
            feature.type = "bazaar"

        updateFeatureParent({
            id    : feature.id,
            title :feature.title,
        });
        callFeatureList()
        disableEditMode();
    }

    var onDeleteFeatureParent = (feature,index, disableEditMode) => {
        deleteFeatureParent(feature.id,index);
        disableEditMode();
    }

     var onAddFeatureParent =(featureParent)=>{
        setFeatureParentModal(true)
        setModalParentVisibility(true)
    }

    var onAddModalFeatureParent =(feature)=>{
        if (!FeatureParentModal)
            return false;

        setParentConfirmLoading(true);
        addFeatureParent({
            title: FormAddFeatureParent.title,
            description: FormAddFeatureParent.description,
            priority: 0,
            type: 'bazaar',
        });

    }


    /* ----------- Delete Confirmation  --------------- */
    const { confirm } = Modal;
    const showConfirm = (e) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                e.preventDefault();
            },
            onCancel() {},
        });
    }


    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */

    var handleTextInput = (name,subname,formName, e) => {
        const { value } = e.target;
        if(formName == 'featureForm') {
            let newForm = {...FormAddFeature};
            if (name == 'title')
                newForm['title'][subname] = value;
            if (name == 'description')
                newForm['description'][subname] = value;
            setFormAddFeature(newForm)
        }else{
            let newForm = {...FormAddFeatureParent};
            if (name == 'title')
                newForm['title'][subname] = value;
            if (name == 'description')
                newForm['description'][subname] = value;
            setFormAddFeatureParent(newForm)
        }
    };

    /* -------------- Handle feature list ------------------- */
    var handleFeatureSelect = (name, featureItem,e) => {
        let isChecked =  e.target.checked;
        let newForm = {...form};

        if (!newForm['feature_list'])
            newForm['feature_list'] = [];

        if (isChecked && newForm['feature_list'] && newForm['feature_list'].indexOf(featureItem.id) == -1)
            newForm['feature_list'].push(featureItem.id)
        if (!isChecked && newForm['feature_list'] && newForm['feature_list'].indexOf(featureItem.id) > -1)
            _.pull(newForm['feature_list'], featureItem.id);

        setFormField(newForm);
        if (props.onSelect)
            props.onSelect(name,featureItem,e)
    };


    /* ------------- get selected feature ------------ */
    let selectedFeatureList = props.selected

    /* ------------------------------------------------ */
    /* -----------------  R E N D E R ----------------- */
    /* ------------------------------------------------ */
    return (
        <Layout style={{marginTop:"50px",marginBottom:"50px",textAlign:'left'}}>
            <Content>
                <Title level={3}>Feature</Title>

                {listLoading ?
                    <Card style={{ marginTop: 16 }}>
                        <Skeleton></Skeleton>
                    </Card>
                :
                featureList.length > 0 && featureList.map((featureParent,i)=>{
                    return (<Card key={i}
                            style={{ marginTop: "20px", textAlign: 'left',fontSize : '20px',}}
                                  className={'featureHead'}
                            title={<div>
                                <EditableText
                                    label={featureParent.title.en ? featureParent.title.en + " " : 'english title'}
                                    onSave={onSaveFeatureParent.bind(this,featureParent,"en")}
                                    onDelete={onDeleteFeatureParent.bind(this,featureParent,i)}
                                    holderStyle={{width:'33%'}}
                                />
                                <EditableText
                                    label={featureParent.title.tr ? featureParent.title.tr : 'turkish title'}
                                    onSave={onSaveFeatureParent.bind(this,featureParent,'tr')}
                                    onDelete={onDeleteFeatureParent.bind(this,featureParent)}
                                    holderStyle={{width:'33%'}}
                                />
                                <EditableText
                                    label={featureParent.title.fa ? featureParent.title.fa : 'persian title'}
                                    onSave={onSaveFeatureParent.bind(this,featureParent,'fa')}
                                    onDelete={onDeleteFeatureParent.bind(this,featureParent)}
                                    holderStyle={{fontFamily:'IRANSans',textAlign:'right',width:'33%'}}
                                    lang={'fa'}
                                />
                            </div>}
                            // extra={
                            //     <EditableText
                            //         label={featureParent.title.tr ? featureParent.title.tr : 'turkish title'}
                            //         onSave={onSaveFeatureParent.bind(this,featureParent,'tr')}
                            //         onDelete={onDeleteFeatureParent.bind(this,featureParent)}
                            //         holderStyle={{width:'83%'}}
                            //         // holderStyle={{fontFamily:'IRANSans',width:'83%'}}
                            //         // lang={'fa'}
                            //     />
                            // }
                        >

                        <div style={{display:'flex',flexWrap:'wrap',}}>
                            {featureParent.childres ? featureParent.childres.map((feature,i) => {
                                let findFeature = _.find(selectedFeatureList, function(item) {
                                    if(item == feature.id)
                                        return true
                                    return false
                                });
                                return (<Col md={12} style={{ margin: '5px 0px',paddingLeft:0}} key={i}>
                                    <Col md={22} style={{ paddingLeft:0}} >
                                        <Col md={2} style={{ padding :0}} className={'left'}>
                                            <Checkbox style={{ flex: 1 }} checked={form.feature_list.indexOf(feature.id) > -1 || findFeature}
                                                      onChange={handleFeatureSelect.bind(this, 'feature', feature)}>
                                            </Checkbox>
                                        </Col>
                                        <Col md={22} className={'left'}>
                                            <Tooltip title={feature.title.en ? feature.title.en + " " : 'english title'}>
                                                <Col md={8} xs={24} style={{paddingLeft:5}} className={classNames('featureTitle','featureLeft')}>
                                                    <EditableText
                                                        label={feature.title.en ? feature.title.en + " " : 'english title'}
                                                        onSave={onSaveFeature.bind(this,feature,"en")}
                                                        onDelete={onDeleteFeature.bind(this,feature,i)}
                                                        holderStyle={{width:'100%'}}
                                                    />
                                                </Col>
                                            </Tooltip>
                                            <Tooltip title={feature.title.fa ? feature.title.fa : 'عنوان فارسی'}>
                                                <Col md={8} xs={24} style={{paddingLeft:5}} className={classNames('featureTitle','featureRight')}>
                                                    <EditableText
                                                        label={feature.title.fa ? feature.title.fa : 'عنوان فارسی'}
                                                        onSave={onSaveFeature.bind(this,feature,'fa')}
                                                        onDelete={onDeleteFeature.bind(this,feature,i)}
                                                        holderStyle={{fontFamily:'IRANSans',width:'100%',paddingTop:2}}
                                                    />
                                                </Col>
                                            </Tooltip>
                                            <Tooltip title={feature.title.tr ? feature.title.tr : 'Türkçe başlık'}>
                                                <Col md={8} xs={24} style={{paddingLeft:5}} className={classNames('featureTitle','featureRight')}>
                                                    <EditableText
                                                        label={feature.title.tr ? feature.title.tr : 'Türkçe başlık'}
                                                        onSave={onSaveFeature.bind(this,feature,'tr')}
                                                        onDelete={onDeleteFeature.bind(this,feature,i)}
                                                        holderStyle={{width:'100%'}}
                                                    />
                                                </Col>
                                            </Tooltip>
                                        </Col>
                                    </Col>
                                </Col>)
                            })
                            :
                            <div style={{padding:15}}> there is no feature for this parent </div>
                            }
                        </div>
                        <div>
                            <Button onClick={onAddFeature.bind(this, featureParent)}
                            style={{marginTop:10,padding:5}}>
                                <Icon type="plus" key="plus" /> Add Feature
                            </Button>
                        </div>
                    </Card>);
                    })
                }
                <Button style={{ marginTop: '50px', felx: '1', width: 500, }} onClick={onAddFeatureParent.bind(this)}><Icon type="plus" key="plus" /> Add Feature Parent</Button>

                <Modal
                    title="Add Feature"
                    visible={modalVisibility}
                    onOk={onAddModalFeature.bind(this)}
                    confirmLoading={confirmLoading}
                    onCancel={() => { setModalVisibility(false)}}
                >
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"english title"}
                        value={FormAddFeature.title.en}
                        onChange={handleTextInput.bind(this, 'title','en','featureForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"persian title"}
                        value={FormAddFeature.title.fa}
                        onChange={handleTextInput.bind(this, 'title', 'fa','featureForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"turkish title"}
                        value={FormAddFeature.title.tr}
                        onChange={handleTextInput.bind(this, 'title', 'tr','featureForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"english description"}
                        value={FormAddFeature.description.en}
                        onChange={handleTextInput.bind(this, 'description','en','featureForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"persian description"}
                        value={FormAddFeature.description.fa}
                        onChange={handleTextInput.bind(this, 'description', 'fa','featureForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"turkish description"}
                        value={FormAddFeature.description.tr}
                        onChange={handleTextInput.bind(this, 'description', 'tr','featureForm')}
                    />
                </Modal>

                <Modal
                    title="Add Feature Parent"
                    visible={modalParentVisibility}
                    onOk={onAddModalFeatureParent.bind(this)}
                    confirmLoading={parentConfirmLoading}
                    onCancel={() => { setModalParentVisibility(false)}}
                >
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"english title"}
                        value={FormAddFeatureParent.title.en}
                        onChange={handleTextInput.bind(this, 'title','en','featureParentForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"persian title"}
                        value={FormAddFeatureParent.title.fa}
                        onChange={handleTextInput.bind(this, 'title', 'fa','featureParentForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"turkish title"}
                        value={FormAddFeatureParent.title.tr}
                        onChange={handleTextInput.bind(this, 'title', 'tr','featureParentForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"english description"}
                        value={FormAddFeatureParent.description.en}
                        onChange={handleTextInput.bind(this, 'description','en','featureParentForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"persian description"}
                        value={FormAddFeatureParent.description.fa}
                        onChange={handleTextInput.bind(this, 'description', 'fa','featureParentForm')}
                    />
                    <Input
                        prefix={<Icon type={'text'} style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={"turkish description"}
                        value={FormAddFeatureParent.description.tr}
                        onChange={handleTextInput.bind(this, 'description', 'tr','featureParentForm')}
                    />
                </Modal>





            </Content>
        </Layout>

    );

}

export default FeatureList;



