import React, { useState ,useEffect} from 'react';
import {Row, Col, Empty} from "antd";
import 'react-sortable-tree/style.css';
import Loading from "../../library/components/Loading";
import CardDetails from '../../library/components/CardDetails'
import {MARKET_INFO} from "../queries/marketplace/getMarketInfo";

function MarketPlaceDetails(props){

    const [loading, setLoading] = useState(false);
    const [itemId, setItemId] = useState(props.match.params.id ? props.match.params.id : null);
    const [itemInfo, setItemInfo] = useState(props.match.params.id ? props.match.params.id : null);


    /* ------------------------------------------------ */
    /* --------------------- Query -------------------- */
    /* ------------------------------------------------ */

    const getInfo = () =>{
        setLoading(true)
        MARKET_INFO(itemId).then(res => {
            if(res.status === 200) {
                if(res.data && res.data.data)
                {
                    setItemInfo(res.data.data)
                    setLoading(false)
                }
            }
        }).catch(error => {
            console.log(error);
        });

    }

    /* ------------------------------------------------ */
    /* ------------- componentDidMount --------------- */
    /* ------------------------------------------------ */
    useEffect(() => {
        getInfo()
    }, []);

    return(
        <div>
            {loading ?
                <Loading/>
                :
                <Row type="flex" justify="space-around">
                    {itemInfo ?
                            <CardDetails
                                item={itemInfo}
                                type={'market'}
                            />
                        :
                        null
                    }
                </Row>
            }
        </div>
    )

}

export default MarketPlaceDetails;
