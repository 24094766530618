import React, { useState ,useEffect} from 'react';
import {
    Row,
    message,
    Col,
    Divider,
    Button,
} from "antd"; // This only needs to be imported once in your app
import 'react-sortable-tree/style.css';
import Loading from "../../../library/components/Loading";
import 'react-sortable-tree/style.css';
import { STATE_INFO } from "../../queries/state/state";
import {CITY_LIST, DELETE_CITY} from "../../queries/city/city";
import CityLists from "../../../library/components/CityLists";
import _ from "lodash";

function UserList(props){

    const [listLoading, setListLoading] = useState(false);
    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState(props.match.params.id ? props.match.params.id : 0);
    const [stateName, setStateName] = useState('');

    useEffect(() => {
        getStateName()
        getCityList()
    }, []);

    /* ------------------------------------------------ */
    /* ---------------- City Query ----------------- */
    /* ------------------------------------------------ */

    var getCityList = () => {
        setListLoading(true)
        let param = {state: stateId }
        CITY_LIST(param).then(res => {
            if(res.status === 200) {
                setListLoading(false)
                setCityList(res.data.data)
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const deleteCity = (id) => {
        DELETE_CITY(id).then(res => {
            if(res.data.errCode === -6) {
                message.error("there are assigned bazaar/marketplace/event to this city")
            }

            if (res.status === 200) {
                let country = res.data.data
                res ? message.success("the ` " + (country.title.en || country.title.tr) + " ` was deleted.") : message.error("Error in delete city")
                var index = _.findIndex(cityList, {id: id});
                cityList.splice(index, 1);
                getCityList()
            }
        }).catch(error => {
            console.log(error);
        });
    }

    var getStateName = () => {
        setListLoading(true)
        let param = {state: stateId}
        STATE_INFO(stateId).then(res => {
            if(res.status === 200) {
                setStateName(res.data.data.title.en)
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return(
        <div>
            <Row type="flex">
                <Col xs={24} md={20}>
                    <Divider orientation="left">city list of {stateName}</Divider>
                </Col>
                <Col xs={24} md={4} >
                    <Button type="primary" className="userListBtn" shape="round" icon="plus" href={`#/city/add/${stateId}`}>
                        create city in {stateName}
                    </Button>
                </Col>
            </Row>
            <Row>

                <div
                    style={{display: 'flex', flexDirection: 'column', height: '100vh'}}
                >
                    <div style={{flex: '1 0 50%'}}>
                        {listLoading ?
                            <Loading/> :
                            cityList && cityList.length>0 && cityList.map((city,index) =>{
                                return (
                                    <CityLists
                                        key={index}
                                        item={city}
                                        link={'#'}
                                        edit={'/city/edit/' + city.id}
                                        onDelete={deleteCity.bind(this,city.id)}
                                    />
                                )
                            })
                        }

                    </div>
                </div>
            </Row>
        </div>
    )

}

export default UserList;
