import React, {useEffect,useState} from 'react';
import {Col, Divider, Icon, Tag, Tooltip, Row, Slider, Table} from 'antd'
import moment from "jalali-moment";
import {FontAwesome,
    Foundation,
    MaterialIcons,
    Entypo} from 'react-web-vector-icons'
import _ from 'lodash'
import {Link} from "react-router-dom";
import {COUNTRY_INFO} from "../../components/queries/country/country";
import {STATE_INFO} from "../../components/queries/state/state";
import {CITY_INFO} from "../../components/queries/city/city";
import MapContainer from '../components/mapContainer';
import {ImgGallery} from "./imageGallery";
import {USER_INFO} from '../../components/queries/user/getUserInfo'
import ReactStars from "react-rating-stars-component";
import {FEATURE_INFO} from "../../components/queries/feature/getFeatureInfo";
import Loading from "./Loading";

const UserInfo = (props) => {

    const [itemInfo, setItemInfo] = useState('');
    const [loading, setLoading] = useState(true);
    const [countryLoading, setCountryLoading] = useState(true);
    const [stateName, setStateName] = useState('');
    const [cityName, setCityName] = useState('');
    const [countryName, setCountryName] = useState('');

    useEffect(()=>{
        userInfo()
    },[props])

    const userInfo = () => {

        setLoading(true)
        USER_INFO({userId:props.id}).then(res => {
            if(res.status === 200)
                if(res.data && res.data.data)
                {
                    setLoading(false)
                    setItemInfo(res.data.data)
                    if(res.data.data.country)
                        countryInfoRefetch(res.data.data.country)
                    if(res.data.data.province)
                        stateInfoRefetch(res.data.data.province)
                    if(res.data.data.city)
                        cityInfoRefetch(res.data.data.city)
                }
        }).catch(error => {
            console.log(error);
        });
    }

    /* ------------------------------------------------ */
    /* -------------------- Query --------------------- */
    /* ------------------------------------------------ */
    const countryInfoRefetch = (id) => {
        setCountryLoading(true)
        COUNTRY_INFO(id).then(info => {
            if(info.status === 200){
                setCountryLoading(false)
                let data = info.data.data
                setCountryName(data.title.en);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const stateInfoRefetch = (id) => {
        setCountryLoading(true)
        STATE_INFO(id).then(info => {
            if(info.status === 200){
                setCountryLoading(false)
                let data = info.data.data
                setStateName(data.title.en);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const cityInfoRefetch = (id) => {
        setCountryLoading(true)
        CITY_INFO(id).then(info => {
            if(info.status === 200){
                setCountryLoading(false)
                let data = info.data.data
                setCityName(data.title.en);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    let status;
    if(itemInfo.status === 1)
        status = <Tag color="green">accept</Tag>
    else
        status = <Tag color="red">block</Tag>

    return (
        <div>
            {loading || countryLoading ?
                <div style={{textAlign:'center'}}>
                    <Loading/>
                </div>
            :
                <div>
                    <div>
                        <div style={mainPic}>
                            <div>
                                {itemInfo && itemInfo.avatar ?
                                    <img style={img}
                                         src={itemInfo && itemInfo.avatar ?
                                             itemInfo.avatar :
                                             null}/>
                                    : <div style={noImage}>No Image</div>
                                }
                            </div>
                        </div>
                        <div style={moreInfo}>
                            <Row style={{marginBottom: 20, marginTop: 20, paddingLeft: 10, textAlign: 'left'}}>
                                <div>
                                    <b>created at : </b>{itemInfo.createdAt ? moment(itemInfo.createdAt).format('lll') : ''}
                                </div>
                                <div style={{marginTop:10}}>
                                    {status}
                                </div>
                            </Row>
                        </div>
                        <div style={clearFix}></div>
                    </div>
                    <div>
                        <Row style={{marginBottom: 20, marginTop: 20, paddingLeft: 10, textAlign: 'left'}}>
                            <div>
                                <b>name : </b>{itemInfo.name ? itemInfo.name : ''}
                            </div>
                            <div>
                                <b>family : </b>{itemInfo.family ? itemInfo.family : ''}
                            </div>
                            <div>
                                <b>mobile
                                    : </b>{itemInfo.countryCode || itemInfo.mob ? itemInfo.countryCode + itemInfo.mob : ''}
                            </div>
                            <div>
                                <b>email
                                    : </b>{itemInfo.email ? itemInfo.email : ''}
                            </div>
                            <div>
                                <b>timezone
                                    : </b>{itemInfo.timezone ? itemInfo.timezone : ''}
                            </div>
                            <div>
                                <b>country
                                    : </b>{countryName}
                            </div>
                            <div>
                                <b>state
                                    : </b>{stateName}
                            </div>
                            <div>
                                <b>city
                                    : </b>{cityName}
                            </div>
                        </Row>
                    </div>
                </div>
            }
        </div>
    );

}
const mainPic = {
    width: '30%',
    float:'left'
}
const img = {
    width:100,
    height: 100
}
const noImage = {
    background:'#eee',
    color:'#999',
    fontSize:20,
    textAlign:'center',
    padding:'40px 0',
}
const moreInfo = {
    float: 'left',
    marginLeft:20
}
const clearFix = {
    clear:'both'
}
export default UserInfo;


