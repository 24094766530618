import {SIDEBAR_VISIBILITY_SET,USERMENU_VISIBILITY_SET,SET_LOGIN_DATA} from "../components/globalConstants";
import {ImmortalStorage, CookieStore, LocalStorageStore, ImmortalDB} from 'immortal-db';

const stores = [CookieStore, LocalStorageStore];
const db = new ImmortalStorage(stores);

// -------------- CATEGORY Page --------------
export const CATEGORY_FIELD_ERROR       = 'CATEGORY_FIELD_ERROR';
export const CATEGORY_FIELD_RESET_ERROR = 'CATEGORY_FIELD_RESET_ERROR';
export const CATEGORY_GET_ITEM          = 'CATEGORY_GET_ITEM';
export const CATEGORY_SET_ITEM          = 'CATEGORY_SET_ITEM';

// --------- CATEGORY Page ---------
export function categoryFieldError(field, fieldData = {}) {
    return { type: CATEGORY_FIELD_ERROR, fieldName: field, fieldData: fieldData }
}
export function categoryFieldResetError(field, fieldData = {}) {
    return { type: CATEGORY_FIELD_RESET_ERROR }
}
export function categorySetItem(targetItem) {
    return { type: CATEGORY_SET_ITEM, data: targetItem }
}
export function categoryGetItem(targetItem) {
    return { type: CATEGORY_GET_ITEM, data: targetItem }
}
export const commonActions = {
    pagePreLoaded
}

export function pagePreLoaded(viewContainer) {
    let isLoginPage = ['LoginPage', 'RegisterPage'].indexOf(viewContainer) !== -1 ? false : true;
    return dispatch => {
        Promise.all([
            dispatch({type: SIDEBAR_VISIBILITY_SET, isSidebarVisible: isLoginPage}),
            dispatch({type: USERMENU_VISIBILITY_SET, isUsermenuVisible: isLoginPage})
        ]);
    };
}
