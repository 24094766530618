//--- React Hook
import React, {useState, useEffect} from 'react';

//--- ant design
import {
    Row, Button, Icon,  Typography, Popover,Layout, Tag,
} from 'antd';


import TextCustomField, { mkTextCustomField } from './fields/TextCustomField';
import NumberCustomField, { mkNumberCustomField } from './fields/NumberCustomField';
import SelectBoxCustomField, { mkSelectboxCustomField } from './fields/SelectBoxCustomField';
import CheckBoxCustomField, { mkCheckboxCustomField } from './fields/CheckBoxCustomField';
import SwitchCustomField, { mkSwitchCustomField } from './fields/SwitchCustomField';
import _ from 'lodash'

// and design component
const { Header, Content, Footer } = Layout;
const { Title } = Typography;

const CustomFieldList = (props) => {

    /* ------------------------------------------------ */
    /* ---------------- Define State ------------------ */
    /* ------------------------------------------------ */
    var fieldList =  props.list ? props.list : [];

    /* ------------------------------------------------ */
    /* ------------- like componentDidMount --------------- */
    /* ------------------------------------------------ */
    useEffect(() => {

    }, []);

    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */

    var onAddCustomField = (type)=>{
        var newField = null;
        switch (type) {
            case 'text':
                newField = mkTextCustomField();
                break;
            case 'number':
                newField = mkNumberCustomField();
                break;
            case 'dropdown':
                newField = mkSelectboxCustomField();
                break;
            case 'checkbox':
                newField = mkCheckboxCustomField();
                break;
            case 'switch':
                newField = mkSwitchCustomField();
                break;
        }
        if(newField){
            newField.id = 'field' + new Date().getTime(); // make unique id
            fieldList.push(newField);
            props.onChange(fieldList);
        }
    }

    var onchangeField = (fieldIndex,item,newValue)=>{
        props.onChange(fieldList);
    }

    var onDeleteField = (fieldIndex,item,newValue)=>{
        fieldList.splice(fieldIndex,1);
        props.onChange(fieldList);
    }


    /* ------------------------------------------------ */
    /* -----------------  R E N D E R ----------------- */
    /* ------------------------------------------------ */
    return (
        <Layout col={12}  style={{marginTop:"50px",marginBottom:"50px",textAlign:'left'}}>
            <Content >
                <Title level={3}>custom Fields</Title>

                <Row>
                    {fieldList && fieldList.map((item,index)=>{

                        if(item.id == undefined)
                            item.id = 'field'+new Date().getTime();

                        switch (item.type) {
                            case 'text':
                                return <TextCustomField
                                            key={'text'+index}
                                            data={item}
                                            onChange={onchangeField.bind(this,index,item)}
                                            onDelete={onDeleteField.bind(this,index,item)}
                                        />
                                break;
                            case 'number':
                                return <NumberCustomField
                                            key={'number'+index}
                                            data={item}
                                            onChange={onchangeField.bind(this,index,item)}
                                            onDelete={onDeleteField.bind(this,index,item)}
                                        />
                                break;
                            case 'dropdown':
                                return <SelectBoxCustomField
                                            key={'select'+index}
                                            data={item}
                                            onChange={onchangeField.bind(this, index, item)}
                                            onDelete={onDeleteField.bind(this, index, item)}
                                        />
                                break;
                            case 'checkbox':
                                return <CheckBoxCustomField
                                    key={'check'+index}
                                    data={item}
                                    onChange={onchangeField.bind(this, index, item)}
                                    onDelete={onDeleteField.bind(this, index, item)}
                                />
                                break;
                            case 'switch':
                                return <SwitchCustomField
                                    key={'switch'+index}
                                    data={item}
                                    onChange={onchangeField.bind(this, index, item)}
                                    onDelete={onDeleteField.bind(this, index, item)}
                                />
                                break;
                        }
                    })}

                </Row>

                <Popover placement="right" trigger="click" content={
                    <div>
                        <div><Button onClick={onAddCustomField.bind(this, 'text')} type="link"><Tag color="purple">Text</Tag></Button></div>
                        <div><Button onClick={onAddCustomField.bind(this, 'dropdown')} type="link"><Tag color="blue">SelectBox</Tag></Button></div>
                        <div><Button onClick={onAddCustomField.bind(this, 'number')} type="link"><Tag color="cyan">Number</Tag></Button></div>
                        <div><Button onClick={onAddCustomField.bind(this, 'checkbox')} type="link"><Tag color="green">CheckBox</Tag></Button></div>
                        <div><Button onClick={onAddCustomField.bind(this, 'switch')} type="link"><Tag color="gold">Switch</Tag></Button></div>
                    </div>
                } title="Types">
                    <Button style={{ marginTop: '50px', felx: '1', width: 500, }}><Icon type="plus" key="plus" /> Add Field</Button>
                </Popover>



            </Content>
        </Layout>

    );

}

export default CustomFieldList;



