import React, { useState ,useEffect} from 'react';
import {Row, Empty, Pagination, Input, Icon, Col, Divider, Button, Form, Select, TreeSelect} from "antd";
import 'react-sortable-tree/style.css';
import {AntDesign} from 'react-web-vector-icons'
import Loading from "../../library/components/Loading";
import _ from 'lodash'
import CardLists from '../../library/components/CardLists'
import {EVENT_LIST} from "../queries/event/getList";
import {EVENT_SEARCH} from "../queries/event/eventSearch";
import SelectField from "../../library/components/SelectField";
import {COUNTRY_LIST} from "../queries/country/country";
import {STATE_LIST} from "../queries/state/state";
import {CITY_LIST} from "../queries/city/city";
import {CATEGORY_LIST} from "../queries/category/getCategoryList";
import {USER_LIST} from "../queries/user/getUserList";

function EventList(props){

    const [pageSize, setPageSize] = useState(12);
    const [current, setCurrent] = useState(props.list ? props.list.pageNumber : 1);
    const [count, setCount] = useState();
    const [filter, setFilter] = useState([]);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [form, setForm] = useState([]);
    const [searchBoxHidden, setSearchBoxHidden] = useState(true);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [treeCat, setTreeCat] = useState([]);
    const [cityValue, setCityValue] = useState('');
    const [userList, setUserList] = useState([]);
    const [userValue, setUserValue] = useState('');
    const {Option} = Select;

    useEffect(() => {
        if(_.isEmpty(filter))
            eventList(0,pageSize)
        else
            searchEvent(filter)
        getCountryList()
        categoryListData();
        getUserList();
    }, []);

    /* ------------------------------------------------ */
    /* --------------------- Query -------------------- */
    /* ------------------------------------------------ */

    const eventList = (skip,size) =>{
        setLoading(true)
        let param = {
            skip  : skip,
            limit : size
        }
        EVENT_LIST(param).then(res => {
            if(res.status === 200) {
                if(res.data && res.data.data)
                {
                    setList(res.data.data)
                    setCount(res.data.totalOfEvents)
                    setLoading(false)
                }
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const goBack = () => {
        this.props.history.goBack()
    }

    /*--- pagination ---*/
    const handlePageChange = async(pageNumber) => {
        let limit = pageSize
        let skip = (pageNumber-1)*pageSize
        // clearInterval(this.interval);
        setCurrent(pageNumber);
        setFilter(prevState  => (
            {...prevState.filter,page:pageNumber}
        ))
        eventList(skip,limit);
    }


    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */
    var handleTextInput = (name, e) => {
        const {value} = e.target;
        let newForm = {...form};
        newForm[name] = value;
        setForm(newForm)
    };

    /* ------------------------------------------------ */
    /* ----------------- Search  ---------------------- */
    /* ------------------------------------------------ */
    var searchEvent = (data) =>  {
        setButtonLoading(true);
        setLoading(true);
        setFilter(prevState  => {
            return {...prevState.filter, ...data}
        })
        EVENT_SEARCH(data).then(res => {
            let eventList = []
            if(res.status === 200) {
                setLoading(false);
                setButtonLoading(false);
                res.data.data.map((item)=>{
                    eventList.push(item.event)
                })
                setCount(res.data.totalOfEvents)
                setList(eventList)
            }else
                return 'error'
        }).catch(error => {
            console.log(error);
        });

    }

    const toggleSearchBox = () =>{
        setSearchBoxHidden(!searchBoxHidden)
    }

    const clearFilter = () => {
        setFilter([])
        setForm([])
        eventList();
    }

    /* ------------------------------------------------ */
    /* --------------- Area Select Box ---------------- */
    /* ------------------------------------------------ */

    const getCountryList = () =>{
        let country = []
        COUNTRY_LIST.then(res => {
            if (res.status === 200) {
                res.data.data.map((item,index)=>{
                    country.push(
                        {label: item.title.en, value: item.id}
                    )
                })

                setCountryList(country)
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const getStateList = (id) =>{
        let state = []
        if(!_.isEmpty(id))
        {
            STATE_LIST({country:id}).then(res => {
                if (res.status === 200) {
                    res.data.data.map((item,index)=>{
                        state.push(
                            {label: item.title.en, value: item.id}
                        )
                    })
                    setStateList(state)
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    const getCityList = (id) =>{
        let city = []
        if(!_.isEmpty(id))
        {
            CITY_LIST({state:id}).then(res => {
                if (res.status === 200) {
                    res.data.data.map((item,index)=>{
                        city.push(
                            {label: item.title.en, value: item.id}
                        )
                    })

                    setCityList(city)
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    var onchangeCountry = (value)=>{
        let newForm = {...form};
        newForm['country'] = value;
        newForm['state'] = '';
        newForm['city'] = '';
        setForm(newForm)
        getStateList(value)
    }

    var onchangeState = (value)=>{
        let newForm = {...form};
        newForm['state'] = value;
        newForm['city'] = '';
        setForm(newForm)
        getCityList(value)
    }

    var onchangeCity = (value)=>{
        let newForm = {...form};
        newForm['city'] = value;
        setForm(newForm)
        setCityValue(value)
    }

    /* ------------------------------------------------ */
    /* ------------- Category Select Box -------------- */
    /* ------------------------------------------------ */
    const renderHTML = (rawHTML) => React.createElement("div", {dangerouslySetInnerHTML: {__html: rawHTML}});

    const categoryListData = () => {
        CATEGORY_LIST('EVENT').then(list => {
            if(list.status === 200) {
                if (list.data.data.length > 0) {
                    setCategoryList(list.data.data);
                } else
                    setCategoryList([]);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const categoryListDataWithpId = () => {
        let newList = []
        if (categoryList) {
            for (let i in categoryList) {
                newList.push({
                    id: categoryList[i]['id'],
                    expanded: true,
                    pId: categoryList[i]['parent'] ? categoryList[i]['parent'] : 0,
                    title: categoryList[i]['title'] && categoryList[i]['title']['en'] ? renderHTML(categoryList[i]['title']['en']) : '',
                    value: categoryList[i]['id'],
                    key: categoryList[i]['key'],
                })
            }

        }
        return newList;
    }

    var handleTreeSelect = (name, val) => {
        let newForm = {...form};
        newForm[name] = val;
        setForm(newForm)
    };

    let newList = []
    var getParents = (parent, id) => {

        if (parent != 0 && parent != null) {
            newList.unshift(parent)
            var newElement = _.find(categoryList, parent)
            if (newElement && newElement.parent != null && newElement.parent != 0)
                getParents(newElement.parent, newElement.id)
        } else
            newList.unshift(id)


        return _.uniq(newList);
    }

    var handleTreeCat = (value, node) => {
        let _ = require('lodash')
        let pId = node.props.pId;
        let id = node.props.id;
        var newList = getParents(pId, id)
        if (props.match.params.id)
            newList.push(id)
        setTreeCat(_.uniq(newList))
    };

    /* ------------------------------------------------ */
    /* ------------------ user list ------------------- */
    /* ------------------------------------------------ */
    let users = []
    const getUserList = () =>{
        USER_LIST.then(res => {
            if(res.status === 200) {
                res.data.data.map((item,index)=>{
                    users.push(
                        {label: item.name+' '+item.family, value: item.id}
                    )
                })
                setUserList(users)
            }
        }).catch(error => {
            console.log(error);
        });

    }

    var onchangeUser = (value)=>{
        let newForm = {...form};
        newForm['user'] = value;
        setForm(newForm)
        setUserValue(value)
    }

    const changeList = (list) => {
        let newList = {...list}
        setStateList(newList)
    }

    return(
        <div>
            <Row>
                <Col xs={24} md={22}>
                    <Divider orientation="left">events list</Divider>
                </Col>
                <Col xs={24} md={2} >
                    <Button type="primary" shape="round" className="searchBtnBox" onClick={toggleSearchBox.bind(this)} >
                        <AntDesign color={'#ffffff'} size={20} name={'search1'} />
                    </Button>
                </Col>
            </Row>
            <Row hidden={searchBoxHidden} gutter={16} style={{marginBottom:10}}>
                <Form onSubmit={e => {
                    e.preventDefault();
                    searchEvent({
                        term    : form.term,
                        options : {
                            skip       : 0,
                            limit      : pageSize,
                            price_start: form.price_start ? form.price_start : null,
                            price_end  : form.price_end ? form.price_end : null,
                            distance   : form.distance ? form.distance : null,
                            countryID  : form.country ? form.country : null,
                            stateID    : form.state ? form.state : null,
                            cityID     : form.city ? form.city : null,
                            userId     : form.user ? form.user : null,
                            status     : form.status && form.status!=2 ? form.status : form.status === 2 ? 0 : null,
                        }
                    });
                }}>
                    <Col md={4} xs={6}>
                        <Input
                            prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder={"search"}
                            value={form.term}
                            onChange={handleTextInput.bind(this, 'term')}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <Input
                            prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder={"price start"}
                            value={form.price_start}
                            onChange={handleTextInput.bind(this, 'price_start')}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <Input
                            prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder={"price end"}
                            value={form.price_end}
                            onChange={handleTextInput.bind(this, 'price_end')}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <SelectField
                            name={'country'}
                            list={countryList}
                            onChange={onchangeCountry.bind(this)}
                            style={{paddingLeft:0}}
                            value={form.country}
                            placeholder={'country'}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <SelectField
                            name={'state'}
                            list={stateList}
                            onChange={onchangeState.bind(this)}
                            value={form.state}
                            placeholder={'state'}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <SelectField
                            name={'city'}
                            list={cityList}
                            onChange={onchangeCity.bind(this)}
                            style={{paddingRight:0}}
                            value={form.city}
                            placeholder={'city'}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <TreeSelect
                            name={"cat_id"}
                            treeData={categoryListDataWithpId()}
                            treeDataSimpleMode
                            placeholder={"category"}
                            treeDefaultExpandAll={true}
                            onChange={handleTreeSelect.bind(this, 'parent')}
                            onSelect={handleTreeCat.bind(this)}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <SelectField
                            name={'status'}
                            list={[
                                {label: 'pending', value: 2},
                                {label: 'approved', value: 1},
                                {label: 'rejected', value: -1},
                            ]}
                            onChange={value => setForm({status:value})}
                            value={form.status}
                            placeholder={'status'}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <SelectField
                            name={'user'}
                            list={userList}
                            onChange={onchangeUser.bind(this)}
                            style={{paddingRight:0}}
                            value={form.user}
                            placeholder={"users"}
                        />
                    </Col>
                    <Col sm={24} md={2}>
                        <Button loading={buttonLoading} className="left" type="primary" shape="round" htmlType="submit">
                            search
                        </Button>
                    </Col>
                    <Col sm={24} md={2}>
                        <Button className="left" type="default" shape="round" onClick={clearFilter.bind(this)}>
                            reset
                        </Button>
                    </Col>
                </Form>
            </Row>
            <Row>
                {loading ?
                    <Loading/>
                    :
                    <Row type="flex" justify="start">
                        {list && list.length > 0 ?
                            list.map((item, index) => {
                                let userInfo;
                                let userId;
                                userList.map((user,index)=>{
                                    if(item.ownerInfo && item.ownerInfo.id && (user.value === item.ownerInfo.id)){
                                        userInfo = user.label
                                        userId = user.value
                                    }else if(item.ownerInfo === undefined && (user.value === item.ownerId)){
                                        userInfo = user.label
                                        userId = user.value
                                    }
                                })

                                return (
                                    <CardLists
                                        key={index}
                                        item={item}
                                        list={list}
                                        type={'event'}
                                        userInfo={userInfo}
                                        userId={userId}
                                        onChange={changeList.bind(this)}
                                    />
                                )
                            })
                            :
                            <Empty style={{margin:'auto'}}/>
                        }
                    </Row>

                }
                <Row type="flex" justify="end" align="top">
                    <Pagination
                        name="pageNum"
                        current={current}
                        total={count}
                        pageSize={pageSize}
                        onChange={(pageNum) => handlePageChange(pageNum)}
                    />
                </Row>
            </Row>
        </div>
    )

}

export default EventList;
