//--- React Hook
import React, {useState, useEffect} from 'react';

//--- ant design
import {
    Button, Icon, Input, Card, Tag, Popconfirm
} from 'antd';
import _ from "lodash";


function TextCustomField(props) {

    /* ------------------------------------------------ */
    /* ------------- like componentDidMount --------------- */
    /* ------------------------------------------------ */
    useEffect(() => {
    }, []);


    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */

    var deleteField = () => {
        props.onDelete();
    }

    var onChangeTitle = (type, e) => {
        let value = e.target.value;
        let newCutomFieldObj = {...props.data};
        newCutomFieldObj.title[type] = value;
        props.onChange(newCutomFieldObj);
    }

    var onChangePlaceHolder = (type, e) => {
        let value = e.target.value;
        let newCutomFieldObj = {...props.data};
        newCutomFieldObj.placeHolder[type] = value;
        props.onChange(newCutomFieldObj);
    }


    var field = props.data;


    /* ------------------------------------------------ */
    /* -----------------  R E N D E R ----------------- */
    /* ------------------------------------------------ */
    return (
        <Card
            title={<Tag title={field.id} color="purple">Text Field </Tag>}
            style={{marginTop: 20}}
            extra={<Popconfirm placement="top"
                               title={'maybe some bazaars/marketplaces/events uses this field , so are you sure to delete this field ?'}
                               onConfirm={deleteField.bind(this)}
                               icon={<Icon type="question-circle-o" style={{color: 'red'}}/>} okText="Yes"
                               cancelText="No"><Button type="danger" ghost>delete</Button></Popconfirm>}
        >

            <Input.Group compact>
                <h5 style={{width: '20%'}}>Field Title </h5>
                <Input required placeholder="عنوان به فارسی" value={field.title && field.title.fa ? field.title.fa : null}
                       onChange={onChangeTitle.bind(this, 'fa')} style={{width: '33%',fontFamily:'IRANSans'}}/>
                <Input required placeholder="English Title" value={field.title && field.title.en ? field.title.en : null}
                       onChange={onChangeTitle.bind(this, 'en')} style={{width: '33%'}}/>
            </Input.Group>


            <Input.Group compact>
                <h5 style={{width: '20%'}}>Field PlaceHolder </h5>
                <Input placeholder="عنوان به فارسی" defaultValue={field.placeHolder && field.placeHolder.fa ? field.placeHolder.fa : null}
                       onChange={onChangePlaceHolder.bind(this, 'fa')} style={{width: '33%',fontFamily:'IRANSans'}}/>
                <Input placeholder="English Title" defaultValue={field.placeHolder && field.placeHolder.en ? field.placeHolder.en : null}
                       onChange={onChangePlaceHolder.bind(this, 'en')} style={{width: '33%'}}/>
            </Input.Group>

        </Card>

    );

}

const mkTextCustomField = () => {

    return {
        type: 'text',
        title: {
            fa: null,
            en: null,
        },
        placeHolder: {
            fa: null,
            en: null,
        },
        isRequire: null,
    }
};

export const findEmptyTextField = (params) => {
    let items;
    if(!_.isEmpty(params.title.en) && !_.isEmpty(params.title.fa) &&
        params.title.en !== null && params.title.fa !== null)
        items = params

    return items;

};


export default TextCustomField;
export {mkTextCustomField};



