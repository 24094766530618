import React from 'react';
import {Col, Tooltip, Modal} from 'antd'
import {
    Feather,
    AntDesign
} from 'react-web-vector-icons'
import {Link} from "react-router-dom";

const CityLists = (props) => {

    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (id) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                props.onDelete(id)
            },
            onCancel() {},
        });
    }

    return (
        <Col style={cardStyle} md={18}>
            <Link style={{color:'rgba(0, 0, 0, 0.65)'}} to={props.link}>
                <Col md={20} xs={24} style={title}>
                    {props.item.title ? props.item.title.en+' - '+props.item.title.fa+' - '+props.item.title.tr : ''}
                </Col>
            </Link>
            <Col md={4} xs={24} style={{textAlign:'right'}}>
                {props.add ?
                <Link to={props.add} className='categoryIcon'>
                    <Tooltip title="add to this category">
                        <Feather name="plus" size={15} color={'lightseagreen'} />
                    </Tooltip>
                </Link>
                : null}
                {props.edit ?
                <Link to={props.edit} className='categoryIcon'>
                    <Tooltip title="edit this category">
                        <AntDesign name="edit" size={15} color={'#FFC107'} />
                    </Tooltip>
                </Link>
                : null}
                <a onClick={showConfirm.bind(this,props.item.id)} className='categoryIcon'>
                    <Tooltip title="delete this category">
                        <AntDesign name="delete" size={15} color={'red'} />
                    </Tooltip>
                </a>
            </Col>
        </Col>
    );

}
const statusStyle = {
    borderRight:'1px solid #e8e8e8',
    borderLeft:'1px solid #e8e8e8',
    padding:'10px 0'
}
const cardStyle = {
    borderRadius:1,
    marginBottom:5,
    padding:5,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
}
const mainPic = {
    height:200,
    overflow:'hidden'
}
const img = {
    width:'100%',
}
const title = {
    textAlign:'left',
    padding:5,
    fontWeight:600,
    paddingLeft:30

}
const desc = {
    textAlign:'left',
    padding:5,
    color:'#74788d',
    borderBottom:'1px solid #e8e8e8',
    height: 90
}
const footer = {
    borderBottom: '1px solid #e8e8e8'
}
const noImage = {
    background:'#eee',
    color:'#999',
    fontSize:20,
    align:'center',
    padding:'85px 0'
}
const icon = {
    marginRight:10,
    marginLeft:10,
    color:'#556EE6'
}

export default CityLists;


