import React, { useState ,useEffect} from 'react';
import {
    Row,
    message,
    Col,
    Divider,
    Button,
} from "antd"; // This only needs to be imported once in your app
import 'react-sortable-tree/style.css';
import Loading from "../../../library/components/Loading";
import {COUNTRY_LIST, DELETE_COUNTRY} from "../../queries/country/country"
import 'react-sortable-tree/style.css';
import CityLists from "../../../library/components/CityLists";
import _ from "lodash";

function UserList(props){
    const [listLoading, setListLoading] = useState(false);
    const [countryList, setCountryList] = useState([]);

    useEffect(() => {
        if(props && props.history.action === 'PUSH')
            window.location.reload();

        getCountryList()
    }, []);

    /* ------------------------------------------------ */
    /* ---------------- Country Query ----------------- */
    /* ------------------------------------------------ */

    const getCountryList = () => {
        setListLoading(true)
        COUNTRY_LIST.then(list => {
            if(list.status === 200) {
                if (list.data.data.length > 0) {
                    setListLoading(false)
                    setCountryList(list.data.data)
                } else
                    return list;
            }

        }).catch(error => {
            console.log(error);
        });

    }

    const deleteCountry = (id) => {
        DELETE_COUNTRY(id).then(res => {
            if(res.data.errCode === -6) {
                message.error("there are assigned bazaar/marketplace/event to this country")
            }

            if (res.status === 200) {
                let country = res.data.data
                res ? message.success("the ` " + (country.title.en || country.title.tr) + " ` was deleted.") : message.error("Error in delete country")
                var index = _.findIndex(countryList, {id: id});
                countryList.splice(index, 1);
                getCountryList()
                props.history.push("/country")
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return(
        <div>
            <Row type="flex">
                <Col xs={24} md={20}>
                    <Divider orientation="left">country list</Divider>
                </Col>
                <Col xs={24} md={4} >
                    <Button type="primary" className="userListBtn" shape="round" icon="plus" href="#/country/add">
                        create country
                    </Button>
                </Col>
            </Row>
            <Row>

                <div
                    style={{display: 'flex', flexDirection: 'column', height: '100vh'}}
                >
                    <div style={{flex: '1 0 50%'}}>
                        {listLoading ?
                            <Loading/> :
                            countryList && countryList.length>0 && countryList.map((country,index) =>{
                                return (
                                    <CityLists
                                        key={index}
                                        item={country}
                                        link={'/state/'+country.id}
                                        edit={'/country/edit/' + country.id}
                                        add={'/state/add/' + country.id}
                                        onDelete={deleteCountry.bind(this,country.id)}
                                    />
                                )
                            })
                        }

                    </div>
                </div>
            </Row>
        </div>
    )

}

export default UserList;
