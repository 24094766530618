import React, { useState ,useEffect} from 'react';
import {Row, Icon, Table, message, Col, Pagination, Tooltip, Divider, Tag, Button, Modal} from "antd"; // This only needs to be imported once in your app
import 'react-sortable-tree/style.css';
import Loading from "../../library/components/Loading";
import {USER_LIST} from "../queries/user/getUserList"
import {CHANGE_STATUS} from "../queries/user/changeUserStatus"
import moment from 'jalali-moment'
import _ from 'lodash'
import {Ionicons,
    MaterialCommunityIcons,
    MaterialIcons,
    Entypo,AntDesign,EvilIcons} from 'react-web-vector-icons'
import {STATE_INFO} from "../queries/state/state";
import {Link} from "react-router-dom";
import {ADMIN_LIST} from "../queries/admin/getAdminList";
import {DELETE_ADMIN} from "../queries/admin/admin";
import {DELETE_CATEGORY} from "../queries/category/DeleteCategory";

const columns = [
    {
        title: 'id',
        dataIndex: 'key',
        width:'5%'
    },
    {
        title: 'fullname',
        dataIndex: 'fullname',
    },
    {
        title: 'username',
        dataIndex: 'username',
    },
    {
        title: 'phone',
        dataIndex: 'phone',
    },
    {
        title: 'create date',
        dataIndex: 'date',
    },
    {
        title: 'action',
        dataIndex: 'action',
        width:'15%'
    },
];

function AdminList(props){

    const [list, setList] = useState([]);
    const [userListLoading, setUserListLoading] = useState(false);


    /* ------------------------------------------------ */
    /* --------------------- Query -------------------- */
    /* ------------------------------------------------ */

    const adminList = () =>{
        setUserListLoading(true)
        ADMIN_LIST.then(res => {
            if(res.status === 200) {
                if(res.data && res.data.data)
                {
                    setList(res.data.data)
                    setUserListLoading(false)
                }
            }
        }).catch(error => {
            console.log(error);
        });

    }

    /* ------------------------------------------------ */
    /* ------------- componentDidMount --------------- */
    /* ------------------------------------------------ */
    useEffect(() => {
        adminList()
    }, []);

    const deleteAdmin = (id) => {
        DELETE_ADMIN({userId:id}).then(res => {
            if (res.status === 200) {
                let admin = res.data.data
                res ? message.success("delete successful") : message.error("Error in delete admin")
                var index = _.findIndex(list, {id: id});
                list.splice(index, 1);
                adminList();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /*--- fix user list for show in table ---*/
    const fixListForShowInTable = () => {
        var dataSource = [];

        if(list) {

            for (let i = 0; i < list.length; i++) {

                dataSource.push({
                    // key: (pageSize*(list.page_number-1))+(i+1),
                    key: i+1,
                    fullname: list[i].fullname ? list[i].fullname : '',
                    username: list[i].username ? list[i].username : '',
                    phone: list[i].phone ? list[i].phone : '',
                    date: list[i].createdAt ? moment(list[i].createdAt).format('DD/M/YYYY') : '',
                    action: <span>
                                <Tooltip placement="top" title="edit">
                                    <Link to={"/admin/edit/"+list[i].id} >
                                        <MaterialCommunityIcons name={'pencil-circle'} size={30} color={'#FFC107'} />
                                    </Link>
                                </Tooltip>
                                <Divider type="vertical" />
                                <Tooltip placement="top" title="delete">
                                    <a onClick={showConfirm.bind(this,list[i].id)} >
                                        <MaterialCommunityIcons name={'close-circle'} size={30} color={'red'} />
                                    </a>
                                </Tooltip>
                            </span>
                });
            }
        }
        return dataSource;
    }

    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (id) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                deleteAdmin(id);
            },
            onCancel() {},
        });
    }

    const goBack = () => {
        this.props.history.goBack()
    }

    return(
        <div>
            <Row type="flex">
                <Col xs={24} md={20}>
                    <Divider orientation="left">admin list</Divider>
                </Col>
                <Col xs={24} md={4} >
                    <Button type="primary" className="userListBtn" shape="round" icon="plus" href="#/admin/add">
                        create admin
                    </Button>
                </Col>
            </Row>
            <Row>
                {userListLoading ?
                    <Loading />
                    :
                    <Table rowClassName={(record, index) => (index + 1) % 2 == 0 ? 'even' : 'odd'} pagination={true} columns={columns} dataSource={fixListForShowInTable()} size="small" />
                }
            </Row>
        </div>
    )

}

export default AdminList;
