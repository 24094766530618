import {API_FETCH} from "../../../library/utils/FetchService";

export const TIPS_LIST = (param) => {
    return API_FETCH.post('super/getAllTips',param)
}

export const DELETE_TIPS = (param) => {
    return API_FETCH.delete('super/deleteTip',{data:param})
}

export const TIP_REPORT_LIST = (param) => {
    return API_FETCH.post('super/tipsReport/getAllReport',param)
}
export const DELETE_TIPS_REPORT = (id) => {
    return API_FETCH.delete('super/tipsReport/deleteReport/'+id)
}

