//--- React Hook
import React, {useState, useEffect , useRef} from 'react';

//--- ant design
import {
    Row, Form, Divider, Col, Button, Icon, Input, TreeSelect, message, Modal,
} from 'antd';

/* ------ custom -------- */
import Loading from "../../../library/components/Loading";
import _ from 'lodash'

import {EDIT_COUNTRY} from "../../queries/country/country";
import {COUNTRY_INFO} from "../../queries/country/country";
import {ADD_COUNTRY} from "../../queries/country/country";
import {DELETE_COUNTRY} from "../../queries/country/country";


const CountryAddEdit = (props) => {

    /* ------------------------------------------------ */
    /* ---------------- Define State ------------------ */
    /* ------------------------------------------------ */
    const [form, setFormField] = useState({
        title_fa: '',
        title_en: '',
        title_tr: '',
        isoCode: '',
    });

    const [mode, setMode] = useState(props.match.params.id ? 'edit' : 'add');
    const [id, setId] = useState(props.match.params.id ? props.match.params.id : '');
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);


    useEffect(() => {

        /*---- edit mode ---*/
        if (props.match.params.id) {
            let id = props.match.params.id
            countryInfoRefetch(id);
        }

    },[] );

    /* ------------------------------------------------ */
    /* -------------------- Query --------------------- */
    /* ------------------------------------------------ */
    const countryInfoRefetch = (id) => {
        setLoading(true)
        COUNTRY_INFO(id).then(info => {
            if(info.status === 200){
                setLoading(false)
                let data = info.data.data
                setFormField({
                    title_tr : mode === 'edit' ? data.title.tr : '',
                    title_en : mode === 'edit' ? data.title.en : '',
                    title_fa : mode === 'edit' ? data.title.fa : '',
                    isoCode : mode === 'edit' ? data.isoCode : '',

                });
            }
        }).catch(error => {
            console.log(error);
        });

    }

    var addCountry = (data) =>  {
        console.log(data)
        setButtonLoading(true);
        ADD_COUNTRY(data).then(res => {
            if(res.status === 201) {
                res && res.data ? message.success("the ` " + (res.data.data.title.en || res.data.data.title.tr)+ " ` was created.") : message.error("Error")
                setButtonLoading(false);
            }else
                return 'error'
        }).catch(error => {
            console.log(error);
        });

    }

    var updateCountry = (data) =>  {
        setButtonLoading(true);
        data['id'] = id
        EDIT_COUNTRY(data).then(res => {
            if(res.status === 200) {
                res ? message.success("the ` " + (res.data.data.title.en || res.data.data.title.tr)+ " ` was updated.") : message.error("Error")
                setButtonLoading(false);
            }else
                return 'error'
        }).catch(error => {
            console.log(error);
        });

    }

    const deleteCountry = (id) => {
        setDeleteLoading(true)
        DELETE_COUNTRY(id).then(res => {
            if(res.data.errCode === -6) {
                message.error("there are assigned bazaar/marketplace/event/state/city to this country",5)
            }

            if (res.status === 200) {
                let country = res.data.data
                res ? message.success("the ` " + (country.title.en || country.title.tr) + " ` was deleted.") : message.error("Error in delete feature")
                props.history.push("/country")
                setDeleteLoading(false)
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (e) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                e.preventDefault();
                deleteCountry(id);
            },
            onCancel() {},
        });
    }


    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */
    var handleTextInput = (name, e) => {
        const {value} = e.target;
        let newForm = {...form};
        newForm[name] = value;
        setFormField(newForm)
    };

    /* ------------------------------------------------ */
    /* -----------------  R E N D E R ----------------- */
    /* ------------------------------------------------ */

    return (

        <Form onSubmit={e => {
            e.preventDefault();
            (mode === 'edit' ? updateCountry : addCountry)({
                title: {
                    en: form.title_en,
                    fa: form.title_fa,
                    tr: form.title_tr,
                },
                isoCode: form.isoCode

            });
        }} className="login-form">
            <Row type="flex">
                <Col xs={24} md={mode === 'edit' ? 20 : 24}>
                    <Divider orientation="left">{mode === 'edit' ? "edit" : "add"} country</Divider>
                </Col>
                <Col xs={24} md={mode === 'edit' ? 4 : 0}>
                    <Button type="primary" className="userListBtn" shape="round" icon="minus" loading={deleteLoading ? true : false}
                            onClick={showConfirm.bind(this)}>
                        delete country
                    </Button>
                </Col>
            </Row>
            {mode === 'edit' && loading ?
                <Loading/>
                :
                <Row type="flex" justify="start" align="middle">
                    <Col md={18} sm={24} type="flex" justify="start" align="middle">
                        <Row gutter={16}>

                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"english title"}
                                value={form.title_en}
                                onChange={handleTextInput.bind(this, 'title_en')}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"persian title"}
                                value={form.title_fa}
                                onChange={handleTextInput.bind(this, 'title_fa')}
                                style={{fontFamily:'IRANSans'}}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"turkish title"}
                                value={form.title_tr}
                                onChange={handleTextInput.bind(this, 'title_tr')}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"abbreviation: for example IR"}
                                value={form.isoCode}
                                onChange={handleTextInput.bind(this, 'isoCode')}
                            />
                        </Row>

                        <Row>
                            <Button loading={buttonLoading} type="primary" shape="round"
                                    htmlType="submit"
                                    className="login-form-button">
                                {mode === 'edit' ? "edit" : "add"}
                            </Button>
                            <Button onClick={()=>props.history.push("/country")} className="backBtn" type="default" shape="round">
                                back
                            </Button>
                        </Row>
                    </Col>
                </Row>
            }


        </Form>

    );

}

export default CountryAddEdit;



