import {API_FETCH} from "../../../library/utils/FetchService";

export const CITY_INFO = (id) =>{
    return API_FETCH.get('super/city/'+id)
}

export const CITY_LIST = (param) => {
    return API_FETCH.post('super/city',param)
}

export const ADD_CITY = (param) =>{
    return API_FETCH.post('super/city/create',param)
}

export const EDIT_CITY = (param) =>{
    return API_FETCH.put('super/city/'+param.id,param)
}

export const DELETE_CITY = (id) =>{
    return API_FETCH.delete('super/city/'+id)
}