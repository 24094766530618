export const FORM_SUCCESS            = 'FORM_SUCCESS';
export const FORM_REQUEST            = 'FORM_REQUEST';
export const FORM_FAILURE            = 'FORM_FAILURE';
export const LOGOUT                  = 'LOGOUT';
export const LOGIN                   = 'LOGIN';
export const SET_LOGIN_DATA          = 'SET_LOGIN_DATA';
export const APP_LOAD                = 'APP_LOAD';
export const PAGE_REDIERCT           = 'PAGE_REDIERCT';
export const REDIRECTED              = 'REDIRECTED';
export const COMMON_IMAGE_UTL        = 'COMMON_IMAGE_UTL';
export const SIDEBAR_VISIBILITY_SET  = 'SIDEBAR_VISIBILITY_SET';
export const USERMENU_VISIBILITY_SET = 'USERMENU_VISIBILITY_SET';
export const UPDATE_FIELD_AUTH       = 'UPDATE_FIELD_AUTH';
export const PAGEL_PRE_LOADED        = 'PAGEL_PRE_LOADED';

export const ALERT_SUCCESS           = 'ALERT_SUCCESS';
export const ALERT_ERROR             = 'ALERT_ERROR';
export const ALERT_CLEAR             = 'ALERT_CLEAR';

export const NOTIFICATION_SUCCESS    = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_ERROR      = 'NOTIFICATION_ERROR';
export const NOTIFICATION_CLEAR      = 'NOTIFICATION_CLEAR';

export const REGISTER_REQUEST        = 'USERS_REGISTER_REQUEST';
export const REGISTER_SUCCESS        = 'USERS_REGISTER_SUCCESS';
export const REGISTER_FAILURE        = 'USERS_REGISTER_FAILURE';
export const LOGIN_REQUEST           = 'USERS_LOGIN_REQUEST';
export const LOGIN_SUCCESS           = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE           = 'USERS_LOGIN_FAILURE';
export const LOGOUT_REQUEST          = 'USERS_LOGOUT_REQUEST';
export const LOGOUT_SUCCESS          = 'USERS_LOGOUT_SUCCESS';
export const LOGOUT_FAILURE          = 'USERS_LOGOUT_FAILURE';
export const USER_FORM_REQUEST       = 'USER_FORM_REQUEST';
export const USER_FORM_SUCCESS       = 'USER_FORM_SUCCESS';
export const USER_FORM_FAILURE       = 'USER_FORM_FAILURE';

