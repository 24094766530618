import axios from 'axios';
import config from '../../config';
import store from '../../store';
import {NOTIFICATION_ERROR} from "../../components/globalConstants";
import {appLogout, LOGIN_DATA} from '../../library/utils/AuthService'


var defaultFetchConfig =
{
    baseURL: `${config.host}/${config.apiVersion}/`,
    headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'token': localStorage.getItem(LOGIN_DATA),
    },
    validateStatus: function (status) {
        if (status === 401)
        {
            appLogout();
            console.log('Fetch Response Status is ' + status+ ' -> log out.',);
        }
        else if (status === 403)
        {
            store.dispatch({
                type: NOTIFICATION_ERROR,
                message: 'امکان دسترسی به این بخش وجود ندارد'
            })
        }
        return status
    },
};
export var API_FETCH = axios.create(defaultFetchConfig);

export const setFetchServiceToken = (token)=>{

    axios.defaults.headers.common['token'] = token;

    axios.defaults.validateStatus = function (status) {
        if (status === 401)
        {
            appLogout();
            console.log('Fetch Response Status is ' + status+ ' -> log out.',);
        }
        else if (status === 403)
        {
            store.dispatch({
                type: NOTIFICATION_ERROR,
                message: 'امکان دسترسی به این بخش وجود ندارد'
            })
        }
        return status;
    };
    defaultFetchConfig.headers['token'] = token;

    API_FETCH = axios.create(defaultFetchConfig);

}
