import React, {Component, useState} from 'react'
import { Statistic, Card, Row, Icon, Input,Col } from 'antd';
import moment from 'jalali-moment'
import { Link } from 'react-router-dom';
import { ALERT_ERROR, FORM_FAILURE, FORM_REQUEST } from "../../globalConstants";
import { API_FETCH } from "../../../library/utils/FetchService";
import { dashboardSetItem } from "../../../components/dashboard/dashboardActions";


const DashboardPage = (props) => {
    const [value,setValue] = useState(moment())
    const [note,setNote] = useState(props.getNoteValues ? props.getNoteValues.note : '')

    const onChange = (event) => {
        const val = event.target.value;
        setTimeout(() => {
            props.handleSubmit(val)
        },3000)
    }

    // const { list } = this.props;
    const { TextArea } = Input;

    return (
        <div>
            <Row gutter={[16,16]}>
                <Col md={8} sm={24}>
                    <Col md={24}>
                        <Card>
                            <Col md={8}>
                                <Icon type="check-circle" className="dashboardIcon purpleIcon" />
                            </Col>
                            <Col md={16}>
                                <Statistic
                                    title="Counter"
                                    value={28}
                                />
                            </Col>
                        </Card>
                    </Col>
                </Col>
                <Col md={8} sm={24}>
                    <Col md={24}>
                        <Card>
                            <Col md={8}>
                                <Icon type="clock-circle" className="dashboardIcon blueIcon" />
                            </Col>
                            <Col md={16}>
                                <Statistic
                                    title="Counter"
                                    value={58}
                                />
                            </Col>
                        </Card>
                    </Col>
                </Col>
                <Col md={8} sm={24}>
                    <Col md={24}>
                        <Card>
                            <Col md={8}>
                                <Icon type="plus-circle" className="dashboardIcon greenIcon" />
                            </Col>
                            <Col md={16}>
                                <Statistic
                                    title="Counter"
                                    value={128}
                                />
                            </Col>
                        </Card>
                    </Col>
                </Col>
            </Row>
            <Row gutter={[16,16]}>
                <Col sm={24}>
                    <Col md={16} sm={24}>
                        <Row gutter={[16,16]} className="shortcutBoxWrapper">
                            <Col md={8} sm={24}>
                                <div className="shortcutBox blueBox" >
                                    <Link to="/bazaar">
                                        <div><Icon type="copy" /></div>
                                        <div>bazaar</div>
                                    </Link>
                                </div>
                            </Col>

                            <Col md={8} sm={24} >
                                <div className="shortcutBox yellowBox">
                                    <Link to="">
                                        <div><Icon type="transaction" /></div>
                                        <div>market</div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={8} sm={24} >
                                <div className="shortcutBox redBox">
                                    <Link to="">
                                        <div><Icon type="shopping" /></div>
                                        <div>event</div>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[16,16]} className="shortcutBoxWrapper">
                            <Col md={8} sm={24}>
                                <div className="shortcutBox grayBox" >
                                    <Link to="/user">
                                        <div><Icon type="user" /></div>
                                        <div>users</div>
                                    </Link>
                                </div>
                            </Col>

                            <Col md={8} sm={24} >
                                <div className="shortcutBox greenBox">
                                    <Link to="/Feature">
                                        <div><Icon type="setting" /></div>
                                        <div>Feature</div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={8} sm={24} >
                                <div className="shortcutBox lightBlueBox">
                                    <Link to="/country">
                                        <div><Icon type="global" /></div>
                                        <div>country</div>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={8} sm={24}>
                        <Row type="flex" justify="start" align="middle">
                            <Col md={24} sm={24} type="flex" justify="start" align="middle" >
                                <div className="note-wrapper">
                                    <div className="note-title">Notes</div>
                                    <div className="note-text">
                                        <TextArea
                                            name="note"
                                            rows="20"
                                            placeholder="Notes"
                                            className="sticky-note"
                                            onChange={(note) => onChange(note)}
                                            defaultValue={props.getNoteValues ? props.getNoteValues.note : ''}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </div>
    );
}

/* ------------------------------------------------ */
/* -------- Functions of mapDispatchToProps ------- */
/* ------------------------------------------------ */

const edit = (value,mode) => {

    return (dispatch, getState) => {

        var formData = {
            'note' : value
        };

        dispatch({type: FORM_REQUEST});
        API_FETCH.put('user/note', formData).then(res => {
            dispatch(dashboardSetItem(res.data));
        }).catch(error => {
            dispatch({type: FORM_FAILURE});
            dispatch({
                type: ALERT_ERROR,
                message: 'خطای سرور'
            })
        });

    }
}

const getNote = () => {

    return (dispatch, getState) => {
        // API_FETCH.post('user/note').then(dashboardInfo => {
        //   dispatch(dashboardGetNote(dashboardInfo.data));
        // }).catch(error => {
        //   console.log(error);
        // });

    }
}

const getItem = () => {
    return (dispatch, getState) => {
        // API_FETCH.get('order/stats').then(dashboardInfo => {
        //   dispatch(dashboardGetItem(dashboardInfo.data));
        // }).catch(error => {
        //   console.log(error);
        // });
    }
}

export default DashboardPage;
