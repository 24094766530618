import React, {useState, useEffect} from 'react';
import {Divider, Row, Col, Icon, Button, Empty , Input, message, Modal,Tooltip} from "antd"; // This only needs to be imported once in your app
import SortableTree, {toggleExpandedForAll} from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
import 'react-sortable-tree/style.css';
import Loading from "../../../library/components/Loading";
import EditableText from "../../../library/components/EditableText";
import {CATEGORY_LIST} from "../../queries/category/getCategoryList";
import {EDIT_CATEGORY} from "../../queries/category/EditCategory";
import _ from 'lodash'
import {DELETE_CATEGORY} from "../../queries/category/DeleteCategory";
import {CHANGE_PRIORITY} from "../../queries/category/ChangePriority";
import {Feather,AntDesign} from 'react-web-vector-icons'
import {Link} from "react-router-dom";

function CategoryTree(props){

    const [size, setSize] = useState('');
    const [searchString, setSearchString] = useState('');
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [searchFoundCount, setSearchFoundCount] = useState(null);
    const [treeData, setTreeData] = useState([]);
    const [expandState, setExpandState] = useState(false);
    const [nodeClicked, setNodeClicked] = useState();
    const [loadingFlag, setLoadingFlag] = useState(true);
    const [categoryListLoading, setCategoryListLoading] = useState(true);
    const [categoryEmpty, setCategoryEmpty] = useState(false);

    useEffect(() => {
        CallCategoryList()

    }, []);  //  [] ===>  only runs once

    useEffect(() => {
        searchResult()

    }, [searchString]);  //  [] ===>  only runs once

    const searchResult= () => {
        if(searchString != ''){
            // console.log('searchString',searchString)
            CATEGORY_LIST('BAZAAR').then(list => {
                if(list.status === 200) {
                    if (list.data.data.length > 0) {

                        setCategoryListLoading(false)
                        let categoryListData = list.data.data
                        var editedlist = categoryListData.map((item, index) => {
                            const word = item.title.en.toLowerCase();
                            const index2 = word.indexOf(searchString.toLowerCase());
                            if (index2 !== -1) {
                                console.log(item.title.en,item.parent)
                                return ({
                                        id: item.id,
                                        title: [<EditableText
                                            key={'tr_' + index}
                                            label={(item.title.tr ? item.title.tr : "Türkçe başlık")}
                                            onSave={onSaveCategory.bind(this, item, "tr")}
                                            onClick={setNodeClicked(setLoadingFlag(false))}
                                        />, ' - ',
                                            <EditableText
                                                key={'en_' + index}
                                                label={(item.title.en ? item.title.en : "english title")}
                                                onSave={onSaveCategory.bind(this, item, "en")}
                                                onClick={setNodeClicked(setLoadingFlag(false))}
                                            />, ' - ',
                                            <EditableText
                                                key={'fa_' + index}
                                                label={(item.title.fa ? item.title.fa : "عنوان فارسی")}
                                                onSave={onSaveCategory.bind(this, item, "fa")}
                                                onClick={setNodeClicked(setLoadingFlag(false))}
                                                holderStyle={{fontFamily: 'IRANSans'}}
                                            />
                                        ],
                                        parent: 0,
                                        priority: item.priority,
                                        key: item.id,
                                        expanded: true
                                    }
                                )
                            }else
                                return []
                        })
                        setTreeData(makeNestedList(editedlist, 0));

                    } else
                        return list;
                }else if(list.data.errCode === -2) {
                    setCategoryListLoading(false)
                    setCategoryEmpty(true)
                }

            }).catch(error => {
                console.log(error);
            });
        }else
            CallCategoryList();
        // treeData.map((item) => {
        //     const word = item.title[2].props.label.toLowerCase();
        //     const index = word.indexOf(searchString.toLowerCase());
        //     if (index !== -1){
        //
        //         setExpandState(true)
        //         CallCategoryList()
        //         console.log(item.title[2].props.label)
        //     }
        // })
    }

    /* ------------------------------------------------ */
    /* ------------ Render HTML  : convert special character ------------- */
    /* ------------------------------------------------ */
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    const updateTreeData = treeData => {
        setTreeData(treeData);
    };

    var makeNestedList = (list, parentid) => {
        var newList = [];
        // console.log(list)

        if (list && list.length > 0)
            for (let i in list) {
                if (list[i] && list[i]['parent'] == parentid)
                    newList.push(list[i]);
            }
        if (newList.length > 0) {
            for (let j in newList) {
                let children = makeNestedList(list, newList[j]['id']);
                if (children)
                    newList[j]['children'] = children;
            }
            return newList;
        }
        else
            return false

    }

    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (id) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                deleteCategory({
                    catId:id
                });
            },
            onCancel() {},
        });
    }

    /* ------------------------------------------------ */
    /* ------------ QUERY ------------- */
    /* ------------------------------------------------ */

    const CallCategoryList = () => {
        CATEGORY_LIST('BAZAAR').then(list => {
            if(list.status === 200) {
                if (list.data.data.length > 0) {
                    setCategoryListLoading(false)
                    let categoryListData = list.data.data
                    var editedlist = categoryListData.map((item, index) => (
                        {
                            id: item.id,
                            title: [<EditableText
                                key={'tr_'+index}
                                label={(item.title.tr ? item.title.tr : "Türkçe başlık")}
                                onSave={onSaveCategory.bind(this, item, "tr")}
                                onClick={setNodeClicked(setLoadingFlag(false))}
                            />,' - ',
                                <EditableText
                                key={'en_'+index}
                                label={(item.title.en ? item.title.en : "english title")}
                                onSave={onSaveCategory.bind(this, item, "en")}
                                onClick={setNodeClicked(setLoadingFlag(false))}
                            />,' - ',
                                <EditableText
                                    key={'fa_'+index}
                                    label={(item.title.fa ? item.title.fa : "عنوان فارسی")}
                                    onSave={onSaveCategory.bind(this, item, "fa")}
                                    onClick={setNodeClicked(setLoadingFlag(false))}
                                    holderStyle={{fontFamily:'IRANSans'}}
                                />
                                ],
                            parent: item.parent ? item.parent : 0,
                            priority: item.priority,
                            key: index,
                            expanded: nodeClicked === true ?
                                _.includes(nodeClicked, item.id.toString()) === true ? true : false
                                : expandState
                        }
                    ))
                    setTreeData(makeNestedList(editedlist, 0));

                } else
                    return list;
            }else if(list.data.errCode === -2) {
                setCategoryListLoading(false)
                setCategoryEmpty(true)
            }

        }).catch(error => {
            console.log(error);
        });

    }

    const deleteCategory = (data) => {
        DELETE_CATEGORY({data}).then(res => {
            if(res.data.errCode === -5) {
                message.error("you could not delete main category.")
            }
            if(res.data.errCode === -6) {
                message.error("please first delete children of category and then delete category")
            }
            if (res.status === 200) {
                // CallCategoryList();
                let category = res.data.data
                res ? message.success("the ` " + (category.title.en || category.title.tr) + " ` was deleted.") : message.error("Error in delete feature")
            }
        }).catch(error => {
            console.log(error);
        });
    }

    var updateCategoryTitle = (data) =>  {
        EDIT_CATEGORY(data).then(res => {
            if(res.status === 200) {
                // CallCategoryList();
                let parentArray = null
                if(res.parent != null)
                    parentArray = getParents(res.parent,res.id)
                setNodeClicked(parentArray)//set for show expanded : true in tree data
                res ? message.success("the ` " + (res?.data?.data?.title?.en || res?.data?.data?.title?.tr)+ " ` was updated.") : message.error("Error in update feature")
            }else
                return 'error'
        }).catch(error => {
            console.log(error);
        });

    }

    var updateCategoryPosition = (data) =>  {
        EDIT_CATEGORY(data)
    }

    const onSaveCategory = (category,lang,text, disableEditMode) => {
        category.title[lang] = text;

        updateCategoryTitle({
            catId   : category.id,
            enTitle : category.title['en'],
            faTitle : category.title['fa'],
            trTitle : category.title['tr'],
            catType:'BAZAAR',
            parent:category.parent,
        })
        disableEditMode();
    }

    var onDeleteCategory = (category, disableEditMode) => {
        deleteCategory({
            catId: category.id,
        });
        disableEditMode();
    }

    const expand = async expanded => {
        setTreeData(
            toggleExpandedForAll({
                treeData: setTreeData,
                expanded,
            }),
        );
        setExpandState(expanded)
        CallCategoryList()
    };

    const expandAll = () => {
        expand(true);
    };

    const collapseAll = () => {
        expand(false);
    };

    const ButtonGroup = Button.Group;

    /* ------------------------------------------------ */
    /* ---------------  Handle Tree Cat --------------- */
    /* ------------------------------------------------ */
    let newList2 = []
    let categoryListData = []
    var getParents = (parent, id) => {

        if (parent != 0 && parent != null) {
            newList2.unshift(parent)
            var newElement = _.find(categoryListData, {id: parent})
            if (newElement && newElement.parent != null && newElement.parent != 0)
                getParents(newElement.parent, newElement.id)
        } else
            newList2.unshift(id)


        return _.uniq(newList2);
    }

    /* ------------------------------------------------ */
    /* -----------------  Handle sort ----------------- */
    /* ------------------------------------------------ */
    const moveNodeInfo = ({treeData, node, nextParentNode}) => {
        let newList = [];
        if(nextParentNode !== null)
            nextParentNode.children.map((item,index) => {
                newList.push(item.id)
            })
        else
            treeData.map((item,index) => {
                newList.push(item.id)
            })

        CHANGE_PRIORITY({'cats':newList}).then(res => {

        }).catch(error => {
            console.log(error);
        });
        let newParent = 0
        if(nextParentNode && nextParentNode.id)
            newParent = nextParentNode.id
        let id = node.id


        /*------ update parent -----*/
        if(node.parent != newParent)
            updateCategoryPosition({
                catId: id,
                parent: newParent,
                trTitle:node.title[0].props.label,
                enTitle:node.title[2].props.label,
                faTitle:node.title[4].props.label,
                catType: "BAZAAR",
            })


        // /*------ update priority -----*/
        // if(nextParentNode && nextParentNode.children.length > 0){//if category move to subcategory
        //     nextParentNode.children.map((item,i) => {
        //         updateCategoryPosition({
        //             catId: item.id,
        //             priority: i,
        //         })
        //     })
        // }else{//if category move to root
        //     _.find(treeData, function(item,i) {
        //         updateCategoryPosition({
        //             catId: item.id,
        //             priority: i,
        //         })
        //
        //     });
        // }

    };

    const selectPrevMatch = () =>
        setSearchFocusIndex(
            searchFocusIndex !== null
                ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
                : searchFoundCount - 1,
        );

    const selectNextMatch = () =>
        setSearchFocusIndex(
            searchFocusIndex !== null
                ? (searchFocusIndex + 1) % searchFoundCount
                : 0,
        );

    const customSearchMethod = ({ node, searchQuery, e }) =>
        searchQuery &&
        ((node.title.en &&
            node.title.en.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1) ||
            (node.subtitle.en &&
                node.subtitle.en.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1))

    const searchCallback = (matches) => {
        setImmediate(() => {
            setSearchFoundCount(matches.length)
            setSearchFocusIndex((searchFocusIndex) =>
                matches.length > 0 ? searchFocusIndex % matches.length : 0
            )
        })
    }

    /* ------------------------------------------------ */
    /* -----------------  R E N D E R ----------------- */
    /* ------------------------------------------------ */

    const onMoveNode = (e) => {
        console.log('moveNode',e)
    }
    const thisNode = (node) => {
        console.log('node',node)
    }

    const onClickNode = (e) => {
    }


    return (
        <div>

            <Row type="flex">
                <Col xs={24} md={20}>
                    <Divider orientation="left">category list</Divider>
                </Col>
                <Col xs={24} md={4} >
                    <Button type="primary" className="userListBtn" shape="round" icon="plus" href="#/bazaar/category/add">
                        create category
                    </Button>
                </Col>
            </Row>

            <Row>

                <div
                    style={{display: 'flex', flexDirection: 'column', height: '100vh'}}
                >
                    <div style={{flex: '0 0 auto', padding: '0 15px'}}>
                        <Col md={12} className="left">
                            <form
                                style={{marginLeft:'30px'}}
                                name="categoryList"
                                onSubmit={event => {
                                    event.preventDefault();
                                }}
                            >
                                <div className="left">
                                    <Input
                                        prefix={<Icon type='text' />}
                                        placeholder="search"
                                        value={searchString}
                                        onChange={event =>
                                            setSearchString(event.target.value)
                                        }
                                    />
                                </div>
                                {/*<div className="left searchBtnBox">*/}

                                {/*    <ButtonGroup size="small">*/}
                                {/*        <Button*/}
                                {/*            type="button"*/}
                                {/*            disabled={!searchFoundCount}*/}
                                {/*            onClick={selectPrevMatch}*/}
                                {/*        >*/}
                                {/*            <Icon type="left" />*/}
                                {/*        </Button>*/}
                                {/*        <Button*/}
                                {/*            type="submit"*/}
                                {/*            disabled={!searchFoundCount}*/}
                                {/*            onClick={selectNextMatch}*/}
                                {/*        >*/}
                                {/*            <Icon type="right" />*/}
                                {/*        </Button>*/}
                                {/*    </ButtonGroup>*/}

                                {/*    <span>*/}
                                {/*        &nbsp;*/}
                                {/*        {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}*/}
                                {/*        &nbsp;/&nbsp;*/}
                                {/*        {searchFoundCount || 0}*/}
                                {/*    </span>*/}
                                {/*</div>*/}
                            </form>
                        </Col>
                        <Col md={12} className="right">
                            <ButtonGroup className="right colExpBtnBox">
                                <Button type="" onClick={expandAll}>
                                    Expand All
                                </Button>
                                <Button type="" onClick={collapseAll}>
                                    Collapse All
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </div>
                    {categoryListLoading && _.isEmpty(nodeClicked) && loadingFlag === true && categoryEmpty === false ?
                        <Loading /> :
                        categoryEmpty === true ?
                            <Empty />
                            :
                            <div style={{flex: '1 0 50%'}}>
                                <SortableTree
                                    theme={FileExplorerTheme}
                                    treeData={treeData}
                                    onChange={updateTreeData}
                                    searchQuery={searchString}
                                    searchFocusOffset={searchFocusIndex}
                                    onlyExpandSearchedNodes={true}
                                    style={{width: '100%', margin: 5}}
                                    rowHeight={45}
                                    searchFinishCallback={matches => searchCallback(matches)}
                                    canDrag={({node}) => !node.dragDisabled}
                                    onMoveNode={moveNodeInfo.bind(this)}
                                    generateNodeProps={rowInfo => ({
                                        buttons: [
                                            <Link to={'/bazaar/category/add/'+rowInfo.node.id} className='categoryIcon'>
                                                <Tooltip title="add to this category">
                                                    <Feather name="plus" size={15} color={'lightseagreen'} />
                                                </Tooltip>
                                            </Link>,
                                            <Link to={'/bazaar/category/edit/'+rowInfo.node.id} className='categoryIcon'>
                                                <Tooltip title="edit this category">
                                                    <AntDesign name="edit" size={15} color={'#FFC107'} />
                                                </Tooltip>
                                            </Link>,
                                            <a onClick={showConfirm.bind(this,rowInfo.node.id)} className='categoryIcon'>
                                                <Tooltip title="delete this category">
                                                    <AntDesign name="delete" size={15} color={'red'} />
                                                </Tooltip>
                                            </a>,

                                        ],
                                    })}

                                />
                            </div>
                    }
                </div>
            </Row>
        </div>
    );
}

export default CategoryTree;
