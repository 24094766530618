import styled from 'styled-components';
import {
    Button,
    Menu,
    List,
    Steps,
    Select,
    TreeSelect,
    Col,
    Table,
    Upload,
    Modal,
    AutoComplete,
    TimePicker,
    DatePicker
} from 'antd';

const Step = Steps.Step;

export const RTLButton = styled(Button)`
    font-size:13px !important;
    & span {
        margin-left: unset !important;
        margin-right:4px;
    }
    & i {
        margin-top: 2px;
    }
    & i.anticon-loading {
        margin-right: unset !important;
        margin-left: -14px !important;
    }
    & .anticon-loading{
        float:left;
    }
`;



export const RTLMenuItem = styled(Menu.Item)`
    text-align: right;
    font-size: unset !important;
    font-size:12px !important;
    &::after {
        left: 0 !important;
        right: unset !important
    }
    &[style] {
        padding-left: unset !important;
        xcolor: #9facbd !important;
    }
    & .anticon {
        margin-left: 10px !important;
        margin-right: 0 !important;
    }
`;

export const RTLMenu = styled(Menu)`
  border-right: unset !important;
`;

export const RTLListItem = styled(List.Item)`
    padding: unset !important;
    padding:1px 0 !important;
`;

export const RTLListItemMeta = styled(List.Item.Meta)`
    padding: unset !important;
    padding: 5px !important;
    & .ant-list-item-meta-avatar {
        margin-right: unset !important;
        margin-right: 0 !important;
    }
    & .ant-list-item-meta-content {
        margin-right: 5px;
        text-align: right;
    }
    & .ant-list-item-meta-content h4 {
        font-size: 11px !important;
        color: #919396;
    }
`;

export const RTLStep = styled(Step)`
  text-align: right;
  justify-content: flex-start;
  & .ant-steps-item-content {
      text-align: right;
  }
  & .ant-steps-item-content .ant-steps-item-title {
    padding-right: 5px !important;
    padding-left: 5px;
    font-size:12px !important;
    font-weight: bold;
  }
  & .ant-steps-item-content .ant-steps-item-title::after {
      left: unset !imporant;
      right: 100%;
  }
  & .ant-steps-item-content .ant-steps-item-description {
      font-size:10px !important;
      padding-right: 5px;
  }
`;

export const RTLSelect = styled(Select)`
    .ant-select-selection__placeholder, .ant-select-search__field__placeholder,.ant-select-selection-selected-value{
        text-align:right;
        right: 0;
        font-size: 12px;
    }
    .ant-select-selection-selected-value{
        float:right;
        padding-right:0px;
    }
    .ant-select-arrow{
       left: 11px;
       right:auto; 
    }
    form .has-feedback > .ant-select .ant-select-arrow, form .has-feedback > .ant-select .ant-select-selection__clear, form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow, form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear{
        right: auto !important;
        left: 28px !important;
    }
    .ant-select-selection--multiple .ant-select-selection__choice{
        float:right
    }
    .ant-select-selection--single .ant-select-selection__rendered{
        margin-left:24px;
        margin-right:5px !important;
    }
    
`;

export const RTLTreeSelect = styled(TreeSelect)`
    .ant-select-selection__placeholder, .ant-select-search__field__placeholder{
        text-align:right;
        right:25px;
    }
    .ant-select-tree li ul {
        padding: 0 18px 0 0 !important;
    }
    .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
        transform: rotate(90deg) !important;
    }
`;

export const RTLCol = styled(Col)`
    .ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24{
        float:right !important;
    }
    @media (min-width: 768px) {
        .ant-col-md-1, .ant-col-md-2, .ant-col-md-3, .ant-col-md-4, .ant-col-md-5, .ant-col-md-6, .ant-col-md-7, .ant-col-md-8, .ant-col-md-9, .ant-col-md-10, .ant-col-md-11, .ant-col-md-12, .ant-col-md-13, .ant-col-md-14, .ant-col-md-15, .ant-col-md-16, .ant-col-md-17, .ant-col-md-18, .ant-col-md-19, .ant-col-md-20, .ant-col-md-21, .ant-col-md-22, .ant-col-md-23, .ant-col-md-24 {
            float: right !important;
        }
    }
   
`;
export const RTLTable = styled(Table)`
    .ant-table table,.ant-table-thead > tr > th{
        text-align: right;
    }
    .ant-table{
        font-size:12px;
    }
    .ant-table table{
        background: #fff;
    }
    .ant-table-thead > tr:first-child > th:first-child {
         border-top-right-radius: 4px !important;
         border-top-left-radius: 0px !important;
     }
    .ant-table-thead > tr:first-child > th:last-child {
        border-top-left-radius: 4px !important;
        border-top-right-radius: 0px !important;
    } 
`;
export const RTLUpload = styled(Upload)`
    .ant-upload.ant-upload-select-picture-card{
        float:right;
        margin-right:0px;
    }   
`;

export const RTLModal = styled(Modal)`
    .ant-modal-close{
        left: 0;
        right: auto;  
    }
    .ant-btn{
        margin-left:10px
    }
    .ant-modal-body{
        min-height: 90px;
    }
`;

export const RTLAutoComplete = styled(AutoComplete)`
    .formLabel{
        padding-top: 2% !important;    
    }
`;
export const RTLTimePicker = styled(TimePicker)`
    .formLabel{
        padding-top: 2% !important;    
    }
`;
export const RTLDatePicker = styled(DatePicker)`
    .formLabel{
        padding-top: 2% !important;    
    }
`;


