import {API_FETCH} from "../../../library/utils/FetchService";

export const ADMIN_INFO = (param) =>{
    return API_FETCH.post('super/getSuperUserInfo',param)
}

export const ADD_ADMIN = (param) =>{
    return API_FETCH.post('super/addSuperUser',param)
}

export const EDIT_ADMIN = (param) =>{
    return API_FETCH.put('super/updateSuperUser',param)
}

export const DELETE_ADMIN = (param) =>{
    return API_FETCH.delete('super/deleteSuperUser',{data:param})
}
