//--- React Hook
import React, {useState, useEffect } from 'react';

//--- ant design
import {
    Row, Form, Divider, Col, Button, Icon, Input, message, Modal, Select,
} from 'antd';

/* ------ custom -------- */
import Loading from "../../library/components/Loading";
import _ from 'lodash'

import {USER_INFO} from "../queries/user/getUserInfo";
import {EDIT_USER} from "../queries/user/editUser";
import SelectField from "../../library/components/SelectField";
import {COUNTRY_LIST} from "../queries/country/country";
import {STATE_LIST} from "../queries/state/state";
import {CITY_LIST} from "../queries/city/city";


const UserAddEdit = (props) => {

    if (props.match.params.id) {
        var explodeUrl = (props.match.url).split('/')
    }

    /* ------------------------------------------------ */
    /* ---------------- Define State ------------------ */
    /* ------------------------------------------------ */
    const [form, setFormField] = useState({
        fullname: '',
        username: '',
        phone: '',
        password: '',
        rule: '',
    });

    const [mode, setMode] = useState(explodeUrl ? explodeUrl[2] : '');
    const [id, setId] = useState(props.match.params.id ? props.match.params.id : '');
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryValue, setCountryValue] = useState('');
    const [stateValue, setStateValue] = useState('');
    const [cityValue, setCityValue] = useState('');
    const {Option} = Select;


    useEffect(() => {

        /*---- edit mode ---*/
        if (props.match.params.id) {
            let id = props.match.params.id
            let explodeUrl = (props.match.url).split('/')
            setMode(explodeUrl[2]);
            userInfoRefetch(id);
        }
        getCountryList()

    },[] );

    /* ------------------------------------------------ */
    /* -------------------- Query --------------------- */
    /* ------------------------------------------------ */
    const userInfoRefetch = (id) => {
        setLoading(true)
        USER_INFO({userId:id}).then(info => {
            if(info.status === 200){
                let data = info.data.data
                setLoading(false)
                setFormField({
                    name: mode === 'edit' ? data.name : '',
                    family: mode === 'edit' ? data.family : '',
                    mob: mode === 'edit' ? data.mob : '',
                    country: mode === 'edit' ? data.country : '',
                    state: mode === 'edit' ? data.province : '',
                    city: mode === 'edit' ? data.city : '',
                });
                getStateList(data.country)
                getCityList(data.province)
            }
        }).catch(error => {
            console.log(error);
        });

    }

    var updateUser = (data) =>  {
        setButtonLoading(true);
        data['userId'] = id
        EDIT_USER(data).then(res => {
            if(res.status === 200) {
                res ? message.success("the ` " + res.data.data.name+" "+ res.data.data.family+ " ` was updated.") : message.error("Error")
                setButtonLoading(false);
            }else{
                message.error("Error")
                setButtonLoading(false);
            }

        }).catch(error => {
            console.log(error);
        });

    }

    const deleteUser = (id) => {
        setDeleteLoading(true)
        // DELETE_USER(id).then(res => {
        //     if (res.status === 200) {
        //         let user = res.data.data
        //         res ? message.success("the ` " + user.name +" "+ user.family + " ` was deleted.") : message.error("Error in delete user")
        //         props.history.push("/user")
        //         setDeleteLoading(false)
        //     }
        // }).catch(error => {
        //     console.log(error);
        // });
    }

    const getCountryList = () =>{
        let country = []
        COUNTRY_LIST.then(res => {
            if (res.status === 200) {
                res.data.data.map((item,index)=>{
                    country.push(
                        {label: item.title.en, value: item.id}
                    )
                })

                setCountryList(country)
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const getStateList = (id) =>{
        let state = []
        if(!_.isEmpty(id))
        {
            STATE_LIST({country:id}).then(res => {
                if (res.status === 200) {
                    res.data.data.map((item,index)=>{
                        state.push(
                            {label: item.title.en, value: item.id}
                        )
                    })
                    setStateList(state)
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    const getCityList = (id) =>{
        let city = []
        if(!_.isEmpty(id))
        {
            CITY_LIST({state:id}).then(res => {
                if (res.status === 200) {
                    res.data.data.map((item,index)=>{
                        city.push(
                            {label: item.title.en, value: item.id}
                        )
                    })

                    setCityList(city)
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (e) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                e.preventDefault();
                deleteUser(id);
            },
            onCancel() {},
        });
    }


    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */
    var handleTextInput = (name, e) => {
        const {value} = e.target;
        let newForm = {...form};
        newForm[name] = value;
        setFormField(newForm)
    };

    /* ------------------------------------------------ */
    /* ----------------  Select Box Area -------------- */
    /* ------------------------------------------------ */

    var onchangeCountry = (value)=>{
        let newForm = {...form};
        newForm['country'] = value;
        newForm['state'] = '';
        newForm['city'] = '';
        setFormField(newForm)
        getStateList(value)
    }

    var onchangeState = (value)=>{
        let newForm = {...form};
        newForm['state'] = value;
        newForm['city'] = '';
        setFormField(newForm)
        getCityList(value)
    }

    var onchangeCity = (value)=>{
        let newForm = {...form};
        newForm['city'] = value;
        setFormField(newForm)
        setCityValue(value)
    }


    return (

        <Form onSubmit={e => {
            e.preventDefault();
            (mode === 'edit' ? updateUser : null)({
                name : form.name,
                family : form.family,
                country : form.country,
                province : form.state,
                city : form.city

            });
        }} className="login-form">
            <Row type="flex">
                <Col xs={24} md={24}>
                    <Divider orientation="left">{mode === 'edit' ? "edit" : "add"} user</Divider>
                </Col>
            </Row>
            {mode === 'edit' && loading ?
                <Loading/>
                :
                <Row type="flex" justify="start" align="middle">
                    <Col md={18} sm={24} type="flex" justify="start" align="middle">
                        <Row gutter={16}>

                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"name"}
                                value={form.name}
                                onChange={handleTextInput.bind(this, 'name')}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"family"}
                                value={form.family}
                                onChange={handleTextInput.bind(this, 'family')}
                            />

                            <Col md={8} style={{paddingLeft:0}}>
                                <SelectField
                                    list={countryList}
                                    onChange={onchangeCountry.bind(this)}
                                    value={form.country}
                                    style={{paddingLeft:0}}
                                    placeholder={'country'}
                                />
                            </Col>

                            <Col md={8}>
                                <SelectField
                                    list={stateList}
                                    onChange={onchangeState.bind(this)}
                                    value={form.state}
                                    placeholder={'state'}
                                />
                            </Col>

                            <Col md={8} style={{paddingRight:0}}>
                                <SelectField
                                    list={cityList}
                                    onChange={onchangeCity.bind(this)}
                                    value={form.city}
                                    style={{paddingRight:0}}
                                    placeholder={'city'}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Button loading={buttonLoading} type="primary" shape="round"
                                    htmlType="submit"
                                    className="login-form-button">
                                {mode === 'edit' ? "edit" : "add"}
                            </Button>
                            <Button href="#/user" className="backBtn" type="default" shape="round">
                                back
                            </Button>
                        </Row>
                    </Col>
                </Row>
            }


        </Form>

    );

}

export default UserAddEdit;



