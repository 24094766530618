import React from 'react';
import {Spin,Icon} from 'antd';


const Loading = () => {
    return (
        <Spin tip="Loading..."></Spin>
    );
};
export const MiniLoading = () => {
    const antIcon = <Icon type="loading" style={{ fontSize: 24,top:10 }} spin />;
    return (
        <Spin indicator={antIcon}></Spin>
    );
};

export default Loading;



