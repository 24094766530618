import React, {useEffect, useState} from 'react';
import './App.css';
import './assets/styles/overwrite.css';
import {setFetchServiceToken} from './library/utils/FetchService';
import {Route, Switch, Redirect} from 'react-router';
import AppLayout from './components/layouts';
import {LOGIN_DATA} from './library/utils/AuthService'

//- Router
import DashboardPage from './components/dashboard/dashboard';

//- Login/Register Router
import LoginPage from './components/login/login';

//- Bazaar Router
import BazaarCategoryList from './components/bazaar/category/list';
import BazaarCategoryAddEdit from './components/bazaar/category/addEdit';
import BazaarList from './components/bazaar/list';
import BazaarInfo from './components/bazaar/info';

//- Event Router
import EventCategoryList from './components/event/category/list';
import EventCategoryAddEdit from './components/event/category/addEdit';
import EventList from './components/event/list';
import EventInfo from './components/event/info';

//- Marketplace Router
import MarketplaceCategoryList from './components/marketplace/category/list';
import MarketplaceCategoryAddEdit from './components/marketplace/category/addEdit';
import MarketList from './components/marketplace/list';
import MarketInfo from './components/marketplace/info';

//- Feature Router
import FeatureList from './components/feature/list';

//- Users Router
import UserList from './components/user/list';
import EditUser from './components/user/addEdit';

//- Country Router
import CountryList from './components/country/country/list';
import AddCountry from './components/country/country/addEdit';
import EditCountry from './components/country/country/addEdit';

//- State Router
import StateList from './components/country/state/list';
import AddState from './components/country/state/addEdit';
import EditState from './components/country/state/addEdit';

//- City Router
import CityList from './components/country/city/list';
import AddCity from './components/country/city/addEdit';
import EditCity from './components/country/city/addEdit';

//- admin Router
import AdminList from './components/admin/list';
import AddAdmin from './components/admin/addEdit';
import EditAdmin from './components/admin/addEdit';

//- Report Router
import ReportList from './components/report/list';
import ReportOptionsList from './components/report/options/list';
import ReportOptionsAddEdit from './components/report/options/addEdit'

//- Tips Router
import TipsList from './components/tips/list';
import TipsInfo from './components/tips/info';
import TipsReport from './components/tips/reports';


/* ------- ------------- --------*/
/* ------- App Component --------*/
/* ------- ------------- --------*/
const App = (props) => {

    const [isLogin,setIsLogin] = useState(false)
    const [isLogout,setIsLogout] = useState(false)

    useEffect(()=>{
        let token = localStorage.getItem(LOGIN_DATA)
        if(token != null || token != undefined) {
            setIsLogin(true)
            setIsLogout(false)
            setFetchServiceToken(token)
        }else {
            setIsLogout(true)
            setIsLogin(false)
        }


        // if (nextProps.redirectTo) {
        //     store.dispatch(push(nextProps.redirectTo));
        //     this.props.onRedirected();
        // }
        // if (nextProps.alert && nextProps.alert.messageType && nextProps.alert.message) {
        //     makeAlert(nextProps.alert.messageType, nextProps.alert.message);
        //     nextProps.onClearAlerts();
        // }
        //
        // if (nextProps.notification && nextProps.notification.messageType && nextProps.notification.message) {
        //     makeNotify(nextProps.notification.messageType, nextProps.notification.message);
        //     nextProps.onClearNotifications();
        // }

    })


    if (isLogin == true) //-- if login -> Dashboard Router
    // if (true)
        return (
            <AppLayout>
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={DashboardPage}/>
                        <Route exact path="/bazaar/category" component={BazaarCategoryList}/>
                        <Route exact path="/bazaar/category/add" component={BazaarCategoryAddEdit}/>
                        <Route exact path="/bazaar/category/add/:id" component={BazaarCategoryAddEdit}/>
                        <Route exact path="/bazaar/category/edit/:id" component={BazaarCategoryAddEdit}/>
                        <Route exact path="/bazaar" component={BazaarList}/>
                        <Route exact path="/bazaar/:id" component={BazaarInfo}/>
                        <Route exact path="/event/category" component={EventCategoryList}/>
                        <Route exact path="/event/category/add" component={EventCategoryAddEdit}/>
                        <Route exact path="/event/category/add/:id" component={EventCategoryAddEdit}/>
                        <Route exact path="/event/category/edit/:id" component={EventCategoryAddEdit}/>
                        <Route exact path="/event" component={EventList}/>
                        <Route exact path="/event/:id" component={EventInfo}/>
                        <Route exact path="/marketplace/category" component={MarketplaceCategoryList}/>
                        <Route exact path="/marketplace/category/add" component={MarketplaceCategoryAddEdit}/>
                        <Route exact path="/marketplace/category/add/:id" component={MarketplaceCategoryAddEdit}/>
                        <Route exact path="/marketplace/category/edit/:id" component={MarketplaceCategoryAddEdit}/>
                        <Route exact path="/marketplace" component={MarketList}/>
                        <Route exact path="/marketplace/:id" component={MarketInfo}/>
                        <Route exact path="/user" component={UserList}/>
                        <Route exact path="/user/edit/:id" component={EditUser}/>
                        <Route exact path="/feature" component={FeatureList}/>
                        <Route exact path="/country" component={CountryList}/>
                        <Route exact path="/country/add" component={AddCountry}/>
                        <Route exact path="/country/edit/:id" component={EditCountry}/>
                        <Route exact path="/state/add/:id" component={AddState}/>
                        <Route exact path="/state/edit/:id" component={EditState}/>
                        <Route exact path="/state/:id" component={StateList}/>
                        <Route exact path="/city/add/:id" component={AddCity}/>
                        <Route exact path="/city/edit/:id" component={EditCity}/>
                        <Route exact path="/city/:id" component={CityList}/>
                        <Route exact path="/admin" component={AdminList}/>
                        <Route exact path="/admin/add" component={AddAdmin}/>
                        <Route exact path="/admin/edit/:id" component={EditAdmin}/>
                        <Route exact path="/report" component={ReportList}/>
                        <Route exact path="/report/options" component={ReportOptionsList}/>
                        <Route exact path="/report/options/add" component={ReportOptionsAddEdit}/>
                        <Route exact path="/report/options/edit/:id" component={ReportOptionsAddEdit}/>
                        <Route exact path="/tips" component={TipsList}/>
                        <Route exact path="/tips/:id" component={TipsInfo}/>
                        <Route exact path="/tips/report/list" component={TipsReport}/>
                    </Switch>
                </div>
            </AppLayout>
        );
    else if(isLogout === true) //-- if Not Login  -> Login/Register Router
        return (
            <AppLayout>
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={LoginPage}/>
                        <Route path="/login" component={LoginPage}/>
                        <Redirect from="*" to="/"/>
                    </Switch>
                </div>
            </AppLayout>
        );
    else
        return (
            <AppLayout></AppLayout>
        );
}


export default App;
