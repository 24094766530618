import React, {useEffect,useState} from 'react';
import {Col, Divider, Tag, Rate , Row, Slider, Table, Modal} from 'antd'
import moment from "jalali-moment";
import {
    FontAwesome,
    Foundation,
    MaterialIcons,
    Entypo
} from 'react-web-vector-icons'
import _ from 'lodash'
import MapContainer from '../components/mapContainer';
import {ImgGallery} from "./imageGallery";
import {USER_INFO} from '../../components/queries/user/getUserInfo'
import {FEATURE_INFO} from "../../components/queries/feature/getFeatureInfo";
import UserInfo from "./UserInfo";

const CardLists = (props) => {

    const [userInfo,setUserInfo]=useState()
    const [visible,setVisible] = useState(false)
    const [userId, setUserId] = useState('');

    useEffect(() => {
        // USER_INFO({userId:props.item.})
    },[])

    let fullDate = moment(props.item.createdAt).format('lll');
    let fullUpdate = moment(props.item.updatedAt).format('lll');
    let status;
    switch (props.item.status) {
        case 0 :
            status = {
                color : 'orange',
                title : 'pending'
            }
            break;
        case 1 :
            status = {
                color : 'green',
                title : 'approved'
            }
            break;
        case -1 :
            status = {
                color : 'red',
                title : 'rejected'
            }
            break;
        default :
            status = {
                color : 'orange',
                title : 'pending'
            }
    }

    let treePrimaryCat = []
    if(props.item.primryCatTree)
        props.item.primryCatTree.map((item,index)=>{
            treePrimaryCat.push(item.title.en)
            if(index < props.item.primryCatTree.length - 1)
                treePrimaryCat.push(' -> ')
        })

    let treeSecondaryCat = []
    if(props.item.secondaryCatTree)
        props.item.primryCatTree.map((item,index)=>{
            treeSecondaryCat.push(item.title.en)
            if(index < props.item.secondaryCatTree.length - 1)
                treeSecondaryCat.push(' -> ')
        })

    let workTimeColumn = [
        {
            title: 'Saturday',
            dataIndex: 'Saturday',
            key: 'Saturday',
        },
        {
            title: 'Sunday',
            dataIndex: 'Sunday',
            key: 'Sunday',
        },
        {
            title: 'Monday',
            dataIndex: 'Monday',
            key: 'Monday',
        },
        {
            title: 'Tuesday',
            dataIndex: 'Tuesday',
            key: 'Tuesday',
        },
        {
            title: 'Wednesday',
            dataIndex: 'Wednesday',
            key: 'Wednesday',
        },
        {
            title: 'Thursday',
            dataIndex: 'Thursday',
            key: 'Thursday',
        },
        {
            title: 'Friday',
            dataIndex: 'Friday',
            key: 'Friday',
        }
    ]
    let workTimeData = []
    if(props.item.workTime){
        let workTime = props.item.workTime
        workTimeData = [
            {
                Saturday: workTime && workTime.Saturday && workTime.Saturday.start ? workTime.Saturday.start+' - '+workTime.Saturday.end : '',
                Sunday: workTime && workTime.Sunday && workTime.Sunday.start ? workTime.Sunday.start+' - '+workTime.Sunday.end : '',
                Monday: workTime && workTime.Monday && workTime.Monday.start ? workTime.Monday.start+' - '+workTime.Monday.end : '',
                Tuesday: workTime && workTime.Tuesday && workTime.Tuesday.start ? workTime.Tuesday.start+' - '+workTime.Tuesday.end : '',
                Wednesday: workTime && workTime.Wednesday && workTime.Wednesday.start ? workTime.Wednesday.start+' - '+workTime.Wednesday.end : '',
                Thursday: workTime && workTime.Thursday && workTime.Thursday.start ? workTime.Thursday.start+' - '+workTime.Thursday.end : '',
                Friday: workTime && workTime.Friday && workTime.Friday.start ? workTime.Friday.start+' - '+workTime.Friday.end : '',
                key: '1',
            }
        ]
    }

    if(props.item.ownerId)
        USER_INFO({userId:props.item.ownerId}).then(res => {
            if(res.data.status === true) {
                if (res.data.data)
                    setUserInfo(res.data.data.name+' '+res.data.data.family)
            }
        })

    let feature_list = []
    if(props.item.features){
        Object.entries(props.item.features).map(([key, value]) => {
            if(value === true)
                FEATURE_INFO(key).then(res => {
                    if(res.status === 200) {
                        if (res.data.data.title.en)
                            feature_list.push(res.data.data.title.en)
                    }
                })
        })
    }

    let custom_field = []
    if(props.item.customFeilds && props.item.customFeilds.length > 0) {
        props.item.customFeilds.map((custom, index) => {
            let customFieldTitle = custom.title.en
            let customFieldValue = custom.value
            if (custom.type === 'dropdown') {
                let title = _.result(_.find(custom.options, {'value': custom.value}), 'title');
                customFieldValue = title.en
            }
            if (custom.type === 'checkbox') {
                custom.value && custom.value.map((check,index)=>{
                    let title = _.result(_.find(custom.options, {'value': check}), 'title');
                    customFieldValue = title.en+' - '
                })
            }
            if (custom.type === 'switch') {
                customFieldValue = custom.value.toString();
            }
            custom_field.push({'title':customFieldTitle,'value':customFieldValue})
        })
    }

    const showModal = (id) => {
        setVisible(true);
        setUserId(id)
    }

    const handleCancel = () =>{
        setVisible(false)
    }

    let marks = {
        1:{
            label:'cheap',
            style:{fontSize:12},
        },
        2:{
            label:'normal',
            style:{fontSize:12},
        },
        3:{
            label:'expensive',
            style:{fontSize:12},
        },
        4:{
            label:'luxury',
            style:{fontSize:12},
        },
    }

    return (
        <Col md={24}>
            <Row >
                <Col style={cardStyle} md={8} xs={24}>
                    <div style={mainPic}>
                        {props.item && props.item.mainPic && !_.isEmpty(props.item.mainPic.medium) ?
                            <img style={img}
                                 src={props.item && props.item.mainPic && !_.isEmpty(props.item.mainPic.medium) ?
                                     props.item.mainPic.medium :
                                     null}/>
                            : <div style={noImage}>No Image</div>
                        }
                    </div>
                </Col>
                <Col style={{paddingLeft:10}} md={16} xs={24}>
                    <div style={enTitle}>
                        {props.item.title ? props.item.title.en : ''}
                        <Tag style={{marginLeft:10}} color={status.color}>
                            {status.title}
                        </Tag>
                    </div>
                    <div style={faTitle}>
                        {props.item.title ? props.item.title.fa : ''}
                    </div>
                    <div style={enTitle}>
                        {props.item.title ? props.item.title.tr : ''}
                    </div>
                    <Row md={24} style={{marginBottom: 30,marginTop:20,paddingLeft:10,textAlign:'left'}}>
                        <Col lg={6} md={12} xs={24}>
                            <div>
                                <FontAwesome name="calendar" style={calendarIcon} size={15} />
                                date created
                            </div>
                            <div style={{fontSize:13}}>
                                {fullDate}
                            </div>
                        </Col>
                        <Col lg={6} md={12} xs={24}>
                            <div>
                                <FontAwesome name="calendar-o" style={calendarIcon} size={15} />
                                date modified
                            </div>
                            <div style={{fontSize:13}}>
                                {fullUpdate}
                            </div>
                        </Col>
                        <Col lg={6} md={12} xs={24}>
                            <div>
                                <MaterialIcons name={'play-arrow'} style={calendarIcon} size={'25'} />
                                primary Category
                            </div>
                            <div style={{fontSize:13}}>
                                {props.item.primryCat ? props.item.primryCat.title.en : null}
                            </div>
                        </Col>
                        {props.item.secondaryCat ?
                            <Col lg={6} md={12} xs={24}>
                                <div>
                                    <MaterialIcons name={'play-arrow'} style={calendarIcon} size={'25'} />
                                    secondary Category
                                </div>
                                <div style={{fontSize:13}}>
                                    {props.item.primryCat ? props.item.primryCat.title.en : null}
                                </div>
                            </Col>
                        :null}

                    </Row>
                    <Row style={{marginBottom: 20,marginTop:20,paddingLeft:10,textAlign:'left'}}>
                        <Col md={12}>
                            <div>
                                <Entypo name="flow-tree" style={calendarIcon} size={18} />
                                tree category
                            </div>
                            <div style={{fontSize:13}}>
                                {treePrimaryCat}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div>
                                <FontAwesome name="user-o" style={calendarIcon} size={18} />
                                User Info
                            </div>
                            <div style={{fontSize:13}}>
                                <a onClick={showModal.bind(this,props.item.ownerId)} >
                                    {userInfo}
                                </a>
                            </div>
                            <Modal
                                title={props.fullName}
                                visible={visible}
                                onCancel={handleCancel}
                                footer={null}
                            >
                                <UserInfo id={userId}/>
                            </Modal>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: 20,marginTop:20,paddingLeft:10,textAlign:'left'}}>
                        {props.item.secondaryTrees && !_.isEmpty(props.item.secondaryTrees) ?
                            <Col lg={6} md={12} xs={24}>
                                <div>
                                    <MaterialIcons name={'play-arrow'} style={calendarIcon} size={'25'} />
                                    secondary tree Category
                                </div>
                                <div style={{fontSize:13}}>
                                    {treeSecondaryCat}
                                </div>
                            </Col>
                            :null}
                    </Row>
                    <Row style={{marginBottom: 20,marginTop:20,paddingLeft:10,textAlign:'left'}}>
                        <Col lg={6} md={12} xs={24}>
                            <div>
                                <Entypo name="share" style={calendarIcon} size={18} />
                                social media
                            </div>
                            <a style={{marginRight:10}} href={props.item.socialMedia ? props.item.socialMedia.youtube : null}>
                                <Foundation name={'social-youtube'} color={'#EE1B0D'} />
                            </a>
                            <a style={{marginRight:10}} href={props.item.socialMedia ? props.item.socialMedia.twiter : null}>
                                <Foundation name={'social-twitter'} color={'#52A7E7'} />
                            </a>
                            <a style={{marginRight:10}} href={props.item.socialMedia ? props.item.socialMedia.instagram : null}>
                                <Foundation name={'social-instagram'} color={'#F6511B'} />
                            </a>
                            <a href={props.item.socialMedia ? props.item.socialMedia.facebook : null}>
                                <Foundation name={'social-facebook'} color={'#3659A5'} />
                            </a>
                        </Col>
                        {props.type !== 'event' ?
                            <Col lg={6} md={12} xs={24}>
                                <div>
                                    <MaterialIcons name="star-half" style={calendarIcon} size={18} />
                                    average rate
                                </div>
                                <Rate
                                    disabled
                                    count={5}
                                    size={24}
                                    defaultValue={props.item.rateAvg}
                                    activeColor="#ffd700"
                                />
                            </Col>
                        : null}
                        {props.type !== 'event' ?
                            props.type === 'bazaar' ?
                                <Col lg={6} md={12} xs={24}>
                                    <div>
                                        <FontAwesome name="dollar" style={calendarIcon} size={18}/>
                                        price range
                                    </div>
                                    <Slider min={1} max={4} marks={marks} defaultValue={props.item.priceRange}
                                            disabled={true}/>
                                </Col>
                                :
                                <Col lg={12} md={12} xs={24}>
                                    <div>
                                        <FontAwesome name="dollar" style={calendarIcon} size={18}/>
                                        price range
                                    </div>
                                    {props.item.price && Object.entries(props.item.price).map(([key,value]) => {
                                        return(<Col md={12} key={key}>
                                            <b>{key + ':'}</b>{value}
                                        </Col>)
                                    })}
                                </Col>
                            : null
                        }
                    </Row>
                    <Row style={{marginBottom: 20,marginTop:20,paddingLeft:10,textAlign:'left'}}>

                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={24} style={{paddingRight:20}}>
                    <Divider orientation="left">Description</Divider>
                    <div style={enDesc}>
                        {props.item.description && props.item.description.en ?
                            props.item.description.en:
                            ''}
                    </div>
                </Col>
                <Col md={12} xs={24} style={{paddingLeft:10}}>
                    <Divider orientation="right" style={{fontFamily:'IRANSans'}}>توضیحات</Divider>
                    <div style={faDesc}>
                        {props.item.description && props.item.description.fa ?
                            props.item.description.fa:
                            ''}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={24} style={{paddingRight:20}}>
                    <Divider orientation="left">Tanım</Divider>
                    <div style={enDesc}>
                        {props.item.description && props.item.description.tr ?
                            props.item.description.tr:
                            ''}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={24} >
                    <Divider orientation="left">Text</Divider>
                    <div style={enDesc}>
                        {props.item.text ?
                            props.item.text:
                            'No Data'}
                    </div>
                </Col>
            </Row>
            <Row>
                <Divider orientation="left">Contact us</Divider>
                <Col md={12} xs={24} style={{paddingRight:20}}>
                    {props.item.address ?
                        props.item.address.map((address,index)=> {
                            return(
                                <div key={index} style={{borderBottom:'1px solid #efefef'}}>
                                    <div style={enDesc}>
                                        {props.type !== 'market' ?
                                            address.phone && address.phone.map((item, index) => {
                                                return (<div key={index}>
                                                    <b>{item.title.en + ' : '}</b>{item.phone}
                                                </div>)
                                            })
                                        :
                                            <>
                                                <div key={index}>
                                                    <b>phone : </b>{address.phone}
                                                </div>
                                                <div key={index}>
                                                    <b>sms : </b>{address.sms}
                                                </div>
                                            </>
                                        }
                                        <div>
                                            <b>country :</b>
                                            {
                                                address.country && address.country.title ?
                                                address.country.title.en :
                                                ''
                                            }
                                        </div>
                                        <div>
                                            <b>state :</b>
                                            {
                                                address.state && address.state.title ?
                                                    address.state.title.en :
                                                    ''
                                            }
                                        </div>
                                        <div>
                                            <b>city :</b>
                                            {
                                                address.city && address.country.title ?
                                                    address.country.title.en :
                                                    ''
                                            }
                                        </div>
                                        <div>
                                            <b>address :</b>
                                            {
                                                address.address && address.address.fa ?
                                                    address.address.fa :
                                                    ''
                                            }
                                            <br />
                                            {
                                                address.address && address.address.en ?
                                                    address.address.en :
                                                    ''
                                            }
                                            <br />
                                            {
                                                address.address && address.address.tr ?
                                                    address.address.tr :
                                                    ''
                                            }
                                        </div>

                                    </div>
                                </div>
                            )})
                    : null}
                </Col>
                <Col md={12} xs={24} style={{paddingLeft:10}}>
                    <div style={mapStyle}>
                        <MapContainer map={props.item.address ? props.item.address : []} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Divider orientation="left">Image Gallery</Divider>
                {props.item.gallery && props.item.gallery.length > 0  && props.item.gallery.map((item,index)=>{
                    if(item.children && item.children.length > 0)
                        return (
                            <Col key={index} md={8} xs={24} style={{paddingLeft:10}}>
                                <ImgGallery item={item.children} />
                            </Col>
                        )
                })}

            </Row>
            <Row>
                <Col md={24} xs={24} style={{paddingRight:20}}>
                    <Divider orientation="left">feature list</Divider>
                    {props.item.features && props.item.features.length > 0 && props.item.features.map((feature,index)=>{
                        if(feature.value === true)
                            return (
                                <span key={index} md={4} xs={24}
                                      style={{float:'left',borderRight:'1px solid #e1e1e1',padding:'0 5px 0  5px'}}>
                                    {feature.title.en}
                                </span>
                            )
                    })}
                </Col>
            </Row>
            <Row>
                <Col md={24} xs={24} style={{paddingRight:20}}>
                    <Divider orientation="left">custom field</Divider>
                    {custom_field.length > 0 && custom_field.map((custom,index)=>{
                        return (
                            <Col key={index} md={8} xs={24} style={itemBorderBottom}>
                                <b>{custom.title} : </b>{custom.value}
                            </Col>
                        )
                    })}
                </Col>
            </Row>
            <Row>
                <Col md={24} xs={24} style={{paddingRight:20}}>
                    <Divider orientation="left">work timeTable (timezone:{props.item.timeZone})</Divider>
                    <div style={enDesc}>
                        <Table columns={workTimeColumn} dataSource={workTimeData} pagination={false} />
                    </div>
                </Col>
            </Row>
        </Col>
    );

}
const cardStyle = {
    marginBottom:15
}
const mainPic = {
}
const img = {
    width:'100%',
}
const enTitle = {
    textAlign:'left',
    paddingLeft:5,
    fontWeight:600,
    fontSize:20
}
const faTitle = {
    textAlign:'left',
    paddingLeft:5,
    fontFamily:'IRANSans',
    color:'#999',
}
const enDesc = {
    textAlign:'left',
    padding:5,
    color:'#74788d',
    overflowWrap:'break-word'
}
const faDesc = {
    textAlign:'right',
    padding:5,
    color:'#74788d',
    fontFamily: 'IRANSans',
    overflowWrap:'break-word'
}
const calendarIcon = {
    marginRight:10,
    color:'#556EE6'
}
const mapStyle = {
    height:400
}
const noImage = {
    background:'#eee',
    color:'#999',
    fontSize:30,
    align:'center',
    padding:'130px 0',
}
const itemBorderBottom = {
    textAlign:'left',
    borderBottom:'1px solid #eee',
    paddingBottom:5,
    paddingTop:5,
}
export default CardLists;


