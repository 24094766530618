import React, { useState } from 'react'
import { Button } from 'antd';
import {appLogout} from "../../library/utils/AuthService";

const Logout = (props) => {

    const onLogout = () => {
        appLogout()
    }

    return(
        <Button loading={false} shape="round" type="danger" icon="logout" style={{marginRight: '10px'}} onClick={onLogout.bind(this) }>Exit</Button>
    );
};

export default Logout;


