//--- React Hook
import React, {useState, useEffect} from 'react';

//--- ant design
import {
    Row, Form, Divider, Col, Button, Icon, Input, message, Modal,
} from 'antd';

/* ------ custom -------- */
import Loading from "../../../library/components/Loading";
import _ from 'lodash'

import {STATE_INFO} from "../../queries/state/state";
import {ADD_CITY, CITY_INFO, DELETE_CITY, EDIT_CITY} from "../../queries/city/city";


const CityAddEdit = (props) => {

    if (props.match.params.id) {
        var explodeUrl = (props.match.url).split('/')
    }

    /* ------------------------------------------------ */
    /* ---------------- Define State ------------------ */
    /* ------------------------------------------------ */
    const [form, setFormField] = useState({
        title_en: '',
        title_fa: '',
        title_tr: '',
    });

    const [mode, setMode] = useState(explodeUrl ? explodeUrl[2] : '');
    const [id, setId] = useState(props.match.params.id ? props.match.params.id : '');
    const [stateId, setStateId] = useState(0);
    const [countryId, setCountryId] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [stateName, setStateName] = useState('');


    useEffect(() => {

        /*---- edit mode ---*/
        if (props.match.params.id) {
            let id = props.match.params.id
            let explodeUrl = (props.match.url).split('/')
            setMode(explodeUrl[2]);
            if(mode === 'add')
                stateInfoRefetch(id);
            else
                cityInfoRefetch(id);
        }

    },[] );

    /* ------------------------------------------------ */
    /* -------------------- Query --------------------- */
    /* ------------------------------------------------ */
    const stateInfoRefetch = (id) => {
        setLoading(true)
        STATE_INFO(id).then(info => {
            if(info.status === 200){
                let data = info.data.data
                setStateName(data.title.en);
                setStateId(data.id);
                setCountryId(data.country);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const cityInfoRefetch = (id) => {
        setLoading(true)
        CITY_INFO(id).then(info => {
            if(info.status === 200){
                let data = info.data.data
                setLoading(false)
                setFormField({
                    title_en : mode === 'edit' ? data.title.en : '',
                    title_fa : mode === 'edit' ? data.title.fa : '',
                    title_tr : mode === 'edit' ? data.title.tr : '',
                });
            }
        }).catch(error => {
            console.log(error);
        });

    }

    var addCity = (data) =>  {
        setButtonLoading(true);
        data['country']=countryId
        data['state']=stateId
        ADD_CITY(data).then(res => {
            if(res.status === 201) {
                res && res.data ? message.success("the ` " + (res.data.data.title.en || res.data.data.title.tr)+ " ` was created.") : message.error("Error")
                setButtonLoading(false);
            }else
                return 'error'
        }).catch(error => {
            console.log(error);
        });

    }

    var updateCity = (data) =>  {
        setButtonLoading(true);
        data['id'] = id
        EDIT_CITY(data).then(res => {
            if(res.status === 200) {
                res ? message.success("the ` " + (res.data.data.title.en || res.data.data.title.tr)+ " ` was updated.") : message.error("Error")
                setButtonLoading(false);
            }else
                return 'error'
        }).catch(error => {
            console.log(error);
        });

    }

    const deleteCity = (id) => {
        setDeleteLoading(true)
        DELETE_CITY(id).then(res => {
            if(res.data.errCode === -6) {
                message.error("there are assigned bazaar/marketplace/event to this City",5)
            }

            if (res.status === 200) {
                let city = res.data.data
                res ? message.success("the ` " + (city.title.en || city.title.tr) + " ` was deleted.") : message.error("Error in delete City")
                props.history.goBack()
                setDeleteLoading(false)
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (e) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                e.preventDefault();
                deleteCity(id);
            },
            onCancel() {},
        });
    }


    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */
    var handleTextInput = (name, e) => {
        const {value} = e.target;
        let newForm = {...form};
        newForm[name] = value;
        setFormField(newForm)
    };

    /* ------------------------------------------------ */
    /* -----------------  R E N D E R ----------------- */
    /* ------------------------------------------------ */
    const goBack = () => {
        props.history.goBack()
    }

    return (

        <Form onSubmit={e => {
            e.preventDefault();
            (mode === 'edit' ? updateCity : addCity)({
                title: {
                    en: form.title_en,
                    tr: form.title_tr,
                    fa: form.title_fa
                },

            });
        }} className="login-form">
            <Row type="flex">
                <Col xs={24} md={mode === 'edit' ? 20 : 24}>
                    <Divider orientation="left">{mode === 'edit' ? "edit" : "add"} city {mode === 'add' ? 'to '+ stateName : ''}</Divider>
                </Col>
                <Col xs={24} md={mode === 'edit' ? 4 : 0}>
                    <Button type="primary" className="userListBtn" shape="round" icon="minus" loading={deleteLoading ? true : false}
                            onClick={showConfirm.bind(this)}>
                        delete City
                    </Button>
                </Col>
            </Row>
            {mode === 'edit' && loading ?
                <Loading/>
                :
                <Row type="flex" justify="start" align="middle">
                    <Col md={18} sm={24} type="flex" justify="start" align="middle">
                        <Row gutter={16}>

                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"english title"}
                                value={form.title_en}
                                onChange={handleTextInput.bind(this, 'title_en')}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"persian title"}
                                value={form.title_fa}
                                onChange={handleTextInput.bind(this, 'title_fa')}
                                style={{fontFamily:'IRANSans'}}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"turkish title"}
                                value={form.title_tr}
                                onChange={handleTextInput.bind(this, 'title_tr')}
                            />
                        </Row>

                        <Row>
                            <Button loading={buttonLoading} type="primary" shape="round"
                                    htmlType="submit"
                                    className="login-form-button">
                                {mode === 'edit' ? "edit" : "add"}
                            </Button>
                            <Button style={{color:'#fff'}} onClick={goBack.bind()} className="backBtn" type="default" shape="round">
                                back
                            </Button>
                        </Row>
                    </Col>
                </Row>
            }


        </Form>

    );

}

export default CityAddEdit;



