import React, { useState ,useEffect} from 'react';
import {
    Row,
    message,
    Col,
    Divider,
    Button,
    Modal, Table, Tooltip,
} from "antd"; // This only needs to be imported once in your app
import 'react-sortable-tree/style.css';
import Loading from "../../../library/components/Loading";
import {OPTION_LIST,DELETE_OPTION} from "../../queries/report/reports"
import 'react-sortable-tree/style.css';
import _ from "lodash";
import {MaterialCommunityIcons} from "react-web-vector-icons";
import {Link} from "react-router-dom";
import moment from "jalali-moment";

const columns = [
    {
        title: 'id',
        dataIndex: 'key',
        width:'5%'
    },
    {
        title: 'English title',
        dataIndex: 'en_title',
    },
    {
        title: 'persian title',
        dataIndex: 'fa_title',
        className:'font-fa'
    },
    {
        title: 'type',
        dataIndex: 'type',
    },
    {
        title: 'created date',
        dataIndex: 'created_at',
    },
    {
        title: 'action',
        dataIndex: 'action',
        width:'10%'
    },
];

function OptionsList(props){

    const [listLoading, setListLoading] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        getOptionList()
    }, []);

    /* ------------------------------------------------ */
    /* ---------------- Country Query ----------------- */
    /* ------------------------------------------------ */

    const getOptionList = () => {
        setListLoading(true)
        let param = {
            limit : 10,
            skip : 0
        }
        OPTION_LIST(param).then(list => {
            if(list.status === 200) {
                if (list.data.data.length > 0) {
                    setListLoading(false)
                    setList(list.data.data)
                } else
                    return list;
            }

        }).catch(error => {
            console.log(error);
        });

    }

    const deleteOption = (id) => {
        DELETE_OPTION(id).then(res => {
            if (res.status === 200) {
                let country = res.data.data
                res ? message.success("the ` " + country.title.en + " ` was deleted.") : message.error("Error in delete country")
                var index = _.findIndex(list, {id: id});
                list.splice(index, 1);
                getOptionList()
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /*--- fix options list for show in table ---*/
    const fixListForShowInTable = () => {
        var dataSource = [];

        if(list) {

            for (let i = 0; i < list.length; i++) {

                dataSource.push({
                    key: i+1,
                    en_title: list[i].title ? list[i].title.en : '',
                    fa_title: list[i].title ? list[i].title.fa : '',
                    type: list[i].type ? list[i].type : '',
                    created_at: list[i].createdAt ? moment(list[i].createdAt).format('lll') : '',
                    action: <span>
                                <Tooltip placement="top" title="edit">
                                    <Link to={"/report/options/edit/"+list[i].id} >
                                        <MaterialCommunityIcons name={'pencil-circle'} size={30} color={'#FFC107'} />
                                    </Link>
                                </Tooltip>
                                <Tooltip placement="top" title="remove">
                                    <Divider type="vertical" />
                                    <a onClick={showConfirm.bind(this,list[i].id)} >
                                        <MaterialCommunityIcons name={'close-circle'} size={30} color={'red'} />
                                    </a>
                                </Tooltip>
                        {list[i].status === 1 ?
                            <Divider type="vertical" />
                            : null}
                            </span>
                });
            }
        }
        return dataSource;
    }

    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (id) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                deleteOption(id);
            },
            onCancel() {},
        });
    }

    return(
        <div>
            <Row>
                <Col xs={24} md={20}>
                    <Divider orientation="left">option list</Divider>
                </Col>
                <Col xs={24} md={4}>
                    <Button type="primary" className="userListBtn" shape="round" icon="plus" href="#/report/options/add">
                        create option
                    </Button>
                </Col>
            </Row>
            <Row>
                {listLoading ?
                    <Loading />
                    :
                    <Table rowClassName={(record, index) => (index + 1) % 2 == 0 ? 'even' : 'odd'} pagination={true} columns={columns} dataSource={fixListForShowInTable()} size="small" />
                }
            </Row>
            {/*<Row type="flex" justify="space-between">*/}
            {/*    <Col md={14} className="right">*/}
            {/*        <Pagination*/}
            {/*            name="pageNum"*/}
            {/*            current={current}*/}
            {/*            total={( list ?  list.count : 10)}*/}
            {/*            // onChange={(pageNum) => handlePageChange(pageNum)}*/}
            {/*            pageSizeOptions = {['10','20','50','100']}*/}
            {/*            showSizeChanger*/}
            {/*            onShowSizeChange={onChangeCounter()}*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    )

}

export default OptionsList;
