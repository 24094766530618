import React, { useState ,useEffect} from 'react';
import {
    Row,
    Icon,
    Table,
    Col,
    Tooltip,
    Divider,
    Tag,
    Modal,
    Button,
    Form,
    Input,

} from "antd"; // This only needs to be imported once in your app
import 'react-sortable-tree/style.css';
import Loading from "../../library/components/Loading";
import {USER_LIST} from "../queries/user/getUserList"
import {CHANGE_STATUS} from "../queries/user/changeUserStatus"
import _ from 'lodash'
import {Ionicons,
    MaterialCommunityIcons,
    AntDesign} from 'react-web-vector-icons'
import {Link} from "react-router-dom";
import UserInfo from "../../library/components/UserInfo";
import {SEARCH_USER} from "../queries/user/searchUser";

const columns = [
    {
        title: 'id',
        dataIndex: 'key',
        width:'5%'
    },
    {
        title: 'name',
        dataIndex: 'name',
    },
    {
        title: 'family',
        dataIndex: 'family',
    },
    {
        title: 'mobile',
        dataIndex: 'mobile',
    },
    {
        title: 'register by',
        dataIndex: 'register',
    },
    {
        title: 'timezone',
        dataIndex: 'timezone',
    },
    {
        title: 'status',
        dataIndex: 'status',
    },
    {
        title: 'action',
        dataIndex: 'action',
        width:'15%'
    },
];

function UserList(props){

    const [filter, setFilter] = useState([]);
    const [list, setList] = useState([]);
    const [userListLoading, setUserListLoading] = useState(false);
    const [visible,setVisible] = useState(false)
    const [userId, setUserId] = useState('');
    const [form, setForm] = useState([]);
    const [searchBoxHidden, setSearchBoxHidden] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    /* ------------------------------------------------ */
    /* --------------------- Query -------------------- */
    /* ------------------------------------------------ */

    const userList = () =>{
        setUserListLoading(true)
        USER_LIST.then(res => {
            if(res.status === 200) {
                if(res.data && res.data.data)
                {
                    setList(res.data.data)
                    setUserListLoading(false)
                }
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const acceptUser = (id) => {
        let param = {
            "userId" : id,
            "status" : 1
        }
        CHANGE_STATUS(param).then(res => {
            if(res.status === 200) {
                if(res.data && res.data.data)
                {
                    var index = _.findIndex(list, {id: id});
                    list.splice(index, 1, res.data.data);
                    userList()
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const blockUser = (id) => {
        let param = {
            "userId" : id,
            "status" : 0
        }
        CHANGE_STATUS(param).then(res => {
            if(res.status === 200) {
                if(res.data && res.data.data)
                {
                    var index = _.findIndex(list, {id: id});
                    list.splice(index, 1, res.data.data);
                    userList()
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /* ------------------------------------------------ */
    /* ------------- componentDidMount --------------- */
    /* ------------------------------------------------ */
    useEffect(() => {
        if(_.isEmpty(filter))
            userList()
        else
            searchUser(filter)
    }, []);


    /*--- fix user list for show in table ---*/
    const fixListForShowInTable = () => {
        var dataSource = [];
        let status;
        let registre;

        if(list) {

            for (let i = 0; i < list.length; i++) {

                if(list[i].status === 1)
                    status = <Tag color="green">accept</Tag>
                else
                    status = <Tag color="red">block</Tag>

                if(list[i].howRegister === "usual")
                    registre = <Tag color="#999"> Normal </Tag>
                else if(list[i].howRegister === "google")
                    registre = <Tag color="#E34133"> Google </Tag>
                else if(list[i].howRegister === "facebook")
                    registre = <Tag color="#4064AC"> facebook </Tag>

                dataSource.push({
                    // key: (pageSize*(list.page_number-1))+(i+1),
                    key: i+1,
                    name: list[i].name,
                    family: list[i].family,
                    register: registre,
                    mobile: list[i].countryCode+list[i].mob,
                    timezone: list[i].timezone,
                    status: status,
                    action: <span>
                                <Tooltip placement="top" title="edit">
                                    <Link to={"/user/edit/"+list[i].id} >
                                        <MaterialCommunityIcons name={'pencil-circle'} size={30} color={'#FFC107'} />
                                    </Link>
                                </Tooltip>
                                {list[i].status === 1 ?
                                <Divider type="vertical" />
                                : null}
                                {list[i].status === 1 ?
                                <Tooltip placement="top" title="block">
                                    <a onClick={blockUser.bind(this,list[i].id)} >
                                        <MaterialCommunityIcons name={'cancel'} size={29} color={'red'} />
                                    </a>
                                </Tooltip>
                                : null}
                                {list[i].status === 0 ?
                                <Divider type="vertical" />
                                : null}
                                {list[i].status === 0 ?
                                <Tooltip placement="top" title="accept">
                                    <a onClick={acceptUser.bind(this,list[i].id)} >
                                        <Ionicons name={'ios-checkmark-circle'} size={30} color={'lightseagreen'} />
                                    </a>
                                </Tooltip>
                                : null}
                                <Divider type="vertical" />
                                <Tooltip placement="top" title="user info">
                                    <a onClick={showModal.bind(this,list[i].id)} >
                                        <MaterialCommunityIcons name={'information'} size={30} color={'#06AAE9'} />
                                    </a>
                                </Tooltip>
                            </span>
                });
            }
        }
        return dataSource;
    }

    const showModal = (id) => {
        setVisible(true);
        setUserId(id)
    }

    const handleCancel = () =>{
        setVisible(false)
    }

    const onChangeCounter = (current, pageSize) => {
        // setSize(size);
        // this.setState(prevState  => ({
        //     filter: {...prevState.filter,page:current,size:pageSize}
        // }))
        // this.props.getList(filter);
    }

    const goBack = () => {
        this.props.history.goBack()
    }

    /* ------------------------------------------------ */
    /* ----------------- Search  ---------------------- */
    /* ------------------------------------------------ */
    var searchUser = (data) =>  {
        setButtonLoading(true);
        setLoading(true);
        setFilter(prevState  => {
            return {...prevState.filter, ...data}
        })
        SEARCH_USER(data).then(res => {
            if(res.status === 200) {
                setLoading(false);
                setButtonLoading(false);
                setList(res.data.data)
            }else
                return 'error'
        }).catch(error => {
            console.log(error);
        });

    }

    const toggleSearchBox = () =>{
        setSearchBoxHidden(!searchBoxHidden)
    }

    const clearFilter = () => {
        setFilter([])
        setForm([])
        userList();
    }

    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */
    var handleTextInput = (name, e) => {
        const {value} = e.target;
        let newForm = {...form};
        newForm[name] = value;
        setForm(newForm)
    };

    return(
        <div>
            <Row>
                <Col xs={24} md={22}>
                    <Divider orientation="left">user list</Divider>
                </Col>
                <Col xs={24} md={2} >
                    <Button type="primary" shape="round" className="searchBtnBox" onClick={toggleSearchBox.bind(this)} >
                        <AntDesign color={'#ffffff'} size={20} name={'search1'} />
                    </Button>
                </Col>
            </Row>
            <Row hidden={searchBoxHidden} gutter={16} style={{marginBottom:10}}>
                <Form onSubmit={e => {
                    e.preventDefault();
                    searchUser({
                            name   : form.name ? form.name : "",
                            family : form.family ? form.family : "",
                            mob    : form.mob ? form.mob : ""
                    });
                }}>
                    <Col md={4} xs={6}>
                        <Input
                            prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder={"name"}
                            value={form.name}
                            onChange={handleTextInput.bind(this, 'name')}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <Input
                            prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder={"family"}
                            value={form.family}
                            onChange={handleTextInput.bind(this, 'family')}
                        />
                    </Col>
                    <Col md={4} xs={6}>
                        <Input
                            prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder={"mobile"}
                            value={form.mob}
                            onChange={handleTextInput.bind(this, 'mob')}
                        />
                    </Col>
                    <Col sm={24} md={2}>
                        <Button loading={buttonLoading} className="left" type="primary" shape="round" htmlType="submit">
                            search
                        </Button>
                    </Col>
                    <Col sm={24} md={2}>
                        <Button className="left" type="default" shape="round" onClick={clearFilter.bind(this)}>
                            reset
                        </Button>
                    </Col>
                </Form>
            </Row>
            <Row>
                {userListLoading ?
                    <Loading />
                    :
                    <Table rowClassName={(record, index) => (index + 1) % 2 == 0 ? 'even' : 'odd'} pagination={true} columns={columns} dataSource={fixListForShowInTable()} size="small" />
                }
            </Row>
            {/*<Row type="flex" justify="space-between">*/}
            {/*    <Col md={14} className="right">*/}
            {/*        <Pagination*/}
            {/*            name="pageNum"*/}
            {/*            current={current}*/}
            {/*            total={( list ?  list.count : 10)}*/}
            {/*            // onChange={(pageNum) => handlePageChange(pageNum)}*/}
            {/*            pageSizeOptions = {['10','20','50','100']}*/}
            {/*            showSizeChanger*/}
            {/*            onShowSizeChange={onChangeCounter()}*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Modal
                visible={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <UserInfo id={userId}/>
            </Modal>
        </div>
    )

}

export default UserList;
