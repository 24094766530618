import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import appConfig from '../../config'
import _ from 'lodash'

export default function MapContainer(props){

     const RegularMap = withScriptjs(
        withGoogleMap(() => (
            <GoogleMap
                defaultZoom={parseFloat(15)}
                defaultCenter={{
                    lat: props.map && props.map[0] && props.map[0].location.lat ? parseFloat(props.map[0].location.lat) : 41.046216814520655,
                    lng: props.map && props.map[0] && props.map[0].location.lng ? parseFloat(props.map[0].location.lng) : 641.2170410156251 }}
                defaultOptions={{
                    scrollwheel: true,
                    zoomControl:true,
                    fullscreenControl:false,
                    streetViewControl:false,
                    mapTypeControl:false,
                }}
            >
                {_.isArray(props.map) && props.map.map((item,index) => {
                    return(<Marker key={index}
                    position={{ lat: parseFloat(item.location.lat), lng: parseFloat(item.location.lng) }}
                    />)
                })}

            </GoogleMap>
        ))
    );

    return (
        <RegularMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${appConfig.googleMapAPI}`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={props.style} />}
            mapElement={<div style={{ height: 400 }} />}
        />
    );
}
