import {API_FETCH} from "../../../library/utils/FetchService";

export const STATE_INFO = (id) =>{
    return API_FETCH.get('super/state/'+id)
}

export const STATE_LIST = (param) => {
    return API_FETCH.post('super/state',param)
}

export const ADD_STATE = (param) =>{
    return API_FETCH.post('super/state/create',param)
}

export const EDIT_STATE = (param) =>{
    return API_FETCH.put('super/state/'+param.id,param)
}

export const DELETE_STATE = (id) =>{
    return API_FETCH.delete('super/state/'+id)
}
