//--- React Hook
import React, { useState, useEffect } from 'react';

//--- ant design
import {
    Input, Tooltip,Icon,Modal
} from 'antd';



const  EditableText = (props) => {



    /* ------------------------------------------------ */
    /* ---------------- Define State ------------------ */
    /* ------------------------------------------------ */
    const [inputValue, setInputValue] = useState(props.label);
    const [editModeInState, setEditModeInState] = useState(false);
    var nameInput = null;
    var editMode = props.editMode ? props.editMode : editModeInState;



    var onChangeMode = (e)=>{
        e.preventDefault();
        setEditModeInState(!editMode);
        if(props.onChangeMode)
            props.onChangeMode(editMode);
    }
    var setEditMode = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        setEditModeInState(true);
        nameInput.focus();
        if(props.onChangeMode)
            props.onChangeMode(true);
    }
    var disableEditMode = ()=>{
        setEditModeInState(false);
        if(props.onChangeMode)
            props.onChangeMode(false);
    }

    var onSaveText = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        disableEditMode();
        props.onSave(inputValue, ()=>{
        })
        return false;
    }

    var onDelete = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        const { confirm } = Modal;
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                disableEditMode();
                props.onDelete(() => {
                })
            },
            onCancel() { },
        });
        return false;
    }

    var onchangeInput = (e)=>{

        let value = e.target.value;
        setInputValue(value)
        if (props.onChange)
            props.onChange(e);
    }


    if(editMode)
        return (
            <div className={props.class} style={props.holderStyle ? { display: 'inline-block', height: '20px', ...props.holderStyle, } : { display: 'inline-block', height: '20px' }} onClick={setEditMode.bind(this)}>
                <Input
                    value={inputValue}
                    size="small"
                    ref={(input) => { nameInput = input; }}
                    autoFocus
                    suffix={[(props.onDelete)?
                        <Tooltip title="Delete">
                            <Icon onClick={onDelete.bind(this)} type="delete" style={{ color: '#1890ff', top: '-5px', position: 'relative', display: 'block', marginLeft: '3px', }} />
                        </Tooltip>:null,
                        <Tooltip  title="Save">
                            <Icon onClick={onSaveText.bind(this)} type="save" style={{ color: '#ff4d4f',top:'-5px',position:'relative',display:'block',marginLeft: '3px', }} />
                        </Tooltip>,
                    ]}
                    {...props}
                    onChange={onchangeInput.bind(this)}
                    onPressEnter={onSaveText.bind(this)}
                    className={props.class}
                />
            </div>
        )
    else
        return (
            <div style={props.holderStyle ? { display: 'inline-block', height: '20px', ...props.holderStyle, } : { display: 'inline-block', height: '20px' }}  onClick={onChangeMode}>
                {props.label}
            </div>
        )
}


export default  EditableText;
