import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import './assets/styles/index.css';
import './assets/styles/font.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import {ConfigProvider} from "antd";

var rootNode = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
            <ConfigProvider>
                <App/>
            </ConfigProvider>
        </div>
      </ConnectedRouter>
    </Provider>,
    rootNode
  )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
