export default {

    version: 1,
    host: "https://api.irusapp.com/api",
    // host: "http://localhost:1337/api",
    apiVersion: "v1",
    mapAccessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjlmNzU4NjIwYmFhYzMwZjk5MDJhNTQ4OTBlNTA4ZjFjYjY2NmVlOGM0MWY3YmE1OGIxNTQ4NjRmMGY5ZWMzY2MzZGU0NDk4MmVlZmFkNTU0In0.eyJhdWQiOiJteWF3ZXNvbWVhcHAiLCJqdGkiOiI5Zjc1ODYyMGJhYWMzMGY5OTAyYTU0ODkwZTUwOGYxY2I2NjZlZThjNDFmN2JhNThiMTU0ODY0ZjBmOWVjM2NjM2RlNDQ5ODJlZWZhZDU1NCIsImlhdCI6MTU1ODU1MzU2NiwibmJmIjoxNTU4NTUzNTY2LCJleHAiOjE1NTg1NTcxNjYsInN1YiI6IiIsInNjb3BlcyI6WyJiYXNpYyIsImVtYWlsIl19.bIE5R06wkqYmIzEIN1rbY2Jec-rCZL6vJ5m4yjpIMg7PItY0ULbykR31AYRWSzMpeY5tFYwBq0bTCf4FPkBQqV1gS-sq5OarRN1qPoJ7xfaOM0y6A2-sTp38V_p63yRxtG5peba4fkxQXheZzuG1Sxq2ww4k6-jkwUl2j-6YWszxmPdvb4wgmZ_YO7HqtwvtcLbYx2pozIiF7qfPFW4fsZGWsREyWF95H3xeqQJU1SARGSLfobnKPr2t1bekWBfXiFv3dZZ_OJHO21ps2BLrofIwLDApIUouYVfIba5o_XJD6VCpnENSFw5Gq5qlCBWOKAkw4YOZpx0e_8WbB5NPGQ",
    googleMapAPI: "AIzaSyDzNjLXqUZK8aXcKgG1K9HLOoupjixT4O8",

    mainColor: "#FCAD10",
    secondColor: "#292929",
    thirdColor: "#FDAE18",
    mainDarkColor: "#4b9e01",
    mainLightColor: "rgba(252, 173, 16, 0.17)",


};
