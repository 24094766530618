import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory, createHashHistory } from 'history';
import rootReducer from './rootReduser';
import { logger } from 'redux-logger';

//-- for routing -> Make History
export const history = createHashHistory({
    hashType: 'slash' // the default
})

const initialState = {};
const enhancers = [];
// const middleware = [thunk, routerMiddleware(history), logger];
const middleware = [thunk, routerMiddleware(history)];



if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
};



const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

export default createStore(
    // connectRouter(history)(rootReducer),
    rootReducer(history),
    initialState,
    composedEnhancers
);
