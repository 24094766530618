import React,{useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export const SlideShow = (props) => {

    return(
        <Carousel>
            {
                props.item && props.item.map((item,index) => {
                    return (
                        <div key={index}>
                            <img style={{width:'100%',borderRadius:'5px 5px 0 0'}} src={item} />
                        </div>
                    )
                })
            }
        </Carousel>
    )
}
