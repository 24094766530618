import {API_FETCH} from "../../../library/utils/FetchService";

export const COUNTRY_LIST =  API_FETCH.get('super/country')

export const COUNTRY_INFO = (id) =>{
    return API_FETCH.get('super/country/'+id)
}

export const ADD_COUNTRY = (param) =>{
    return API_FETCH.post('super/country',param)
}

export const EDIT_COUNTRY = (param) =>{
    return API_FETCH.put('super/country/'+param.id,param)
}

export const DELETE_COUNTRY = (id) =>{
    return API_FETCH.delete('super/country/'+id)
}
