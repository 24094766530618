//--- React Hook
import React, {useState, useEffect} from 'react';

//--- ant design
import {
    Col, Button, Icon, Input, Typography, Card, Tag, Popconfirm,
} from 'antd';
import _ from 'lodash'


function SelectboxCustomField(props) {

    /* ------------------------------------------------ */
    /* ------------- like componentDidMount --------------- */
    /* ------------------------------------------------ */
    useEffect(() => {
    }, []);


    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */

    var deleteField = () => {
        props.onDelete();
    }

    var onChangeTitle = (type, e) => {
        let value = e.target.value;
        let newCutomFieldObj = {...props.data};
        newCutomFieldObj.title[type] = value;
        props.onChange(newCutomFieldObj);
    }

    var onAddOptions = () => {
        let newOptions = mkSelectboxOptionsCustomField(props.data);
        let newCustomFieldObj = {...props.data};
        newCustomFieldObj.options.push(newOptions);
        props.onChange(newCustomFieldObj);
    }

    var onRemoveOption = (fieldIndex) => {
        field.options.splice(fieldIndex,1);
        props.onChange(field);
    }

    var onChangeOptionsItem = (index, type, e) => {
        let value = e.target.value;
        let newCustomFieldObj = {...props.data};
        newCustomFieldObj.options[index]['title'][type] = value;
        props.onChange(newCustomFieldObj);
    }

    var onChangeOptionsItemVal = (index, type, e) => {
        let value = e.target.value;
        let newCustomFieldObj = {...props.data};
        newCustomFieldObj.options[index]['value'] = value;
        props.onChange(newCustomFieldObj);
    }


    var field = props.data;


    /* ------------------------------------------------ */
    /* -----------------  R E N D E R ----------------- */
    /* ------------------------------------------------ */
    return (
        <Card
            title={<Tag title={field.id} color="blue">SelectBox Field </Tag>}
            style={{marginTop: 20}}
            extra={<Popconfirm placement="top"
                               title={'maybe some bazaars/marketplaces/events uses this field , so are you sure to delete this field ?'}
                               onConfirm={deleteField.bind(this)}
                               icon={<Icon type="question-circle-o" style={{color: 'red'}}/>} okText="Yes"
                               cancelText="No"><Button type="danger" ghost>delete</Button></Popconfirm>}
        >

            <Input.Group compact>
                <h5 style={{width: '20%'}}>Title </h5>
                <Input required placeholder="عنوان به فارسی" value={field.title && field.title.fa ? field.title.fa : null}
                       onChange={onChangeTitle.bind(this, 'fa')} style={{width: '33%',fontFamily:'IRANSans'}}/>
                <Input required placeholder="English Title" value={field.title && field.title.en ? field.title.en : null}
                       onChange={onChangeTitle.bind(this, 'en')} style={{width: '33%'}}/>
            </Input.Group>


            <Input.Group compact>
                <h5 style={{width: '20%'}}>Items</h5>
                {field.options && field.options.map((item, index) => {
                    return <Col style={{width: '100%'}} key={index}>
                        <Input.Group compact>
                            <div style={{width: '20%'}}></div>
                            <Input required placeholder="عنوان به فارسی" defaultValue={item.title && item.title.fa ? item.title.fa : null}
                                   onChange={onChangeOptionsItem.bind(this, index, 'fa')} style={{width: '33%',fontFamily:'IRANSans'}}/>
                            <Input required placeholder="English Title" defaultValue={item.title && item.title.en ? item.title.en : null}
                                   onChange={onChangeOptionsItem.bind(this, index, 'en')} style={{width: '33%'}}/>
                            <Input placeholder="Value" defaultValue={item.value}
                                   onChange={onChangeOptionsItemVal.bind(this, index, 'value')}
                                   style={{width: '10%', display: 'none'}}/>
                            {(index == 0) ?
                                <div style={{marginLeft: 20}}><Button shape="circle" icon="plus"
                                                                      onClick={onAddOptions.bind(this)}/></div>
                                :
                                <div style={{marginLeft: 20}}><Button shape="circle" icon="minus"
                                                                      onClick={onRemoveOption.bind(this,index)}/></div>
                            }
                        </Input.Group>
                    </Col>
                })}
            </Input.Group>

        </Card>

    );

}

const mkSelectboxCustomField = () => {
    return {
        type: 'dropdown',
        title: {
            fa: null,
            en: null,
        },
        options: [
            {
                title: {
                    fa: null,
                    en: null,
                },
                value: "field"+parseInt(new Date().getTime()/1000)+"i0"
            }
        ],
        isRequire: null,
    }
};

export const findEmptySelectField = (params) => {
    var options = []
    if(params.options.length > 0){
        params.options.map((item,index)=>{
            if(!_.isEmpty(item.title.en) && !_.isEmpty(item.title.fa) &&
                item.title.en !== null && item.title.fa !== null)
                options.push(item)
        })
    }
    return {'options':options,'id':params.id};
};

const mkSelectboxOptionsCustomField = (allFields) => {
    let defaultVal = allFields.id + "i" + allFields.options.length;
    allFields.options.map((item) => {
        if (item.value == defaultVal)
            defaultVal = defaultVal + "2";
    });
    return {
        title: {
            fa: null,
            en: null
        },
        value: defaultVal,
    }
};


export default SelectboxCustomField;
export {mkSelectboxCustomField};



