//--- React Hook
import React, {useState, useEffect , useRef} from 'react';

//--- ant design
import {
    Row, Form, Divider, Col, Button, Icon, Input, TreeSelect, message, Modal,
} from 'antd';

/* ------ custom -------- */
import Loading from "../../../library/components/Loading";
import _ from 'lodash'

import {EDIT_OPTION,OPTION_INFO,ADD_OPTION,DELETE_OPTION} from "../../queries/report/reports";
import SelectField from "../../../library/components/SelectField";


const OptionsAddEdit = (props) => {

    /* ------------------------------------------------ */
    /* ---------------- Define State ------------------ */
    /* ------------------------------------------------ */
    const [form, setFormField] = useState({
        title_en: '',
        title_fa: '',
    });

    const [mode, setMode] = useState(props.match.params.id ? 'edit' : 'add');
    const [id, setId] = useState(props.match.params.id ? props.match.params.id : '');
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);


    useEffect(() => {
        /*---- edit mode ---*/
        if (props.match.params.id) {
            let id = props.match.params.id
            optionsInfoRefetch(id);
        }

    },[] );

    /* ------------------------------------------------ */
    /* -------------------- Query --------------------- */
    /* ------------------------------------------------ */
    const optionsInfoRefetch = (id) => {
        setLoading(true)
        OPTION_INFO(id).then(info => {
            if(info.status === 200){
                setLoading(false)
                let data = info.data.data
                setFormField({
                    title_en : mode === 'edit' ? data.title.en : '',
                    title_fa : mode === 'edit' ? data.title.fa : '',

                });
            }
        }).catch(error => {
            console.log(error);
        });

    }

    var addOption = (data) =>  {
        setButtonLoading(true);
        ADD_OPTION(data).then(res => {
            if(res.status === 201) {
                res && res.data ? message.success("the ` " + res.data.data.title.en+ " ` was created.") : message.error("Error")
                setButtonLoading(false);
            }else{
                message.error(res.data.message,5)
                setButtonLoading(false);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    var updateOption = (data) =>  {
        setButtonLoading(true);
        data['optId'] = id
        EDIT_OPTION(data).then(res => {
            if(res.status === 200) {
                res ? message.success("the ` " + res.data.data.title.en+ " ` was updated.") : message.error("Error")
                setButtonLoading(false);
            }else{
                message.error(res.data.message,5)
                setButtonLoading(false);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const deleteOption = (id) => {
        setDeleteLoading(true)
        DELETE_OPTION(id).then(res => {
            if (res.status === 200) {
                let option = res.data.data
                res ? message.success("the ` " + option.title.en + " ` was deleted.") : message.error("Error in delete feature")
                props.history.push("/report/options")
                setDeleteLoading(false)
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (e) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                e.preventDefault();
                deleteOption(id);
            },
            onCancel() {},
        });
    }


    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */
    var handleTextInput = (name, e) => {
        const {value} = e.target;
        let newForm = {...form};
        newForm[name] = value;
        setFormField(newForm)
    };

    var handleSelectChange = (name, value) => {
        let newForm = {...form};
        newForm[name] = value;
        setFormField(newForm)
    };

    /* ------------------------------------------------ */
    /* -----------------  R E N D E R ----------------- */
    /* ------------------------------------------------ */

    return (

        <Form onSubmit={e => {
            e.preventDefault();
            (mode === 'edit' ? updateOption : addOption)({
                title: {
                    en: form.title_en,
                    fa: form.title_fa,
                },
                type: form.type

            });
        }} className="login-form">
            <Row type="flex">
                <Col xs={24} md={mode === 'edit' ? 20 : 24}>
                    <Divider orientation="left">{mode === 'edit' ? "edit" : "add"} option</Divider>
                </Col>
                <Col xs={24} md={mode === 'edit' ? 4 : 0}>
                    <Button type="primary" className="userListBtn" shape="round" icon="minus" loading={deleteLoading ? true : false}
                            onClick={showConfirm.bind(this)}>
                        delete option
                    </Button>
                </Col>
            </Row>
            {mode === 'edit' && loading ?
                <Loading/>
                :
                <Row type="flex" justify="start" align="middle">
                    <Col md={18} sm={24} type="flex" justify="start" align="middle">
                        <Row gutter={16}>

                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"english title"}
                                value={form.title_en}
                                onChange={handleTextInput.bind(this, 'title_en')}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"persian title"}
                                value={form.title_fa}
                                onChange={handleTextInput.bind(this, 'title_fa')}
                                style={{fontFamily:'IRANSans'}}
                            />
                            <SelectField
                                name={'type'}
                                list={[
                                    {label: 'bazaar', value: 'bazaar'},
                                    {label: 'market place', value: 'market'},
                                    {label: 'event', value: 'event'},
                                    {label: 'tips', value: 'tips'}
                                ]}
                                onChange={handleSelectChange.bind(this,'type')}
                                value={form.type}
                                placeholder={'type'}
                            />
                        </Row>

                        <Row>
                            <Button loading={buttonLoading} type="primary" shape="round"
                                    htmlType="submit"
                                    className="login-form-button">
                                {mode === 'edit' ? "edit" : "add"}
                            </Button>
                            <Button href="#/report/options" className="backBtn" type="default" shape="round">
                                back
                            </Button>
                        </Row>
                    </Col>
                </Row>
            }


        </Form>

    );

}

export default OptionsAddEdit;



