import React, {Component} from 'react'
import {Layout, Menu, Icon, Avatar, Button} from 'antd';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {AntDesign,
    MaterialCommunityIcons,
    FontAwesome,
    Feather,
    SimpleLineIcons,
    Octicons} from 'react-web-vector-icons'

const {Sider} = Layout;

const { SubMenu } = Menu;

class SideNavigation extends Component {
    state = {
        collapsed: false
    };

    getRandomName = (count) => {
        var listEmail = []
        for (var i = 0; i < count; i++) {
            listEmail.push([...Array(10)].map(i => (~~(Math.random() * 36)).toString(36)).join('') + '@gmail.com')
        }
        return listEmail;
    }
    onCollapse = (collapsed) => {
        this.setState({collapsed});
    };

    __onOpenChange(e){
        console.log('e',e)

    }

    render() {
        const listEmail = this.getRandomName(10);
        return (
            <Sider
                theme="light"
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
                reverseArrow="true"
            >
                <Menu defaultSelectedKeys={['sub1']} mode="inline"
                         style={{borderRight: 'none !important', fontWeight: '500'}}
                >
                    <SubMenu
                        key="sub1"
                        title={<span><FontAwesome name="user-md" color={'#fff'} size={19} style={{marginRight:10}} /><span>Administrator</span></span>}
                    >
                        <Menu.Item key="10">
                            <Link to="/admin">list</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub2"
                        title={<span><AntDesign name="shoppingcart" color={'#fff'} size={19} style={{marginRight:10}} /><span>Bazaar</span></span>}
                    >
                        <Menu.Item key="20">
                            <Link to="/bazaar/category">Category</Link>
                        </Menu.Item>
                        <Menu.Item key="21">
                            <Link to="/bazaar">list</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub3"
                        title={<span><MaterialCommunityIcons name="store" color={'#fff'} size={20} style={{marginRight:10}} /><span>Marketplace</span></span>}
                    >
                        <Menu.Item key="30">
                            <Link to="/marketplace/category">Category</Link>
                        </Menu.Item>
                        <Menu.Item key="31">
                            <Link to="/marketplace">list</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub4"
                        title={<span>
                            <Feather name="calendar" color={'#fff'} size={18} style={{marginRight:10}} />
                            <span>Event</span></span>}
                    >
                        <Menu.Item key="40">
                            <Link to="/event/category">Category</Link>
                        </Menu.Item>
                        <Menu.Item key="41">
                            <Link to="/event">list</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub5"
                        title={<span><AntDesign name="setting" color={'#fff'} size={18} style={{marginRight:10}} /><span>feature</span></span>}
                    >
                        <Menu.Item key="50">
                            <Link to="/feature">list</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub6"
                        title={<span><AntDesign name="user" color={'#fff'} size={18} style={{marginRight:10}} /><span>user</span></span>}
                    >
                        <Menu.Item key="60">
                            <Link to="/user">list</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub7"
                        title={<span><SimpleLineIcons name="globe" color={'#fff'} size={18} style={{marginRight:10}} /><span>country</span></span>}
                    >
                        <Menu.Item key="70">
                            <Link to="/country">country</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub8"
                        title={<span><Feather name="alert-triangle" color={'#fff'} size={18} style={{marginRight:10}} /><span>reports</span></span>}
                    >
                        <Menu.Item key="80">
                            <Link to="/report">list</Link>
                        </Menu.Item>
                        <Menu.Item key="81">
                            <Link to="/report/options">options</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub9"
                        title={<span><FontAwesome name="comments-o" color={'#fff'} size={18} style={{marginRight:10}} /><span>tips</span></span>}
                    >
                        <Menu.Item key="90">
                            <Link to="/tips">list</Link>
                        </Menu.Item>
                        <Menu.Item key="91">
                            <Link to="/tips/report/list">reports</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
        );
    }
}

export default SideNavigation;
