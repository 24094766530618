import cookie from 'react-cookies';
import { ImmortalStorage, CookieStore, LocalStorageStore } from 'immortal-db';
import { setFetchServiceToken} from '../../library/utils/FetchService';
import _ from 'lodash'


export const LOGIN_DATA             = 'LoginData';

const stores = [CookieStore, LocalStorageStore];
const db = new ImmortalStorage(stores);

/* ---  --------------- --- */
/* ---  Login / Logout  --- */
/* ---  --------------- --- */
export async function appLogin(token,userData)
{
  setFetchServiceToken(token);
  db.set(LOGIN_DATA, token);
  localStorage.setItem(LOGIN_DATA, token)
  userData.token = token;
  var dataString = JSON.stringify(userData);
  setFetchServiceToken(token);
  return dataString;
}


export async function appLogout(callback)
{

  db.remove(LOGIN_DATA);
  localStorage.removeItem(LOGIN_DATA)
  localStorage.removeItem('PASS')
  if(!_.isEmpty(localStorage))
    window.location.reload();
  return true;
}

export function getAuthorizationData(props)
{
  props.isLogin = true
  var  rawData = cookie.load(LOGIN_DATA);
  var data = JSON.parse(rawData);

  return data;
}
