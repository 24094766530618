//--- React Hook
import React, {useState, useEffect , useRef} from 'react';

//--- ant design
import {
    Row, Form, Divider, Col, Button, Icon, Input, TreeSelect, message, Checkbox, Typography,
    Layout, Card, Modal, Tag,
} from 'antd';

/* ------ custom -------- */
import Loading from "../../../library/components/Loading";
import _ from 'lodash'

import {CATEGORY_LIST} from "../../queries/category/getCategoryList";
import { DELETE_CATEGORY } from "../../queries/category/DeleteCategory";
import FeatureList from '../../feature/list';
import {UPDATE_FEATURE} from "../../queries/feature/EditFeature";
import {getAuthorizationData} from "../../../library/utils/AuthService";
import CustomField from "../../customField/list";
import {EDIT_CATEGORY} from "../../queries/category/EditCategory";
import {CATEGORY_INFO} from "../../queries/category/getCategoryInfo";
import {ADD_CATEGORY} from "../../queries/category/AddCategory";
import {FEATURE_LIST} from "../../queries/feature/FeatureList";


const CategoryAddEdit = (props) => {

    if (props.match.params.id) {
        var explodeUrl = (props.match.url).split('/')
    }
    /* ------------------------------------------------ */
    /* ------------ Render HTML  : convert special character ------------- */
    /* ------------------------------------------------ */
    const renderHTML = (rawHTML) => React.createElement("div", {dangerouslySetInnerHTML: {__html: rawHTML}});

    /* ------------------------------------------------ */
    /* ---------------- Define State ------------------ */
    /* ------------------------------------------------ */
    const [form, setFormField] = useState({
        title_en: '',
        title_fa: '',
        title_tr: '',
        desc_en: '',
        desc_fa: '',
        desc_tr: '',
        parent: null,
        icon:{
            name:'',
            libraryName:''
        },
        feature_list:[],
        customField: [],
    });

    const [mode, setMode] = useState(explodeUrl ? explodeUrl[3] : 'add');
    const [id, setId] = useState(props.match.params.id ? props.match.params.id : '');
    const [treeData, setTreeData] = useState([]);
    const [treeCat, setTreeCat] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [state, setState] = useState(false);
    const [categoryInfoLoading, setCategoryInfoLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);


    useEffect(() => {

        /*---- edit mode ---*/
        if (props.match.params.id) {
            let id = props.match.params.id
            let explodeUrl = (props.match.url).split('/')
            setMode(explodeUrl[3]);
            categoryInfoRefetch(id);
        }
        categoryListData();

    },[] );


    /* ------------------------------------------------ */
    /* ------------ Query ------------- */
    /* ------------------------------------------------ */
    const categoryListData = () => {
        CATEGORY_LIST('EVENT').then(list => {
            if(list.status === 200) {
                if (list.data.data.length > 0) {
                    setCategoryList(list.data.data);
                } else
                    setCategoryList([]);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const categoryInfoRefetch = (id) => {
        CATEGORY_INFO(id).then(categoryInfoData => {
            if(categoryInfoData.status === 200){
                let data
                data = categoryInfoData.data.data[0]
                setFormField({
                    title_en : mode === 'edit' ? (data?.title?.en) : '',
                    title_fa : mode === 'edit' ? (data?.title?.fa) : '',
                    title_tr : mode === 'edit' ? (data?.title?.tr) : '',
                    desc_en  : mode === 'edit' ? (data?.description?.en) : '',
                    desc_fa  : mode === 'edit' ? (data?.description?.fa) : '',
                    desc_tr  : mode === 'edit' ? (data?.description?.tr) : '',
                    parent   : mode === 'edit' && data.parent ?
                                data.parent
                            : mode === 'add' && id && !_.isEmpty(id) ?
                                id
                            : 0,
                    customField : mode === 'edit' ? data.customField : '',
                    feature_list: mode === 'edit' ? data.feature_list : [],
                    icon_name  : mode === 'edit' ? (data?.icon?.name) : '',
                    icon_library  : mode === 'edit' ? (data?.icon?.libraryName) : ''
                });
            }
        }).catch(error => {
            console.log(error);
        });

    }

    //--- get all feature
    const callFeatureList = () => {
        FEATURE_LIST.then(list => {
            if(list.status === 200) {
                if(list.data && list.data.data.length > 0)
                {
                    setFeatureList(list.data.data);
                }
            }
        }).catch(error => {
            console.log(error);
        });

    }


    var addCategory = (data) =>  {
        setButtonLoading(true);
        ADD_CATEGORY(data).then(res => {
            if(res.data.errCode === -4) {
                message.error("You are not able to create sub category for categories which include bazaar/market/event.",5)
                setButtonLoading(false);
            }

            if(res.status === 200) {
                res && res.data ? message.success("the ` " + (res.data.data.title.en || res.data.data.title.tr)+ " ` was created.") : message.error("Error")
                setButtonLoading(false);
                categoryListData()
            }else
                return 'error'
        }).catch(error => {
            console.log(error);
        });

    }

    var updateCategory = (data) =>  {
        setButtonLoading(true);
        EDIT_CATEGORY(data).then(res => {
            if(res.status === 200) {
                res ? message.success("the ` " + (res.data.data.title.en || res.data.data.title.tr)+ " ` was updated.") : message.error("Error")
                setButtonLoading(false);
            }else
                return 'error'
        }).catch(error => {
            console.log(error);
        });

    }

    /* -------------- DeleteCategory  ----------------- */
    const deleteCategory = (data) => {
        setDeleteLoading(true)
        DELETE_CATEGORY({data}).then(res => {
            if(res.data.errCode === -5) {
                message.error("You are not able to delete main category.",5)
                setDeleteLoading(false)
            }

            if (res.status === 200) {
                let category = res.data.data
                res ? message.success("the ` " + (category.title.en || category.title.tr) + " ` was deleted.") : message.error("Error in delete feature")
                props.history.push("/event/category")
                setDeleteLoading(false)
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /* -------------- Edit Feature  ----------------- */
    const updateFeature = (param) => {
        UPDATE_FEATURE(param).then(res => {
            if(res.status === 200) {
                if(res.data)
                {
                    let feature = res.data
                    res ? message.success("the ` " + feature.title.en+ " ` was updated.") : message.error("Error in update feature")

                }
            }
        }).catch(error => {
            console.log(error);
        });

    }


    var onSaveFeature = (feature,text, disableEditMode) => {

        feature.title.en = text;
        if (!feature.type)
            feature.type = "event"

        var FeatureInput = {
            title           :feature.title,
        }
        updateFeature({
            id: feature.id,
            FeatureInput
        });
        disableEditMode();
    }


    /* ------------------------------------------------ */
    /* ----------- Delete Confirmation  --------------- */
    /* ------------------------------------------------ */
    const { confirm } = Modal;
    const showConfirm = (e) => {
        confirm({
            title: 'Alert',
            content: 'Are you sure delete this Item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk(){
                e.preventDefault();
                deleteCategory({
                    catId:id
                });
            },
            onCancel() {},
        });
    }

    /* ----------- help to choose icon  --------------- */
    const closeHelpModal = (e) => {
        setShowHelpModal(false)
    }

    /* ------------------------------------------------ */
    /* -------------- Handle Input  ------------------- */
    /* ------------------------------------------------ */
    var handleTextInput = (name, e) => {
        const {value} = e.target;
        let newForm = {...form};
        newForm[name] = value;
        setFormField(newForm)
    };

    /* -------------- Handle tree select  ------------------- */
    var handleTreeSelect = (name, val) => {
        let newForm = {...form};
        newForm[name] = val;
        setFormField(newForm)
    };
    /* -------------- Handle feature list ------------------- */
    var handleFeatureSelect = (name, featureItem,e) => {
        let isChecked =  e.target.checked;
        let newForm = {...form};

        if (!newForm['feature_list'])
            newForm['feature_list'] = [];

        if (isChecked && newForm['feature_list'] && newForm['feature_list'].indexOf(featureItem.id) == -1)
            newForm['feature_list'].push(featureItem.id)
        if (!isChecked && newForm['feature_list'] && newForm['feature_list'].indexOf(featureItem.id) > -1)
            _.pull(newForm['feature_list'], featureItem.id);


        setFormField(newForm)
    };

    /* ---------------  Handle Tree Cat --------------- */
    let newList = []
    var getParents = (parent, id) => {

        if (parent != 0 && parent != null) {
            newList.unshift(parent)
            var newElement = _.find(categoryList, parent)
            if (newElement && newElement.parent != null && newElement.parent != 0)
                getParents(newElement.parent, newElement.id)
        } else
            newList.unshift(id)


        return _.uniq(newList);
    }

    var handleTreeCat = (value, node) => {
        let _ = require('lodash')
        let pId = node.props.pId;
        let id = node.props.id;
        var newList = getParents(pId, id)
        if (props.match.params.id)
            newList.push(id)
        setTreeCat(_.uniq(newList))
    };

    const categoryListDataWithpId = () => {
        let newList = []
        if (categoryList) {
            for (let i in categoryList) {
                newList.push({
                    id: categoryList[i]['id'],
                    expanded: true,
                    pId: categoryList[i]['parent'] ? categoryList[i]['parent'] : 0,
                    title: categoryList[i]['title'] && categoryList[i]['title']['en'] ? renderHTML(categoryList[i]['title']['en']) : '',
                    value: categoryList[i]['id'],
                    key: categoryList[i]['key'],
                })
            }

        }
        return newList;
    }



    /* ------------------------------------------------ */
    /* -----------------  R E N D E R ----------------- */
    /* ------------------------------------------------ */

    return (

        <Form onSubmit={e => {
            e.preventDefault();
            (mode === 'edit' ? updateCategory : addCategory)({
                catId: id,
                enTitle: form.title_en,
                faTitle: form.title_fa,
                trTitle: form.title_tr,
                parent: form.parent ? form.parent : 0,
                catType: "EVENT",
                enDesc: form.desc_en,
                faDesc: form.desc_fa,
                customField  : form.customField ? form.customField : [],
                feature_list :form.feature_list,
                icon:{
                    name:form.icon_name,
                    libraryName:form.icon_library
                }
            });
        }} className="login-form">
            <Row type="flex">
                <Col xs={24} md={mode === 'edit' ? 20 : 24}>
                    <Divider orientation="left">{mode === 'edit' ? "edit" : "add"} category</Divider>
                </Col>
                <Col xs={24} md={mode === 'edit' ? 4 : 0}>
                    <Button type="primary" className="userListBtn" shape="round" icon="minus" loading={deleteLoading ? true : false}
                            onClick={showConfirm.bind(this)}>
                        delete category
                    </Button>
                </Col>
            </Row>
            {mode === 'edit' && categoryInfoLoading ?
                <Loading/>
                :
                <Row type="flex" justify="start" align="middle">
                    <Col md={18} sm={24} type="flex" justify="start" align="middle">
                        <Row gutter={16}>

                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"english title"}
                                value={form.title_en}
                                onChange={handleTextInput.bind(this, 'title_en')}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"persian title"}
                                value={form.title_fa}
                                onChange={handleTextInput.bind(this, 'title_fa')}
                                style={{fontFamily:'IRANSans'}}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"turkish title"}
                                value={form.title_tr}
                                onChange={handleTextInput.bind(this, 'title_tr')}
                            />
                            <TreeSelect
                                value={
                                    mode === 'edit' && form.parent && form.parent != 0 ?
                                        form.parent
                                    : mode === 'add' && id === '' ?
                                        form.parent
                                    : mode === 'add' && id && !_.isEmpty(id) ?
                                        id
                                    : null}
                                name={"parent"}
                                treeData={categoryListDataWithpId()}
                                treeDataSimpleMode
                                placeholder={"category"}
                                treeDefaultExpandAll={true}
                                onChange={handleTreeSelect.bind(this, 'parent')}
                                onSelect={handleTreeCat.bind(this)}
                            />
                            <div className={'iconBox'}>
                                <a onClick={()=>setShowHelpModal(true)} target={'_blank'}>
                                    <Tag color="blue">
                                        help to choose icon
                                    </Tag>
                                </a>
                            </div>
                            <div className={'iconBox'}>
                                <a href={'https://oblador.github.io/react-native-vector-icons/'} target={'_blank'}>
                                    <Tag color="purple">choose icon</Tag>
                                </a>
                            </div>
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"icon library"}
                                value={form.icon_library}
                                onChange={handleTextInput.bind(this, 'icon_library')}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"icon name"}
                                value={form.icon_name}
                                onChange={handleTextInput.bind(this, 'icon_name')}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"english description"}
                                value={form.desc_en}
                                onChange={handleTextInput.bind(this, 'desc_en')}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"persian description"}
                                value={form.desc_fa}
                                onChange={handleTextInput.bind(this, 'desc_fa')}
                                style={{fontFamily:'IRANSans'}}
                            />
                            <Input
                                prefix={<Icon type={'text'} style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder={"persian description"}
                                value={form.desc_tr}
                                onChange={handleTextInput.bind(this, 'desc_tr')}
                            />
                            <FeatureList
                                selectable={true}
                                onSelect={handleFeatureSelect.bind(this)}
                                selected={form.feature_list ? form.feature_list : null}
                            />

                            <CustomField
                                list={form.customField ? form.customField : null}
                                onChange={(newData) => {
                                    let newForm         = { ...form};
                                    newForm.customField = newData;
                                    setFormField(newForm);
                                }}
                            />


                        </Row>

                        <Row>
                            <Button loading={buttonLoading} type="primary" shape="round"
                                    htmlType="submit"
                                    className="login-form-button">
                                {mode === 'edit' ? "edit" : "add"}
                            </Button>
                            <Button href="#/event/category" className="backBtn" type="default" shape="round">
                                back
                            </Button>
                        </Row>
                    </Col>
                </Row>
            }
            {showHelpModal?
                <Modal
                    title="help to choose icon"
                    visible={showHelpModal}
                    footer={false}
                    onCancel={closeHelpModal}
                >
                    <p>1. click on Choose Icon box</p>
                    <p>2. copy icon library and past to input of icon library</p>
                    <p>3. copy icon name and past to input of icon name</p>
                    <img style={{margin:'auto',width:'100%'}} src={'/chooseIcon.png'} />
                </Modal>
                : null}


        </Form>

    );

}

export default CategoryAddEdit;



