import React, {Component, useEffect, useState} from 'react';
import { Layout, Row, Col } from 'antd';
import SideNavigation from './parts/SideNavigation';
import UserMenu from './parts/UserMenu';
import { Link } from 'react-router-dom';
import {ImmortalDB} from "immortal-db";
import {LOGIN_DATA} from "../../library/utils/AuthService";
import {setFetchServiceToken} from "../../library/utils/FetchService";
const {
    Header, Footer, Content,
} = Layout;


const AppLayout = (props) => {

    const {children} = props
    const [isLogin,setIsLogin] = useState(false)

    useEffect((props) => {
        let token = localStorage.getItem(LOGIN_DATA)
        if(token != null || token != undefined) {
            setIsLogin(true)
        }
    })

    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                {(isLogin) ?
                    <Header style={{ padding: 0, backgroundColor: '#FDFDFD' }}>
                        <Row type="flex" justify="end" align="middle">
                            <Col md={3} sm={12} xs={24}>
                                <Link to="/">
                                    <div className="logo-box">
                                        <div className="logo"></div>
                                    </div>
                                </Link>
                            </Col>
                            <Col md={21} sm={12} xs={24} style={{textAlign:'right',paddingRight: '15px'}}>
                                <UserMenu/>
                            </Col>
                        </Row>
                    </Header>
                :
                null}
                <Layout>
                     {(isLogin) ?
                     <SideNavigation />
                       :null}
                    <Layout>
                        <Content style={{padding: '10px'}}>{children}</Content>
                        {(isLogin) ?
                            <Footer style={{backgroundColor: '#F7F7F7'}}>IRUS Platform &copy;2019</Footer>
                          :null}
                    </Layout>
                </Layout>
            </Layout>
        </div>
    );
}

export default AppLayout;
