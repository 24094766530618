import React, { useState ,useEffect} from 'react';
import {
    Row,
    message,
    Col,
    Divider,
    Button,
} from "antd"; // This only needs to be imported once in your app
import 'react-sortable-tree/style.css';
import Loading from "../../../library/components/Loading";
import {COUNTRY_INFO} from "../../queries/country/country"
import 'react-sortable-tree/style.css';
import {DELETE_STATE, STATE_LIST} from "../../queries/state/state";
import CityLists from "../../../library/components/CityLists";
import _ from "lodash";


function UserList(props){

    const [listLoading, setListLoading] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [countryId, setCountryId] = useState(props.match.params.id ? props.match.params.id : 0);
    const [countryName, setCountryName] = useState('');

    useEffect(() => {
        getCountryName()
        getStateList()
    }, []);

    /* ------------------------------------------------ */
    /* ---------------- State Query ----------------- */
    /* ------------------------------------------------ */

    var getStateList = () => {
        setListLoading(true)
        let param = {country: countryId }
        STATE_LIST(param).then(res => {
            if(res.status === 200) {
                setListLoading(false)
                setStateList(res.data.data)
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const deleteState = (id) => {
        DELETE_STATE(id).then(res => {
            if(res.data.errCode === -6) {
                message.error("there are assigned bazaar/marketplace/event to this state")
            }

            if (res.status === 200) {
                let country = res.data.data
                res ? message.success("the ` " + country.title.en + " ` was deleted.") : message.error("Error in delete state")
                var index = _.findIndex(stateList, {id: id});
                stateList.splice(index, 1);
                getStateList()
            }
        }).catch(error => {
            console.log(error);
        });
    }

    var getCountryName = () => {
        setListLoading(true)
        let param = {country: countryId}
        COUNTRY_INFO(countryId).then(res => {
            if(res.status === 200) {
                setCountryName(res.data.data.title.en)
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return(
        <div>
            <Row type="flex">
                <Col xs={24} md={20}>
                    <Divider orientation="left">state list of {countryName}</Divider>
                </Col>
                <Col xs={24} md={4} >
                    <Button type="primary" className="userListBtn" shape="round" icon="plus" href={`#/state/add/${countryId}`}>
                        create state in {countryName}
                    </Button>
                </Col>
            </Row>
            <Row>

                <div
                    style={{display: 'flex', flexDirection: 'column', height: '100vh'}}
                >
                    <div style={{flex: '1 0 50%'}}>
                        {listLoading ?
                            <Loading/> :
                            stateList && stateList.length>0 && stateList.map((state,index) =>{
                                return (
                                    <CityLists
                                        key={index}
                                        item={state}
                                        link={'/city/'+state.id}
                                        edit={'/state/edit/' + state.id}
                                        add={'/city/add/' + state.id}
                                        onDelete={deleteState.bind(this,state.id)}
                                    />
                                )
                            })
                        }

                    </div>
                </div>
            </Row>
        </div>
    )

}

export default UserList;
