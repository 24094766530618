import React, { Component } from 'react';
import { Menu, Dropdown, Icon, Avatar, Badge, List, Button } from 'antd';
import { logout } from '../../../components/globalAction';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
    RTLListItem,
    RTLListItemMeta} from '../../RTLKit';
import Logout from '../../logout/Logout';


const data = [];
// const data = [
//     {
//         title: 'فدراسیون فوتبال: پاتوسی در لیگ قهرمانان بازی می‌کند'
//     },
//     {
//         title: 'ترکیب احتمالی پرسپولیس برابر نفت مسجد سلیمان'
//     },
//     {
//         title: 'دخسوس: روز خوبم با بدترین روز عوض شد'
//     },
//     {
//         title: 'رقیب پرسپولیس بدون ژاوی در کلاسیکوی دوحه'
//     },
//     {
//         title: 'یاران زخم خورده طارمی به رقیب استقلال رسیدند'
//     }
//   ];


const NewList = styled(List)`
  top: 8px !important;
`;

const messageList = (
    <NewList
      size="small"
      bordered
      dataSource={data}
      renderItem={item => (
        <RTLListItem>
          <RTLListItemMeta
            avatar={<Avatar icon="message" size="small" style={{ backgroundColor: '#87d068' }}/>}
            title={item.title}
          />
        </RTLListItem>
      )}
    />
);

const menu = (
    <Menu style={{fontWeight: 'bolder'}}>
      <Menu.Item key="0">
        <span><Icon type="wallet" /><span>حسابداری</span></span>
      </Menu.Item>
      <Menu.Item key="1">
        <span><Icon type="profile" /><span>پروفایل</span></span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <span style={{color: 'red'}}>Exit<Icon type="logout" /><span></span></span>
      </Menu.Item>
    </Menu>
  );

  const menuActions = (
    <Menu>
      {/*<Menu.Item key="1">*/}
      {/*    <a href="/#/order/add">*/}
      {/*      <Icon type="plus-circle" theme="twoTone" />ایجاد مشتری و ثبت سفارش*/}
      {/*    </a>*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item key="2">*/}
      {/*    <a href="/#/user/list">*/}
      {/*        <Icon type="plus-circle" theme="twoTone" />لیست کاربران*/}
      {/*    </a>*/}
      {/*</Menu.Item>*/}
    </Menu>
  );

class UserMenu extends Component {
    render () {
        return(
            <React.Fragment>
                <Dropdown overlay={menuActions}>
                    <Button type="primary" shape="round" style={{marginRight: '10px', width: '150px'}}>
                        Shortcut <Icon type="caret-down" />
                    </Button>
                </Dropdown>
                <Dropdown overlay={messageList} trigger={['click']} placement="bottomLeft">
                    <span style={{ marginRight: '10px' }}>
                        {/*<Badge count={3}><Avatar shape="circle" icon="message" /></Badge>*/}
                        <Avatar shape="circle" icon="message" />
                    </span>
                </Dropdown>
                <Logout />
            </React.Fragment>
        );
    }
}
// export default UserMenu;
export default UserMenu;
