import React, {useEffect, useState} from 'react'
import {Col, Row, Card, Form, Button, Icon, Input, message} from 'antd'
import {appLogin, LOGIN_DATA} from "../../../library/utils/AuthService";
import {API_FETCH} from "../../../library/utils/FetchService";
import _ from 'lodash'
import { Base64 } from 'js-base64';
import {CookieStore, ImmortalStorage, LocalStorageStore} from "immortal-db";

const stores = [CookieStore, LocalStorageStore];

const Login = (props) => {

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        // props.onPageLoad('LoginPage');
    })

    const onFormSubmit = () => {
        setLoading(true)
        let data={
            username : username,
            password : password
        }
        API_FETCH.post('super/login', data).then(res => {
            if (res.status === 200) {
                let token = res.data.data.token
                appLogin(token, data)
                let pass = Base64.encode(password)
                localStorage.setItem('PASS', pass)
                window.location.reload();
            } else
                message.error("username or password is incorrect")

            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }

    return(
        <Row type="flex" justify="space-around" align="middle">
            <Col md={6} sm={24}>
                <Card style={{marginTop: '30%'}}>
                    <img src={require(`../../../assets/image/logo.png`)} alt="" style={{marginBottom: '20px'}}/>
                    <Form className="login-form">
                        <Form.Item>
                            <Input
                                name="username"
                                type="text"
                                placeholder="Username"
                                onChange={(data => setUsername(data.target.value))}
                                style={{textTransform:'none'}}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Input
                                name="password"
                                type="password"
                                placeholder="Password"
                                onChange={(data => setPassword(data.target.value))}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button onClick={onFormSubmit.bind(this)} type="primary" block
                                    shape="round" htmlType="submit"
                                    className="login-form-button"
                                    loading={loading}
                            >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default Login;
// const mapDispatchToProps = dispatch => ({
//
//     onSubmit: (values) => {
//         dispatch({type: LOGIN_REQUEST});
//         API_FETCH.post('auth/sign_in', {
//             username: values.username,
//             passwd: values.password
//         }).then(res => {
//             if (res.data && res.data.access_token) {
//                 var userData = jwtDecode(res.data.access_token);
//                 appLogin(res.data.access_token,userData);
//                 dispatch({type: LOGIN_SUCCESS});
//                 dispatch({type: PAGE_REDIERCT, redirectTo: '/'});
//             } else if (res.data && res.status === 401) {
//                 dispatch({type: LOGIN_FAILURE});
//                 dispatch({
//                     type: ALERT_ERROR,
//                     message: 'نام کاربری یا رمز عبور اشتباه است'
//                 })
//             }
//         }).catch(error => {
//             dispatch({type: LOGIN_FAILURE});
//             dispatch({
//                 type: ALERT_ERROR,
//                 message: 'نام کاربری یا رمز عبور اشتباه است'
//             })
//         });
//
//     },
//
//
//     onPageLoad: view => {
//         dispatch(commonActions.pagePreLoaded(view))
//     },
//
// });
//
// const mapStateToProps = state => ({
//     // alert: state.alert,
//     // auth: state.auth,
//     // common: state.common
// })
//
// export default connect(mapStateToProps, mapDispatchToProps)(Login);


